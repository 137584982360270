<style scoped>
.text-danger {
  color: red !important;
}
.table-expend:hover {
  background: #e9e9e9;
  cursor: pointer;
}
.table-expend-combo:hover td.combo {
  background: #e9e9e9;
  cursor: pointer;
}
.table-expend:active,
.table-expend-combo td.combo:active {
  background: #e7e5e5;
}
.disabled-row {
  /* Apply styles to indicate disabled state */
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #eee;
}
.blink {
  animation: blinking ease-out 1s 2;
}

.selected_parent_fg {
  background-color: pink;
}

@keyframes blinking {
  0% {
    background-color: #ffae00;
  }
  100% {
    background-color: #fff;
  }
}
</style>
<!-- New Layout - Persis CO SFA Mix2 -->
<!-- Pembagian dan pengelompokan dropdown -->
<template>
  <vx-card id="head_card">
    <!-- modal reject -->
    <vs-prompt
      title="Add Reject Reason"
      color="warning"
      @accept="addRejectFlag(modalReject.activeIndex)"
      :active.sync="modalReject.activeModal"
    >
      <div class="con-exemple-prompt">
        <SelectMasterReason
          :reasonGroup="'Reject SO'"
          @data="setSelectMasterReasonReject"
          ref="selectMasterReason"
        />
      </div>
    </vs-prompt>

    <!-- 1st row : Leftside Customer Data & Rightside Segmentation Data -->
    <div class="vx-row mt-3">
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="py-2 h5 font-semibold">Customer Data</span>
        <hr />
        <!-- One time cust -->
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3">One Time</div>
          <div class="vx-col sm:w-2/6">
            <vs-switch
              color="dark"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="salesOrder.IsOneTime"
              :disabled="false"
            >
              <span slot="on">YES</span>
              <span slot="off">NO</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Ship to</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="salesOrder.ShipAddress"
              :options="optionShippTo"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
              :internal-search="false"
              :loading="isLoadingShipto"
              :max-height="200"
              :limit="5"
              :disabled="this.disable_form"
              v-validate="'required'"
              placeholder="Type to search"
              @input="onSelectedShipto"
              @search-change="handlerSearchShipto"
              :custom-label="customLableShippTo"
            />
            <span class="text-danger text-sm" v-show="true">{{
              msgErrorShippTo
            }}</span>
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
            <vs-input
              class="w-full"
              v-model="oneTimeCust.address_manual"
              disabled
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Bill to</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="salesOrder.BillAddress"
              :options="optionBillTo"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
              :internal-search="true"
              :max-height="200"
              :limit="5"
              :disabled="true"
              placeholder="Type to search"
              @input="onSelectedBillto"
              :custom-label="customLableBillto"
            />
            <span class="text-danger text-sm" v-show="isErrorBillTo">{{
              msgErrorBillto
            }}</span>
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
            <vs-input
              class="w-full"
              v-model="oneTimeCust.address_manual"
              disabled
            />
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="salesOrder.Customer"
              :options="optionCustomer"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
              :loading="isLoadingCus"
              :internal-search="false"
              :max-height="200"
              :limit="5"
              :disabled="true"
              placeholder="Type to search"
              @search-change="handlerSearchCustomer"
              :custom-label="customLableCustomer"
            />
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
            <!-- <vs-input class="w-full" v-model="salesOrder.Customer.Code" disabled/> -->
            <vs-input
              class="w-full"
              v-model="salesOrder.Customer.DisplayName"
              disabled
            />
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Name</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <vs-input
              class="w-full"
              v-model="salesOrder.Customer.Name"
              disabled
            />
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
            <vs-input
              class="w-full"
              v-model="salesOrder.OcData.name"
              disabled
            />
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Created By</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input
              class="w-full"
              v-model="salesOrder.Creator.Name"
              disabled
            />
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Creation Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <!-- <vs-input class="w-full" v-model="salesOrder.DefaultAttribute.CreatedAt" disabled/> -->
            <datepicker
              format="yyyy - MM - dd"
              name="date"
              :inline="false"
              v-model="salesOrder.DefaultAttribute.CreatedAt"
              placeholder="Select Date"
              v-validate="'required'"
              :disabled="true"
            ></datepicker>
          </div>
        </div>
        <!--  -->
        <div v-if="salesOrder.Category == 'ZDCBD'" class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Deposits</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustDeposit"
              :options="optionCustDeposit"
              :multiple="true"
              :allow-empty="false"
              :track-by="`TrackByKey`"
              :max-height="200"
              placeholder="Type to search"
              :custom-label="customLableCustDeposit"
              :disabled="this.disable_form"
            />
          </div>
        </div>
        <!-- Customer One time Form -->
        <div v-if="this.isOneTime" class="vx-row mb-2 mt-4">
          <div class="vx-col w-full">
            <span class="py-2 h5 font-semibold">One Time Customer Data</span>
            <!-- <span role="button" class="float-right" @click="handleCloseOt">Close</span> -->
            <hr />
          </div>
          <div class="vx-col w-full">
            <div class="vx-row my-6">
              <div class="vx-col w-full">
                <label class="vs-input--label"
                  >Customer Name <span class="text-danger">*</span></label
                >
                <vs-input
                  class="w-full"
                  v-model="oneTimeCust.name"
                  placeholder="Input name"
                  name="Customer Name"
                />
              </div>
            </div>
            <address-component
              :data="oneTimeCust.address"
              @data="setDataAddress"
            ></address-component>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label"
                  >Bill / Tax Address <span class="text-danger">*</span></label
                >
                <vs-input
                  class="w-full"
                  v-model="oneTimeCust.address_manual"
                  placeholder="Re-input address"
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label"
                  >Contact Person <span class="text-danger">*</span></label
                >
                <vs-input
                  class="w-full"
                  v-model="oneTimeCust.contact_person"
                  placeholder="Input contact person"
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label"
                  >NIK <span class="text-danger">*</span></label
                >
                <vs-input
                  type="number"
                  class="w-full"
                  v-model="oneTimeCust.nik"
                  placeholder="Input NIK"
                />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label"
                  >Phone Number <span class="text-danger">*</span></label
                >
                <vs-input
                  type="number"
                  class="w-full"
                  v-model="oneTimeCust.phone"
                  placeholder="Input phone number"
                />
              </div>
            </div>
            <!-- <div class="vx-row">
                      <div class="vx-col w-full">
                          <vs-button size="medium" v-on:click="handleCustOnetime" color="primary">
                          {{ this.oneTimeCust.id != null ? "Update" : "Confirm" }}
                          </vs-button>
                      </div>
                    </div> -->
          </div>
        </div>
      </div>
      <!-- Segmentation Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="py-2 h5 font-semibold">Segmentation Data</span>
        <hr />
        <!-- Territory -->
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="dispTerritory" disabled />
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Zone</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              v-model="zone.selected"
              :options="zone.option"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
              @search-change="handlerSearchZone"
              @input="onselectedZone"
              :disabled="this.update || this.disable_form"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.code }} {{ props.option.name }}
                  </span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    {{ props.option.code }} {{ props.option.name }}
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Sales Channel</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <!-- Lock Sales Channel - Sales Taking Order -->
            <!-- <vs-input class="w-full" v-model="dispSalesChannel" disabled/> -->
            <multiselect
              class="selectExample"
              v-model="salesChannel.selected"
              :options="salesChannel.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              track-by="code"
              label="name"
              :disabled="true"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Distribution Channel</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="dispDistChannel" disabled />
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Sales ID</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="salesPersonal.selected"
              :options="salesPersonal.option"
              :multiple="false"
              :allow-empty="true"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :loading="isLoadingSales"
              track-by="ID"
              @search-change="handlerSearchSales"
              @input="onselectedSalesman"
              :custom-label="customLableSalesman"
              :disabled="this.disable_form"
            >
            </multiselect>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Pricing Group</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              v-model="pricingGroup.selected"
              :options="pricingGroup.options"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
              :disabled="this.update || this.disable_form"
              @input="onselectedPricingGroup"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.name }} ({{ props.option.code }})
                  </span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    {{ props.option.name }} ({{ props.option.code }})
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <!--  -->
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Fulfillment Note</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <!-- <vs-input class="w-full" v-model="salesOrder.FulfillmentMessage" :disabled="this.update || this.disable_form"/> -->
            <vs-textarea
              v-model="salesOrder.FulfillmentMessage"
              :disabled="this.update || this.disable_form"
              readonly
            />
          </div>
        </div>
        <div
          class="vx-row my-6"
          v-if="
            salesOrder.CancelMessage != '' && salesOrder.CancelMessage != null
          "
        >
          <div class="vx-col sm:w-1/3 w-full">
            <span>Cancel PI Reason</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-textarea
              v-model="salesOrder.CancelMessage"
              :disabled="this.update || this.disable_form"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 2nd row : CO Data & Delivery Data -->
    <div class="vx-row">
      <!-- CO Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="pt-2 h5 font-semibold">SO Data</span>
        <hr />
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>SO Code</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="salesOrder.Code" disabled />
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Ref. PO Code</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input
              class="w-full"
              v-model="salesOrder.PoReferenceCode"
              :disabled="this.disable_form"
            />
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Ref. SO Code</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input
              class="w-full"
              v-model="salesOrder.SoReferenceCode"
              :disabled="this.update || this.disable_form"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>SO Type</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="soType"
              :options="optionSoType"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="4"
              placeholder="Type to search"
              track-by="Code"
              :custom-label="customLableCOType"
              :disabled="this.update || this.disable_form"
            >
            </multiselect>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <!-- disabledDates -->
            <datepicker
              format="yyyy - MM - dd"
              name="date"
              :inline="false"
              v-model="salesOrder.Date"
              placeholder="Select Date"
              v-validate="'required'"
              :disabledDates="disabledDates"
              :disabled="false"
            ></datepicker>
            <!-- :disabled="this.update || this.disable_form" -->
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>PO Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              format="yyyy - MM - dd"
              name="date"
              :inline="false"
              v-model="salesOrder.PoReferenceDate"
              placeholder="Select Date"
              v-validate="'required'"
              :disabledDates="disabledDates"
              :disabled="this.disable_form"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Payment Term</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="paymentTerm.selected"
              :options="paymentTerm.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder=" Type to search"
              track-by="id"
              label="name"
              :disabled="this.update || this.disable_form"
              @input="onselectedPaymentTerm_"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>PBF</span>
          </div>
          <div class="vx-col vx-row sm:w-2/3 w-full">
            <div class="vx-col sm:w-1/6">
              <input type="radio" value="1" v-model="pbf" />
              <label> Yes</label>
            </div>
            <div class="vx-col sm:w-1/6">
              <input type="radio" value="0" v-model="pbf" />
              <label> No</label>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
      <!-- Delivery Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="pt-2 h5 font-semibold">Delivery Data</span>
        <hr />
        <!--  -->
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Allow Partial</span>
          </div>
          <div class="vx-col vx-row sm:w-2/3 w-full">
            <div class="vx-col sm:w-1/6">
              <input
                type="radio"
                value="1"
                v-model="allowPartialNum"
                :disabled="disableAllowPartial"
              />
              <label> Yes</label>
            </div>
            <div class="vx-col sm:w-1/6">
              <input
                type="radio"
                value="0"
                v-model="allowPartialNum"
                :disabled="disableAllowPartial"
              />
              <label> No</label>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Delivery Type</span>
          </div>
          <div class="vx-col vx-row sm:w-2/3 w-full">
            <!-- MIX2-2212 open beberapa field on edit dan remove btn delete item -->
            <multiselect
              class="selectExample"
              v-model="deliveryType.selected"
              :options="deliveryType.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder=" Type to search"
              track-by="id"
              label="name"
              :disabled="this.disable_form"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <!--  -->
        <div v-if="salesOrder.Category == 'ZDSM'" class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Expense Type</span>
          </div>
          <div class="vx-col vx-row sm:w-2/3 w-full">
            <vs-input
              class="w-full"
              :value="
                salesOrder.ExpenseType == 1
                  ? 'Principal'
                  : salesOrder.ExpenseType == 2
                  ? 'Distributor'
                  : 'Unknown'
              "
              :disabled="this.disable_form"
            />
          </div>
        </div>
        <!--  -->
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Req. Delivery Date</span>
          </div>
          <div class="vx-col vx-row sm:w-2/3 w-full">
            <datepicker
              format="yyyy - MM - dd"
              class="w-full"
              :inline="false"
              v-model="salesOrder.RequestDeliveryDate"
              placeholder="Select Date"
              v-validate="'required'"
              :disabledDates="disabledDateRdds"
              :disabled="this.disable_form"
            ></datepicker>
          </div>
        </div>
        <!--  -->
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Note</span>
          </div>
          <div class="vx-col vx-row sm:w-2/3 w-full">
            <vs-textarea
              v-model="salesOrder.Notes"
              :disabled="this.disable_form"
              height="110px"
              :readonly="this.disable_form"
            />
          </div>
        </div>
        <!--  -->
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Flush Out</span>
          </div>
          <div class="vx-col vx-row sm:w-2/3 w-full">
            <div class="vx-col sm:w-1/6">
              <input type="radio" value="1" v-model="flushOut" disabled />
              <label> Yes</label>
            </div>
            <div class="vx-col sm:w-1/6">
              <input type="radio" value="0" v-model="flushOut" disabled />
              <label> No</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CO Line baru -->
    <div
      class="vx-row"
      style="
        @media only screen and (max-width: 800px) {
          max-width: 1230px;
          overflow: scroll;
        }
      "
    >
      <div class="vx-col w-full">
        <!-- <table class="table table-stripe" style="width:100%"> -->
        <table class="table table-bordered" style="width: 100%">
          <thead>
            <tr>
              <th style="vertical-align: middle; width: 30%" rowspan="2">
                SKU Code
              </th>
              <th
                style="vertical-align: middle; width: 20%"
                rowspan="2"
                colspan="2"
                v-if="isExistSkuCombo"
              >
                Item Combo
              </th>
              <th style="vertical-align: middle; width: 10%" rowspan="2">
                HU Label
              </th>
              <th style="text-align: center; width: 15%" colspan="2">Qty</th>
              <th
                style="vertical-align: middle; text-align: center"
                colspan="4"
              >
                List Price
              </th>
              <!-- Hide discount -->
              <th
                style="vertical-align: middle; text-align: center"
                rowspan="2"
              >
                Discount
              </th>
              <th
                style="
                  vertical-align: middle;
                  text-align: center;
                  max-width: 70px;
                "
                rowspan="2"
              >
                Rounding
              </th>
              <th
                style="vertical-align: middle; text-align: center"
                rowspan="2"
              >
                Tax
              </th>
              <th
                style="vertical-align: middle; text-align: center"
                rowspan="2"
              >
                Grand Total
              </th>
              <!-- <th style="vertical-align: middle; text-align:center;" rowspan="2">Promotion</th> -->
              <th
                style="vertical-align: middle; text-align: center"
                rowspan="2"
              >
                {{ !disable_form ? "Action" : "-" }}
              </th>
            </tr>
            <tr>
              <th style="text-align: center">HU</th>
              <th style="text-align: center">UOM</th>
              <th style="text-align: center">Unit</th>
              <th style="text-align: center">Cogs</th>
              <th style="text-align: center">Price</th>
              <th style="text-align: center">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <!-- Struktur baru, array-of-object -->
            <template v-for="(dt, index) in table.data">
              <!-- Start for SKU non combo -->
              <tr
                :key="index"
                v-if="
                  ((dt.freeItems == undefined || !dt.freeItems) &&
                    !isExistSkuCombo) ||
                  dt.hu_labels.length == 0 ||
                  (!dt.hu_labels[0].is_combo &&
                    (dt.data_combo ? dt.data_combo.length == 0 : true))
                "
                :class="[
                  dt.isBlink && !dt.is_rejected
                    ? 'selected_parent_fg'
                    : dt.is_rejected
                    ? 'disabled-row'
                    : '',
                ]"
              >
                <td
                  style="align-item-center; vertical-align: middle; width: 300px; max-width: 400px;"
                  :rowspan="dt.list_price.length + dt.rowSpanPlus"
                >
                  <!-- <div :class="''" :key="index2" v-for="(dt2, index2) in dt.formInputs"> -->
                  <div :class="''">
                    <FormInput
                      :components="dt.formInputs[0]"
                      @onSelected="onselected(dt.formInputs[0].value, index)"
                      @search-change="handlerSearchSelect"
                    />
                    <div v-if="!dt.freeItems" class="mt-2">
                      <strong v-if="dt.allow_partial == 1" class="text-success">
                        Allow Partial -
                      </strong>
                      <strong
                        v-if="
                          dt.formInputs[0].value.item_type != undefined &&
                          dt.formInputs[0].value.item_type_name != undefined
                        "
                        class="text-success"
                      >
                        {{ "SKU Type" }}
                      </strong>
                      <span
                        v-if="
                          dt.formInputs[0].value.item_type != undefined &&
                          dt.formInputs[0].value.item_type_name != undefined
                        "
                      >
                        :
                        {{
                          dt.formInputs[0].value.item_type +
                          " || " +
                          dt.formInputs[0].value.item_type_name
                        }}
                      </span>
                    </div>
                    <div v-show="dt.freeItems" class="mt-2">
                      <strong class="text-success"> {{ "Free Items" }} </strong>
                    </div>
                  </div>
                </td>
                <td
                  v-if="isExistSkuCombo"
                  style="vertical-align: middle; text-align: center"
                  :rowspan="dt.list_price.length + dt.rowSpanPlus"
                  colspan="2"
                >
                  -
                </td>
                <td
                  v-if="dt.hu_labels.length > 0"
                  style="vertical-align: middle"
                  :rowspan="dt.list_price.length + dt.rowSpanPlus"
                >
                  <span :key="i" v-for="(dt, i) in dt.hu_labels">
                    {{ dt.unit }}
                  </span>
                </td>
                <td
                  v-else
                  style="vertical-align: middle"
                  :rowspan="dt.list_price.length + dt.rowSpanPlus"
                >
                  {{ dt.unit }}
                </td>
                <td
                  style="vertical-align: middle"
                  :rowspan="dt.list_price.length + dt.rowSpanPlus"
                >
                  <!-- LAYOUT GOOD -->
                  <div v-if="!dt.freeItems">
                    <vs-row vs-justify="center">
                      <vs-col
                        v-for="(q, ix) in dt.items_qty_hu"
                        :key="ix"
                        vs-type="flex"
                        :style="
                          'width :' + (dt.width > 0 ? dt.width - 35 : 35) + 'px'
                        "
                        vs-justify="center"
                        vs-align="center"
                      >
                        <vs-input
                          v-model="dt.items_qty_hu[ix]"
                          type="text"
                          @change="calc_itemsLineQtyHU(index, ix)"
                          :disabled="!update || disable_form"
                        ></vs-input>
                      </vs-col>
                    </vs-row>
                  </div>
                  <div v-else>
                    <vs-input
                      v-model="dt.items_qty_hu[ix]"
                      type="text"
                      @change="calc_itemsLineQtyHU(index, ix)"
                      :disabled="true"
                    ></vs-input>
                  </div>
                </td>
                <!-- <td style="vertical-align: middle;"> -->
                <!-- <vs-input v-model="dt.items_hu" class="w-full" placeholder="" @input="calc_itemsLineQty(index)"/> -->
                <!-- </td> -->
                <td
                  v-if="!dt.freeItems"
                  style="vertical-align: middle; padding-right: 25px"
                  :rowspan="dt.list_price.length + dt.rowSpanPlus"
                >
                  <vs-input-number
                    v-model="dt.items_qty"
                    class="w-full"
                    placeholder=""
                    @input="calc_itemsLineQtyConv(index)"
                    :disabled="!update || disable_form || dt.is_rejected"
                  />
                </td>
                <td v-else>
                  <vs-input-number
                    v-model="dt.items_qty"
                    class="w-full"
                    placeholder=""
                    :disabled="true"
                  />
                </td>

                <td
                  v-if="dt.list_price.length == 0"
                  style="vertical-align: middle"
                >
                  -
                </td>
                <td
                  v-if="dt.list_price.length == 0"
                  style="vertical-align: middle"
                >
                  -
                </td>
                <td
                  v-if="dt.list_price.length == 0"
                  style="vertical-align: middle"
                >
                  -
                </td>
                <td
                  v-if="dt.list_price.length == 0"
                  style="vertical-align: middle"
                >
                  -
                </td>
                <td
                  v-if="dt.list_price.length == 0"
                  style="vertical-align: middle"
                >
                  -
                </td>
                <td
                  v-if="dt.list_price.length == 0"
                  style="vertical-align: middle"
                >
                  -
                </td>
                <td
                  v-if="dt.list_price.length == 0"
                  style="vertical-align: middle"
                >
                  -
                </td>
                <td
                  v-if="dt.list_price.length == 0"
                  style="vertical-align: middle"
                >
                  -
                </td>
                <td
                  v-if="dt.list_price.length == 0"
                  style="vertical-align: middle"
                >
                  <div
                    v-if="index == table.data.length - 1 && !disable_form"
                    style="align-items: center; vertical-align: middle"
                  >
                    <div
                      class="flex gap-2"
                      style="align-items: center; vertical-align: middle"
                    >
                      <vx-tooltip text="Delete Item">
                        <vs-button
                          size="small"
                          color="red"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click="handleRemoveItem(index)"
                        />
                      </vx-tooltip>
                      <vx-tooltip text="Add Item">
                        <vs-button
                          size="small"
                          color="green"
                          icon-pack="feather"
                          icon="icon-plus"
                          @click="handleAddItem()"
                        />
                      </vx-tooltip>
                    </div>
                  </div>
                  <div
                    v-else-if="!disable_form"
                    style="align-items: center; vertical-align: middle"
                  >
                    <div
                      class="flex gap-2"
                      style="align-items: center; vertical-align: middle"
                    >
                      <vx-tooltip text="Delete Item">
                        <vs-button
                          size="small"
                          color="red"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click="handleRemoveItem(index)"
                        />
                      </vx-tooltip>
                    </div>
                  </div>
                </td>
              </tr>
              <!-- List Price -->
              <!-- diubah jadi template -->
              <template v-for="(lp, i) in table.data[index].list_price">
                <!-- <tr :key="index+'-'+i" v-on:click="expandDetail(index,i)" class="table-expend"> -->
                <tr
                  :key="index + '-' + i"
                  class="table-expend"
                  v-if="
                    (!isExistSkuCombo || dt.data_combo
                      ? dt.data_combo.length == 0
                      : true) &&
                    dt.qty_hu[i] > 0 &&
                    !dt.is_rejected
                  "
                >
                  <!-- <td class="table-expend" style="vertical-align: middle; text-align:right;" :rowspan="lp.is_expand? (disable_form ? dt.list_price.length-1 : dt.list_price.length):null" v-on:click="expandDetail(index,i)" > -->
                  <td
                    :class="[dt.is_rejected ? 'disabled-row' : 'table-expend']"
                    style="vertical-align: middle; text-align: right"
                    :rowspan="lp.is_expand ? 2 : null"
                    v-on:click="expandDetail(index, i)"
                  >
                    {{ dt.qty_hu[i] + " (" + lp.unit + ")" }} <br />
                  </td>
                  <td
                    :class="[dt.is_rejected ? 'disabled-row' : '']"
                    style="vertical-align: middle; text-align: right"
                    v-on:click="expandDetail(index, i)"
                  >
                    {{ formatCurrency(lp.cogs) }} <br />
                  </td>
                  <td
                    :class="[dt.is_rejected ? 'disabled-row' : '']"
                    style="vertical-align: middle; text-align: right"
                    v-on:click="expandDetail(index, i)"
                  >
                    {{ "@" + formatCurrency(lp.price) }} <br />
                  </td>
                  <td
                    :class="[dt.is_rejected ? 'disabled-row' : '']"
                    style="vertical-align: middle; text-align: right"
                    v-on:click="expandDetail(index, i)"
                  >
                    {{ formatCurrency(dt.subtotal.price[i]) }}<br />
                  </td>
                  <td
                    :class="[
                      dt.is_rejected ? 'disabled-row' : 'td vs-table--td',
                    ]"
                    style="vertical-align: middle; text-align: right"
                  >
                    <b
                      ><a
                        href="javascript:void(0);"
                        v-on:click="
                          showDetailDisc(lp, dt.subtotal.price[i], index, i)
                        "
                        ><b>{{ formatCurrency(lp.discount) }}</b>
                        <i class="fa fa-eye"></i></a
                    ></b>
                  </td>
                  <td
                    style="vertical-align: middle; text-align: center"
                    :class="[dt.is_rejected ? 'disabled-row' : '']"
                  >
                    <div>
                      <vs-row vs-justify="center">
                        <vs-col vs-justify="center" vs-align="center">
                          <vs-input
                            v-model="lp.rounding"
                            type="text"
                            :disabled="
                              !update ||
                              disable_form ||
                              salesOrder.Category == 'ZDSM'
                            "
                            @input="onInputRounding(index, i)"
                            style="max-width: 75px"
                          ></vs-input>
                        </vs-col>
                      </vs-row>
                    </div>
                    <!-- {{ "rounding here" }} -->
                  </td>
                  <td
                    :class="[dt.is_rejected ? 'disabled-row' : '']"
                    style="vertical-align: middle; text-align: right"
                    v-on:click="expandDetail(index, i)"
                  >
                    {{ formatCurrency(dt.subtotal.tax[i]) }} <br />
                  </td>
                  <td
                    :class="[dt.is_rejected ? 'disabled-row' : '']"
                    style="vertical-align: middle; text-align: right"
                    v-on:click="expandDetail(index, i)"
                  >
                    {{
                      formatCurrency(
                        parseFloat(dt.grand_total[i]) + parseFloat(lp.rounding)
                      )
                    }}
                    <br />
                  </td>
                  <td v-if="!disable_form && update">
                    <div v-if="index == 0 && lp.is_head && !disable_form">
                      <!-- Cannot add item, can remove item only -->
                      <!-- MIX2-2212 open beberapa field on edit dan remove btn delete item -->
                      <div
                        class="flex gap-2"
                        style="align-items: center; vertical-align: middle"
                      >
                        <!-- MIX2-2212 open beberapa field on edit dan remove btn delete item -->
                        <vs-button
                          v-if="!dt.is_rejected && dt.formInputs[0].disabled"
                          size="small"
                          color="warning"
                          @click="openModalReject(index)"
                          >Reject</vs-button
                        >
                        <vs-button
                          v-else-if="
                            dt.is_rejected &&
                            dt.formInputs[0].disabled &&
                            !disable_form
                          "
                          size="small"
                          color="warning"
                          @click="doUnReject(index)"
                          >UnReject</vs-button
                        >
                      </div>
                      <vx-tooltip
                        text="Add Item"
                        class="text-right mt-2"
                        v-if="
                          index == 0 && table.data.length == 1 && !disable_form
                        "
                      >
                        <vs-button
                          size="small"
                          color="green"
                          icon-pack="feather"
                          icon="icon-plus"
                          @click="handleAddItem()"
                        />
                      </vx-tooltip>
                    </div>
                    <!--  -->
                    <!-- MIX2-2212 open beberapa field on edit dan remove btn delete item & reactive button add item-->
                    <div
                      v-else-if="
                        index == table.data.length - 1 &&
                        lp.is_head &&
                        !disable_form
                      "
                      style="align-items: center; vertical-align: middle"
                    >
                      <div
                        class="flex gap-2"
                        style="align-items: center; vertical-align: middle"
                      >
                        <vs-button
                          v-if="!dt.is_rejected && dt.formInputs[0].disabled"
                          size="small"
                          color="warning"
                          @click="openModalReject(index)"
                          >Reject</vs-button
                        >
                        <vx-tooltip
                          v-else-if="!dt.formInputs[0].disabled"
                          text="Delete Item"
                        >
                          <vs-button
                            size="small"
                            color="red"
                            icon-pack="feather"
                            icon="icon-trash"
                            @click="handleRemoveItem(index)"
                          />
                        </vx-tooltip>
                        <vx-tooltip text="Add Item">
                          <vs-button
                            size="small"
                            color="green"
                            icon-pack="feather"
                            icon="icon-plus"
                            @click="handleAddItem()"
                          />
                        </vx-tooltip>
                      </div>
                    </div>
                    <!--  -->
                    <div
                      v-else-if="index != 0 && lp.is_head && !disable_form"
                      style="align-items: center; vertical-align: middle"
                    >
                      <div
                        class="flex gap-2"
                        style="align-items: center; vertical-align: middle"
                      >
                        <!-- MIX2-2212 open beberapa field on edit dan remove btn delete item -->
                        <vs-button
                          v-if="!dt.is_rejected && dt.formInputs[0].disabled"
                          size="small"
                          color="warning"
                          @click="openModalReject(index)"
                          >Reject</vs-button
                        >
                        <vx-tooltip
                          v-else-if="!dt.formInputs[0].disabled"
                          text="Delete Item"
                        >
                          <vs-button
                            size="small"
                            color="red"
                            icon-pack="feather"
                            icon="icon-trash"
                            @click="handleRemoveItem(index)"
                          />
                        </vx-tooltip>
                        <vs-button
                          v-else-if="
                            dt.is_rejected &&
                            dt.formInputs[0].disabled &&
                            !disable_form
                          "
                          size="small"
                          color="warning"
                          @click="doUnReject(index)"
                          >UnReject</vs-button
                        >
                      </div>
                    </div>
                    <div
                      v-else
                      style="align-items: center; vertical-align: middle"
                    ></div>
                  </td>
                  <td v-else></td>
                </tr>
                <tr :key="index + '-' + i + '_'" v-else>
                  <td
                    v-if="
                      index + '-' + i == index + '-0' &&
                      dt.data_combo.length == 0 &&
                      table.data[index].is_rejected
                    "
                    :colspan="8"
                    style="
                      align-items: center;
                      text-align: center;
                      vertical-align: middle;
                    "
                    :class="[dt.is_rejected ? 'disabled-row' : '']"
                  >
                    <b>{{
                      dt.reason.ID
                        ? "Item rejected. Reason | " +
                          dt.reason.code +
                          " - " +
                          dt.reason.reason
                        : "Item rejected. Reason Yet Selected"
                    }}</b>
                  </td>
                  <td
                    v-if="
                      index + '-' + i == index + '-0' &&
                      dt.data_combo.length == 0 &&
                      table.data[index].is_rejected &&
                      dt.formInputs[0].disabled &&
                      !disable_form
                    "
                    :colspan="8"
                    style="
                      align-items: center;
                      text-align: center;
                      vertical-align: middle;
                    "
                  >
                    <vs-button
                      size="small"
                      color="warning"
                      @click="doUnReject(index)"
                    >
                      UnReject
                    </vs-button>
                    <vx-tooltip
                      text="Add Item"
                      class="ml-3 mt-2"
                      v-if="index == table.data.length - 1 && !disable_form"
                    >
                      <vs-button
                        size="small"
                        color="green"
                        icon-pack="feather"
                        icon="icon-plus"
                        @click="handleAddItem()"
                      />
                    </vx-tooltip>
                  </td>
                </tr>
                <tr
                  :key="'expand-head-' + index + '-' + i"
                  v-if="
                    lp.is_expand &&
                    (!isExistSkuCombo ||
                      (dt.hu_labels.length == 0 ||
                      (!dt.hu_labels[0].is_combo && dt.data_combo)
                        ? dt.data_combo.length == 0
                        : true))
                  "
                >
                  <td colspan="7">
                    <!-- expand-head -->
                    <tr>
                      <th>
                        Width<br />
                        @{{ lp.width }}
                      </th>
                      <th>
                        Height <br />
                        @{{ lp.height }}
                      </th>
                      <th>
                        Length<br />
                        @{{ lp.length }}
                      </th>
                      <th>
                        Volume<br />
                        @{{ lp.volume }}
                      </th>
                      <th>
                        Weight<br />
                        @{{ lp.weight }}
                      </th>
                      <th>
                        Net Weight<br />
                        @{{ lp.net_weight }}
                      </th>
                      <th>Gross Up</th>
                      <th>DPP</th>
                      <th>Price Rule Code</th>
                      <th>Price Rule Valid From</th>
                      <th>Price Rule Valid To</th>
                      <th>Division Code</th>
                      <th>Division Description</th>
                    </tr>
                    <!-- expand-body -->
                    <tr>
                      <td>{{ lp.total_width }}</td>
                      <td>{{ lp.total_height }}</td>
                      <td>{{ lp.total_length }}</td>
                      <td>{{ lp.total_volume }}</td>
                      <td>{{ lp.total_weight }}</td>
                      <td>{{ lp.total_net_weight }}</td>
                      <td>{{ formatCurrency(lp.gross_up) }}</td>
                      <td>{{ formatCurrency(lp.dpp) }}</td>
                      <td>{{ lp.price_rule_code }}</td>
                      <td>{{ lp.price_rule_valid_from }}</td>
                      <td>{{ lp.price_rule_valid_to }}</td>
                      <td>{{ lp.division_code }}</td>
                      <td>{{ lp.division_description }}</td>
                    </tr>
                  </td>
                </tr>
              </template>
              <!-- End of sku non combo -->

              <!-- Start layout SKU Combo -->
              <tr
                :key="index"
                v-if="
                  !dt.free_item &&
                  isExistSkuCombo &&
                  dt.hu_labels.length > 0 &&
                  dt.hu_labels[0].is_combo &&
                  dt.data_combo &&
                  !dt.is_rejected
                "
                :class="[dt.is_rejected ? 'disabled-row' : '']"
              >
                <td
                  style="align-item-center; vertical-align: middle; width: 300px; max-width: 400px;"
                  :rowspan="
                    (dt.data_combo ? dt.data_combo.length + 1 : 0) +
                    comboRowSpanPlus
                  "
                >
                  <div :class="''">
                    <FormInput
                      :components="dt.formInputs[0]"
                      @onSelected="onselected(dt.formInputs[0].value, index)"
                      @search-change="handlerSearchSelect"
                    />
                    <strong v-if="isExistSkuCombo" class="text-success">
                      {{ "Combo Parent" }}
                    </strong>
                  </div>
                </td>
              </tr>
              <tr
                :key="index"
                v-if="
                  !dt.free_item &&
                  isExistSkuCombo &&
                  dt.hu_labels.length > 0 &&
                  dt.hu_labels[0].is_combo &&
                  dt.data_combo &&
                  dt.is_rejected
                "
                :class="[dt.is_rejected ? 'disabled-row' : '']"
              >
                <td
                  style="align-item-center; vertical-align: middle; width: 300px; max-width: 400px;"
                  :rowspan="
                    (dt.data_combo ? dt.data_combo.length + 1 : 0) +
                    comboRowSpanPlus
                  "
                >
                  <div :class="''">
                    <FormInput
                      :components="dt.formInputs[0]"
                      @onSelected="onselected(dt.formInputs[0].value, index)"
                      @search-change="handlerSearchSelect"
                    />
                    <strong v-if="isExistSkuCombo" class="text-success">
                      {{ "Combo Parent" }}
                    </strong>
                  </div>
                </td>
              </tr>

              <template v-for="(dtCombo, iCombo) in dt.data_combo">
                <tr
                  :key="index + '-c-' + iCombo"
                  :class="dt.qty_hu[iCombo] > 0 ? 'table-expend-combo' : ''"
                  v-if="
                    isExistSkuCombo &&
                    dt.hu_labels.length > 0 &&
                    dt.data_combo &&
                    dt.data_combo.length > 0
                  "
                  :rowspan="
                    (dt.data_combo.length > 0 ? dt.data_combo.length : 0) +
                    dtCombo.rowSpanPlusCombo
                  "
                >
                  <td
                    :rowspan="dtCombo.is_expand ? dtCombo.rowSpanPlusCombo : 1"
                    colspan="2"
                    :class="[dt.is_rejected ? 'disabled-row' : '']"
                  >
                    [{{ dtCombo.ItemCode }}] {{ dtCombo.ItemName }}
                    <strong v-if="isExistSkuCombo" class="text-success">
                      {{ "Combo Item" }}
                    </strong>
                  </td>
                  <td
                    v-if="dt.hu_labels.length > 0"
                    style="vertical-align: middle"
                    :rowspan="dtCombo.is_expand ? dtCombo.rowSpanPlusCombo : 1"
                    :class="[dt.is_rejected ? 'disabled-row' : '']"
                  >
                    <span :key="i" v-for="(dt, i) in dt.hu_labels">
                      {{ dt["unit"] + "." }}{{ dtCombo.UnitName }}
                    </span>
                  </td>
                  <td
                    v-else
                    style="vertical-align: middle"
                    :rowspan="dtCombo.is_expand ? dtCombo.rowSpanPlusCombo : 1"
                    :class="[dt.is_rejected ? 'disabled-row' : '']"
                  >
                    {{ " - " }}
                  </td>
                  <td
                    style="vertical-align: middle"
                    :rowspan="dtCombo.is_expand ? dtCombo.rowSpanPlusCombo : 1"
                    :class="[dt.is_rejected ? 'disabled-row' : '']"
                  >
                    <!-- LAYOUT GOOD -->
                    <div>
                      <vs-row vs-justify="center">
                        <vs-col
                          v-for="(q, ix) in dt.items_qty_hu"
                          :key="ix"
                          vs-type="flex"
                          :style="
                            'width :' +
                            (dtCombo.width > 0 ? dtCombo.width : 70) +
                            'px'
                          "
                          vs-justify="center"
                          vs-align="center"
                        >
                          <vs-input
                            v-model="dt.items_qty_hu[ix]"
                            type="text"
                            disabled="true"
                          ></vs-input>
                          <vs-input
                            v-model="dt.data_combo[iCombo].total_qty"
                            type="text"
                            disabled="true"
                          ></vs-input>
                        </vs-col>
                      </vs-row>
                    </div>
                  </td>
                  <td
                    style="vertical-align: middle; padding-right: 25px"
                    v-if="iCombo == 0"
                    :rowspan="dt.data_combo.length + comboRowSpanPlus"
                    :class="[dt.is_rejected ? 'disabled-row' : '']"
                  >
                    <vs-input-number
                      v-model="dt.items_qty"
                      class="w-full"
                      placeholder=""
                      :disabled="dt.is_rejected"
                      @input="calc_itemsLineQtyConv(index)"
                    />
                  </td>
                  <!-- Batas awal listprice sampai acton for combo -->
                  <!-- <tr :key="index+'-c-'+iCombo" :class="dt.qty_hu[iCombo]>0?'table-expend-combo':''" v-if="isExistSkuCombo&&(dt.hu_labels.length>0&&dt.data_combo&&dt.data_combo.length>0)"> -->
                  <template
                    v-if="
                      !dt.is_rejected &&
                      dt.items_qty_hu[0] > 0 &&
                      dt.list_price.length > 0
                    "
                  >
                    <!-- :class="[(dt.is_rejected ? 'disabled-row' : '') ]" -->
                    <td
                      style="vertical-align: middle; text-align: right"
                      class="combo"
                      v-on:click="expandDetailCombo(index, iCombo)"
                      :rowspan="
                        dtCombo.is_expand ? dtCombo.rowSpanPlusCombo : 1
                      "
                    >
                      {{
                        dt.list_price.length > 0
                          ? dt.data_combo[iCombo].total_qty_combo +
                            " (" +
                            dt.list_price[iCombo].unit +
                            ")"
                          : ""
                      }}
                    </td>
                    <td
                      style="vertical-align: middle; text-align: right"
                      class="combo"
                      v-on:click="expandDetailCombo(index, iCombo)"
                    >
                      {{
                        dt.list_price.length > 0
                          ? formatCurrency(dt.list_price[iCombo].cogs)
                          : 0
                      }}
                    </td>
                    <td
                      style="vertical-align: middle; text-align: right"
                      class="combo"
                      v-on:click="expandDetailCombo(index, iCombo)"
                    >
                      {{
                        "@" +
                        (dt.list_price.length > 0
                          ? formatCurrency(dt.list_price[iCombo].price)
                          : 0)
                      }}
                    </td>
                    <td
                      class="combo"
                      style="vertical-align: middle; text-align: right"
                      v-on:click="expandDetailCombo(index, iCombo)"
                    >
                      {{
                        dt.list_price.length > 0
                          ? formatCurrency(dt.subtotal.price[iCombo])
                          : 0
                      }}
                    </td>
                    <td
                      class="combo"
                      style="vertical-align: middle; text-align: right"
                      v-on:click="expandDetailCombo(index, iCombo)"
                    >
                      <b
                        ><a
                          href="javascript:void(0);"
                          v-on:click="
                            showDetailDisc(
                              dt.list_price[iCombo],
                              dt.subtotal.price[iCombo],
                              index,
                              iCombo
                            )
                          "
                          >{{ formatCurrency(dt.list_price[iCombo].discount) }}
                          <i class="fa fa-eye"></i> </a
                      ></b>
                    </td>
                    <td class="combo" style="vertical-align: middle">
                      <div v-if="dt.list_price.length > 0">
                        <vs-row vs-justify="center">
                          <vs-col vs-justify="center" vs-align="center">
                            <vs-input
                              v-model="dt.list_price[iCombo].rounding"
                              :disabled="
                                !update ||
                                disable_form ||
                                salesOrder.Category == 'ZDSM'
                              "
                              type="text"
                              @input="onInputRounding(index, iCombo)"
                              style="max-width: 75px"
                            ></vs-input>
                          </vs-col>
                        </vs-row>
                      </div>
                      <!-- {{ "rounding here" }} -->
                    </td>
                    <td
                      style="vertical-align: middle; text-align: right"
                      class="combo"
                      v-on:click="expandDetailCombo(index, iCombo)"
                    >
                      {{
                        dt.list_price.length > 0
                          ? formatCurrency(dt.subtotal.tax[iCombo])
                          : 0
                      }}
                    </td>
                    <td
                      v-if="soType != null && soType.Code == 'ZDSM'"
                      style="vertical-align: middle; text-align: right"
                      class="combo"
                      v-on:click="expandDetailCombo(index, iCombo)"
                    >
                      {{
                        dt.list_price.length > 0
                          ? formatCurrency(dt.subtotal.disc[iCombo])
                          : 0
                      }}
                    </td>
                    <td
                      style="vertical-align: middle; text-align: right"
                      class="combo"
                      v-on:click="expandDetailCombo(index, iCombo)"
                    >
                      {{
                        dt.list_price.length > 0
                          ? formatCurrency(dt.grand_total[iCombo])
                          : 0
                      }}
                    </td>
                    <td v-if="iCombo == 0" style="vertical-align: middle">
                      <div
                        v-if="index == 0"
                        class="flex gap-2"
                        style="align-items: center; vertical-align: middle"
                      >
                        <!-- MIX2-2212 open beberapa field on edit dan remove btn delete item -->
                        <vs-button
                          v-if="!dt.is_rejected && dt.formInputs[0].disabled"
                          size="small"
                          color="warning"
                          @click="openModalReject(index)"
                          >Reject</vs-button
                        >
                        <vs-button
                          v-else-if="
                            dt.is_rejected &&
                            dt.formInputs[0].disabled &&
                            !disable_form
                          "
                          size="small"
                          color="warning"
                          @click="doUnReject(index)"
                          >UnReject</vs-button
                        >
                        <!-- 1130 -->
                        <vx-tooltip
                          v-if="
                            (!dt.formInputs[0].disabled || !disable_form) &&
                            index == table.data.length - 1
                          "
                          text="Add Item"
                        >
                          <vs-button
                            size="small"
                            color="green"
                            icon-pack="feather"
                            icon="icon-plus"
                            @click="handleAddItem()"
                          />
                        </vx-tooltip>
                        <vx-tooltip
                          v-if="!dt.formInputs[0].disabled"
                          text="Delete Item"
                        >
                          <vs-button
                            size="small"
                            color="red"
                            icon-pack="feather"
                            icon="icon-trash"
                            @click="handleRemoveItem(index)"
                          />
                        </vx-tooltip>
                        <!--  -->
                      </div>
                      <div
                        v-else
                        style="align-items: center; vertical-align: middle"
                        class="flex gap-2"
                      >
                        <vs-button
                          v-if="!dt.is_rejected && dt.formInputs[0].disabled"
                          size="small"
                          color="warning"
                          @click="openModalReject(index)"
                          >Reject</vs-button
                        >
                        <vs-button
                          v-else-if="
                            dt.is_rejected &&
                            dt.formInputs[0].disabled &&
                            !disable_form
                          "
                          size="small"
                          color="warning"
                          @click="doUnReject(index)"
                          >UnReject</vs-button
                        >
                        <!--  -->
                        <vx-tooltip
                          v-if="
                            (!dt.formInputs[0].disabled || !disable_form) &&
                            index == table.data.length - 1
                          "
                          text="Add Item"
                        >
                          <vs-button
                            size="small"
                            color="green"
                            icon-pack="feather"
                            icon="icon-plus"
                            @click="handleAddItem()"
                          />
                        </vx-tooltip>
                        <vx-tooltip
                          v-if="!dt.formInputs[0].disabled"
                          text="Delete Item"
                        >
                          <vs-button
                            size="small"
                            color="red"
                            icon-pack="feather"
                            icon="icon-trash"
                            @click="handleRemoveItem(index)"
                          />
                        </vx-tooltip>
                      </div>
                    </td>
                  </template>
                  <template
                    v-else-if="!dt.is_rejected && dt.items_qty_hu[0] > 0"
                  >
                    <!-- <td :colspan="8">Disini ya</td> -->
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      {{ "0 (" + dtCombo.UnitName + ")" }}
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      <vs-input
                        :disabled="true"
                        value="0"
                        type="text"
                        style="max-width: 75px"
                      ></vs-input>
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <!-- tes -->
                    <td v-if="iCombo == 0">
                      <vs-button
                        v-if="!dt.is_rejected && dt.formInputs[0].disabled"
                        size="small"
                        color="warning"
                        @click="openModalReject(index)"
                        >Reject</vs-button
                      >
                      <vs-button
                        v-else-if="
                          dt.is_rejected &&
                          dt.formInputs[0].disabled &&
                          !disable_form
                        "
                        size="small"
                        color="warning"
                        @click="doUnReject(index)"
                        >UnReject</vs-button
                      >
                      <!-- 1130 -->
                      <vx-tooltip
                        v-if="
                          (!dt.formInputs[0].disabled || !disable_form) &&
                          index == table.data.length - 1
                        "
                        text="Add Item"
                      >
                        <vs-button
                          size="small"
                          color="green"
                          icon-pack="feather"
                          icon="icon-plus"
                          @click="handleAddItem()"
                        />
                      </vx-tooltip>
                      <vx-tooltip
                        v-if="!dt.formInputs[0].disabled"
                        text="Delete Item"
                      >
                        <vs-button
                          size="small"
                          color="red"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click="handleRemoveItem(index)"
                        />
                      </vx-tooltip>
                    </td>
                    <td v-else></td>
                    <!-- tes -->
                  </template>
                  <template
                    v-else-if="dt.is_rejected && dt.list_price.length == 0"
                  >
                    <td
                      :colspan="8"
                      :rowspan="dt.data_combo.length"
                      style="
                        align-items: center;
                        text-align: center;
                        vertical-align: middle;
                      "
                      v-if="iCombo == 0"
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                    >
                      <b>{{
                        dt.reason.ID
                          ? "Item rejected. Reason | " +
                            dt.reason.code +
                            " - " +
                            dt.reason.reason
                          : "Item rejected. Reason Yet Selected"
                      }}</b>
                    </td>
                    <td
                      :rowspan="dt.data_combo.length"
                      v-if="iCombo == 0 && !disable_form"
                      style="
                        align-items: center;
                        text-align: center;
                        vertical-align: middle;
                      "
                    >
                      <vs-button
                        size="small"
                        color="warning"
                        @click="doUnReject(index)"
                      >
                        UnReject
                      </vs-button>
                    </td>
                  </template>
                  <template
                    v-else-if="
                      !dt.is_rejected &&
                      dt.list_price.length == 0 &&
                      index != table.data.length - 1
                    "
                  >
                    <!-- <td :colspan="8">Kanan 1175</td> -->
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      {{ "0 (" + dtCombo.UnitName + ")" }}
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      <vs-input
                        :disabled="true"
                        value="0"
                        type="text"
                        style="max-width: 75px"
                      ></vs-input>
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td>
                      <div
                        v-if="
                          !disable_form &&
                          !dt.formInputs[0].disabled &&
                          iCombo == table.data[index].data_combo.length - 1 &&
                          update
                        "
                        style="align-items: center; vertical-align: middle"
                      >
                        <div
                          class="flex gap-2"
                          style="align-items: center; vertical-align: middle"
                        >
                          <!-- Button add and delete -->
                          <vx-tooltip text="Delete Item">
                            <vs-button
                              size="small"
                              color="red"
                              icon-pack="feather"
                              icon="icon-trash"
                              @click="handleRemoveItem(index)"
                            />
                          </vx-tooltip>
                        </div>
                      </div>
                    </td>
                  </template>
                  <template
                    v-else-if="
                      !dt.is_rejected &&
                      dt.list_price.length == 0 &&
                      index == table.data.length - 1
                    "
                  >
                    <!-- <td :colspan="8">Kanan 1194</td> -->
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      {{ "0 (" + dtCombo.UnitName + ")" }}
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      <vs-input
                        :disabled="true"
                        value="0"
                        type="text"
                        style="max-width: 75px"
                      ></vs-input>
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                      style="vertical-align: middle; text-align: right"
                    >
                      -
                    </td>
                    <td>
                      <div
                        v-if="
                          !disable_form &&
                          iCombo == table.data[index].data_combo.length - 1 &&
                          update
                        "
                        style="align-items: center; vertical-align: middle"
                      >
                        <div
                          class="flex gap-2"
                          style="align-items: center; vertical-align: middle"
                        >
                          <vx-tooltip
                            v-if="!dt.formInputs[0].disabled"
                            text="Add Item"
                          >
                            <vs-button
                              size="small"
                              color="green"
                              icon-pack="feather"
                              icon="icon-plus"
                              @click="handleAddItem()"
                            />
                          </vx-tooltip>
                          <vx-tooltip
                            v-if="!dt.formInputs[0].disabled"
                            text="Delete Item"
                          >
                            <vs-button
                              size="small"
                              color="red"
                              icon-pack="feather"
                              icon="icon-trash"
                              @click="handleRemoveItem(index)"
                            />
                          </vx-tooltip>
                        </div>
                      </div>
                    </td>
                  </template>
                  <template v-else>
                    <td
                      v-if="!dt.is_rejected && !calculated"
                      :colspan="9"
                      style="
                        align-items: center;
                        text-align: center;
                        vertical-align: middle;
                      "
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                    ></td>
                    <!-- Un Reject -->
                    <td
                      v-if="dt.is_rejected && !calculated"
                      :colspan="8"
                      style="
                        align-items: center;
                        text-align: center;
                        vertical-align: middle;
                      "
                      :class="[dt.is_rejected ? 'disabled-row' : '']"
                    >
                      <b>{{
                        dt.reason.ID
                          ? "Item rejected. Reason | " +
                            dt.reason.code +
                            " - " +
                            dt.reason.reason
                          : "Item rejected. Reason Yet Selected"
                      }}</b>
                    </td>
                    <td
                      v-if="
                        iCombo == 0 &&
                        dt.is_rejected &&
                        !calculated &&
                        dt.formInputs[0].disabled &&
                        !disable_form
                      "
                      :colspan="8"
                      style="
                        align-items: center;
                        text-align: center;
                        vertical-align: middle;
                      "
                    >
                      <vs-button
                        size="small"
                        color="warning"
                        @click="doUnReject(index)"
                      >
                        UnReject
                      </vs-button>
                      <div
                        class="flex gap-2"
                        style="align-items: center; vertical-align: middle"
                      >
                        <vx-tooltip
                          v-if="
                            index == table.data.length - 1 &&
                            dt.formInputs[0].disabled
                          "
                          text="Add Item"
                        >
                          <vs-button
                            size="small"
                            color="green"
                            icon-pack="feather"
                            icon="icon-plus"
                            @click="handleAddItem()"
                          />
                        </vx-tooltip>
                        <vx-tooltip
                          v-if="
                            !dt.formInputs[0].disabled &&
                            index == data.length - 1
                          "
                          text="Delete Item"
                        >
                          <vs-button
                            size="small"
                            color="red"
                            icon-pack="feather"
                            icon="icon-trash"
                            @click="handleRemoveItem(index)"
                          />
                        </vx-tooltip>
                      </div>
                    </td>
                  </template>
                </tr>
                <!-- Batas akhir listprice sampai acton for combo -->
                <!-- <template>
                        </template> -->
                <tr
                  :key="'expand-head-' + index + '-' + iCombo"
                  v-if="dtCombo.is_expand"
                >
                  <td colspan="6">
                    <!-- expand-head -->
                    <tr>
                      <th>
                        Width<br />
                        @{{ dt.list_price[iCombo].width }}
                      </th>
                      <th>
                        Height <br />
                        @{{ dt.list_price[iCombo].height }}
                      </th>
                      <th>
                        Length<br />
                        @{{ dt.list_price[iCombo].length }}
                      </th>
                      <th>
                        Volume<br />
                        @{{ dt.list_price[iCombo].volume }}
                      </th>
                      <th>
                        Weight<br />
                        @{{ dt.list_price[iCombo].weight }}
                      </th>
                      <th>
                        Net Weight<br />
                        @{{ dt.list_price[iCombo].net_weight }}
                      </th>
                      <th>Price Rule Code</th>
                      <th>Price Rule Valid To</th>
                      <th>Price Rule Valid From</th>
                      <th>Division Code</th>
                      <th>Division Description</th>
                    </tr>
                    <!-- expand-body -->
                    <tr>
                      <td>{{ dt.list_price[iCombo].total_width }}</td>
                      <td>{{ dt.list_price[iCombo].total_height }}</td>
                      <td>{{ dt.list_price[iCombo].total_length }}</td>
                      <td>{{ dt.list_price[iCombo].total_volume }}</td>
                      <td>{{ dt.list_price[iCombo].total_weight }}</td>
                      <td>{{ dt.list_price[iCombo].total_net_weight }}</td>
                      <td>{{ dt.list_price[iCombo].price_rule_code }}</td>
                      <td>{{ dt.list_price[iCombo].price_rule_valid_from }}</td>
                      <td>{{ dt.list_price[iCombo].price_rule_valid_to }}</td>
                      <td>{{ dt.list_price[iCombo].division_code }}</td>
                      <td>{{ dt.list_price[iCombo].division_description }}</td>
                    </tr>
                  </td>
                </tr>
              </template>
              <!-- End layout SKU Combo -->
            </template>

            <!-- sTART LAYOUT FREE items -->
            <!-- row free good -->
            <template v-if="existing_fg">
              <tr>
                <td colspan="100%" class="p-0">
                  <vs-divider><b>Free Good</b></vs-divider>
                </td>
              </tr>
              <tr>
                <th style="vertical-align: middle; width: 30%">SKU Code</th>
                <th v-if="!isExistSkuCombo"></th>
                <th style="vertical-align: middle; width: 10%">
                  Promotion Type
                </th>
                <th style="vertical-align: middle; width: 30%">
                  Promotion Code
                </th>
                <th
                  style="text-align: center; width: 15%"
                  v-if="isExistSkuCombo"
                >
                  -
                </th>
                <th
                  style="vertical-align: middle; text-align: center"
                  v-if="isExistSkuCombo"
                  colspan="2"
                >
                  -
                </th>
                <th style="vertical-align: middle; text-align: center">Unit</th>
                <th style="vertical-align: middle; text-align: center">Cogs</th>
                <th style="vertical-align: middle; text-align: center">
                  Price
                </th>
                <th style="vertical-align: middle; text-align: center">
                  Subtotal
                </th>
                <th style="vertical-align: middle; text-align: center">
                  Discount
                </th>
                <th style="vertical-align: middle; text-align: center"></th>
                <th style="vertical-align: middle; text-align: center"></th>
                <th style="vertical-align: middle; text-align: center"></th>
                <th style="vertical-align: middle; text-align: center"></th>
              </tr>
              <template v-for="(dt, index) in table.free_goods">
                <tr :key="index + '-fg'" v-if="existing_fg">
                  <td
                    style="align-item-center; vertical-align: middle; width: 300px; max-width: 400px;"
                  >
                    <!-- @onSelected="onselected2" -->
                    <FormInput
                      :components="table.free_goods[index].fg_component"
                      :i_data="index"
                      @onSelected="onselected_fg(index)"
                      @search-change="handlerSearchSelect_fg"
                    />
                    <strong v-if="dt.allow_partial == 1" class="text-success">
                      Allow Partial
                    </strong>
                  </td>
                  <th v-if="!isExistSkuCombo"></th>
                  <td style="vertical-align: middle; text-align: center">
                    {{ dt.promotion_type }}
                  </td>
                  <td style="vertical-align: middle; text-align: center">
                    {{ dt.promotion_code }}
                  </td>
                  <td
                    style="vertical-align: middle; text-align: center"
                    v-if="isExistSkuCombo"
                  >
                    -
                  </td>
                  <td
                    style="vertical-align: middle; text-align: center"
                    colspan="2"
                    v-if="isExistSkuCombo"
                  >
                    -
                  </td>
                  <td style="vertical-align: middle">
                    {{ dt.fg_component.value.qty }}
                    {{ dt.fg_component.value.item_unit }}
                  </td>
                  <td style="vertical-align: middle; text-align: right">
                    {{ dt.fg_component.value.cogs }}
                  </td>
                  <td style="vertical-align: middle; text-align: right">
                    {{
                      (dt.fg_component.value.price
                        ? formatCurrency(dt.fg_component.value.price)
                        : "-") +
                      (dt.fg_component.value.capped ? " (capped)" : "")
                    }}
                  </td>
                  <td style="vertical-align: middle; text-align: right">
                    {{
                      dt.fg_component.value.discount
                        ? formatCurrency(dt.fg_component.value.discount)
                        : "-"
                    }}
                  </td>
                  <td style="vertical-align: middle; text-align: right">
                    {{
                      dt.fg_component.value.discount
                        ? formatCurrency(dt.fg_component.value.discount)
                        : "-"
                    }}
                  </td>
                  <td v-if="calculated" style="vertical-align: middle">
                    <b
                      ><a
                        href="javascript:void(0);"
                        v-on:click="checkFgFrom(table.free_goods[index])"
                        ><b>Parent</b> <i class="fa fa-eye"></i></a
                    ></b>
                  </td>
                  <td style="vertical-align: middle"></td>
                  <td style="vertical-align: middle"></td>
                  <td style="vertical-align: middle"></td>
                  <td style="vertical-align: middle"></td>
                </tr>
              </template>
            </template>
            <!-- End layout free items -->

            <tr
              v-if="
                table.data[0].list_price.length != 0 &&
                this.CalcRespData != null
              "
            >
              <td
                :colspan="isExistSkuCombo ? 8 : 7"
                style="vertical-align: middle; text-align: right"
              >
                <b>Total</b>
              </td>
              <td style="vertical-align: middle; text-align: right">
                <b>{{
                  formatCurrency(this.CalcRespData.total_order.toFixed(2))
                }}</b>
              </td>
              <!-- Hide discount -->
              <td style="vertical-align: middle; text-align: right">
                <b>{{
                  formatCurrency(this.CalcRespData.total_discount.toFixed(2))
                }}</b>
              </td>
              <td style="vertical-align: middle; text-align: right">
                <b>{{
                  formatCurrency(this.CalcRespData.total_rounding.toFixed(2))
                }}</b>
              </td>
              <td style="vertical-align: middle; text-align: right">
                <b>{{
                  formatCurrency(this.CalcRespData.total_tax.toFixed(2))
                }}</b>
              </td>
              <td style="vertical-align: middle; text-align: right">
                <b>{{
                  formatCurrency(this.CalcRespData.total_billing.toFixed(2))
                }}</b>
              </td>
              <td></td>
            </tr>
            <tr v-else>
              <td
                :colspan="isExistSkuCombo ? 9 : 7"
                style="vertical-align: middle; text-align: right"
              >
                <b>Total</b>
              </td>
              <td style="vertical-align: middle; text-align: right">
                <b>{{
                  formatCurrency(this.manual_calc_resp.total_netto.toFixed(2))
                }}</b>
              </td>
              <!-- Hide discount -->
              <!-- <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.CalcRespData.total_discount.toFixed(2)) }}</b> </td> -->
              <td style="vertical-align: middle; text-align: right">
                <b>{{
                  formatCurrency(
                    this.manual_calc_resp.total_discount.toFixed(2)
                  )
                }}</b>
              </td>
              <td style="vertical-align: middle; text-align: right">
                <b>{{ this.manual_calc_resp.total_rounding.toFixed(2) }}</b>
              </td>
              <td style="vertical-align: middle; text-align: right">
                <b>{{
                  formatCurrency(this.manual_calc_resp.total_tax.toFixed(2))
                }}</b>
              </td>
              <td style="vertical-align: middle; text-align: right">
                <b>{{
                  formatCurrency(this.manual_calc_resp.total_billing.toFixed(2))
                }}</b>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <vs-popup fullscreen title="Discount Details" :active.sync="detailDisc">
      <div class="vx-row mb-6">
        <div class="vx-col w-full" v-if="detailDisc">
          <discountDetail
            :selectedDiscount="selectedDiscount"
            :DetailPricing="detailPricing"
            :Sku="sku"
            :calculated="calculated"
            :PricingCode="pricingCode"
            :Pricing="formatCurrency(pricing)"
            :Subtotal="formatCurrency(subtotal)"
            :Rounding="rounding"
          ></discountDetail>
        </div>
      </div>
    </vs-popup>
    <div class="vx-row mt-2" v-if="update">
      <div class="vx-col w-1/2">
        <vs-button
          v-if="!calculated || redirectFrom == 'debug'"
          class="float-left"
          type="border"
          color="primary"
          @click="handleCalculate()"
        >
          Calculate
        </vs-button>
        <vs-button
          v-else
          class="float-left"
          color="success"
          type="border"
          @click="handleSubmit()"
        >
          Submit
        </vs-button>
      </div>
      <div v-if="calculated" class="vx-col w-1/2">
        <table class="table" style="width: 100%">
          <thead>
            <tr>
              <td style="text-align: right"><b>Total Net Weight</b></td>
              <td style="text-align: right">
                {{
                  formatCurrency(this.CalcRespData.total_net_value.toFixed(2))
                }}
              </td>
            </tr>
            <tr>
              <td style="text-align: right"><b>Total Weight</b></td>
              <td style="text-align: right">
                {{ formatCurrency(this.CalcRespData.total_weight.toFixed(2)) }}
              </td>
            </tr>
            <tr>
              <td style="text-align: right"><b>Total Volume</b></td>
              <td style="text-align: right">
                {{ formatCurrency(this.CalcRespData.total_volume.toFixed(2)) }}
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </vx-card>
</template>

<script>
// import from CO form
import moment from "moment";
import FormInput from "./form_input.vue";
import Datepicker from "vuejs-datepicker";
import AddressComponent from "@/components/master/AddressOtc";

import VueNumberInput from "@chenfengyuan/vue-number-input";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import fieldPromotionCode from "../component/field_promotion_code.vue";
import datatablePromotionCode from "../component/datatable_promotion_code.vue";
import discountDetail from "../component/discdetail.vue";
import SelectMasterReason from "@/components/master/SelectMasterReason.vue";

export default {
  props: [
    "territoryCode",
    "territoryId",
    "selectedSO",
    "optionTerritory",
    "selectedTerritory",
    "selectedDistChannel",
    "selectedSalesChannel",
    "redirectFrom",
    "IsCancel",
  ],
  components: {
    Datepicker,
    VueNumberInput,
    flatPickr,
    fieldPromotionCode,
    datatablePromotionCode,
    FormInput,
    AddressComponent,
    SelectMasterReason,
    discountDetail,
  },
  mounted() {
    // console.log("userLogin : ", this.$userLogin);
    // console.log(this.selectedSO)
    // console.log(this.selectedTerritory)
    // console.log(this.selectedDistChannel)
    // console.log("this.IsCancel", this.IsCancel)
    if (this.selectedSO && this.selectedSO.Code) {
      this.$vs.loading();
      this.salesOrder = this.selectedSO;
      Promise.all([
        this.getSOTypes(),
        this.getOptionSalesChannel(),
        this.getOptionSalesID(true),
        this.getOptionZones(),
        this.getAllDataPricingGroup(),
        this.getOptionPaymentTerm(),
        this.getOptionDeliveryType(),
        this.getItem(),
        this.getData(),
      ]).then((resp) => {
        console.log(resp);
        console.log("response", resp);
        if (this.salesOrder.Category == "ZDCBD") {
          this.getCustDeposit_(
            parseInt(this.salesOrder.Customer.DefaultAttribute.ID)
          );
        }
        let is_success = true;
        resp.forEach((r) => {
          if (!r) {
            is_success = r;
          }
        });
        if (is_success) {
          // disini kalau success semua persiapan datanya

          this.dispTerritory =
            this.selectedSO.Territory.Code +
            " " +
            this.selectedSO.Territory.Name;
          this.dispDistChannel =
            this.selectedSO.Customer.CustomerCategory.Name +
            " - " +
            this.selectedSO.Customer.CustomerCategory.Classification;
          this.dispSalesChannel =
            this.selectedSO.SalesChannel.Code +
            " " +
            this.selectedSO.SalesChannel.Name;
          this.resetSalesOrder = Object.assign({}, this.selectedSO);
          this.salesOrder.Customer = this.selectedSO.Customer;
          this.salesOrder.Date = new Date(this.salesOrder.Date);
          this.salesOrder.DueDate = new Date(this.salesOrder.DueDate);
          this.salesOrder.RequestDeliveryDate = new Date(
            this.salesOrder.RequestDeliveryDate
          );
          this.salesOrder.PoReferenceDate =
            this.salesOrder.PoReferenceDate != null
              ? new Date(this.salesOrder.PoReferenceDate)
              : null;
          this.baseUrl =
            this.baseUrl + "/" + this.salesOrder.DefaultAttribute.ID;

          this.salesOrder.Customer.DisplayName =
            this.salesOrder.Customer.Code +
            " - " +
            this.salesOrder.Customer.Name;

          this.external = false; // sementara dibikin true, belom ada object saleschannel di api SO

          this.disable_form = false;
          this.update = true;

          if (this.redirectFrom == "monitoring") {
            this.disable_form = true;
            this.update = false;
          } else if (this.redirectFrom == "debug") {
            this.disable_form = false;
            this.update = true;
          }
          this.setDataSO();

          let rddLimit = new Date();
          rddLimit.setDate(rddLimit.getDate() + 1);
          this.disabledDateRdds.to = rddLimit;
        } else {
          console.log(resp);
        }
        this.$vs.loading.close();
      });
    } else {
      console.log("masuk else lho");
      this.update = false;
      this.disable_form = true;
      console.log("this.update", this.update);
    }

    console.log("this.salesOrder", this.salesOrder);
    console.log("this.salesOrder.Customer", this.salesOrder.Customer);
  },
  computed: {
    DueDate: {
      get() {
        var d = new Date();
        if (this.selectedSO && this.selectedSO.DueDate) {
          d = new Date(this.selectedSO.DueDate);
          d.setHours(d.getHours() - 7);
        } else {
          d = new Date(this.salesOrder.DueDate);
        }
        return d;
      },
      set(val) {
        this.DueDatePost = val;
        return val;
      },
    },
    charactersLeft() {
      var char = this.salesOrder.Notes.length,
        limit = 255;

      return "counter " + (limit - char);
    },
    currentPage: {
      get() {
        return 1;
      },
      set(page) {
        return page;
      },
    },
  },
  watch: {
    territoryCode: function () {
      console.log(this.territoryCode);
    },
    selectedTerritory: function () {
      console.log(this.selectedTerritory);
      if (this.selectedSO.id !== undefined) {
        if (!this.isOneTime) {
          this.searchSales = "";
          this.getDataCustomer();
          this.getOptionSalesID(false);
          this.resetShipBillto();
        }

        this.zone.selected = this.zone.all_options.filter((v) => {
          return v.territory_code == this.selectedTerritory.code;
        });
      }
    },
    selectedCustomer: function () {
      console.log("this.selectedCustomer", this.selectedCustomer);
      this.$emit(
        "onSelectedDistChannel",
        this.selectedCustomer.customer_category_id
      );

      // filter pricing group
      this.pricingGroup.selected = this.pricingGroup.options.filter((v) => {
        return v.ID == this.selectedCustomer.pricing_group_id;
      });
      this.pricingGroup.selected = this.pricingGroup.selected[0];

      // filter payment term
      this.paymentTerm.selected = this.paymentTerm.all_options.filter((v) => {
        return v.id == this.selectedCustomer.payment_term_id;
      });
      this.paymentTerm.selected = this.paymentTerm.selected[0];
      this.paymentTerm.option = this.paymentTerm.all_options.filter((v) => {
        return (
          v.due_invoice_period <= this.paymentTerm.selected.due_invoice_period
        );
      });
    },
    selectedDistChannel: function () {
      console.log(this.selectedDistChannel);
      if (!this.isOneTime) {
        if (
          this.selectedDistChannel.name !== "All" &&
          this.selectedCustomer.code == ""
        ) {
          this.getDataCustomer();
          this.resetShipBillto();
        }
      }
    },
    selectedSalesChannel: function () {
      console.log(this.selectedSalesChannel);
      if (this.selectedSalesChannel.code !== "All") {
        this.searchSales = "";
        this.getOptionSalesID(false);
      }
    },
  },
  data() {
    return {
      modalReject: {
        activeModal: false,
        activeIndex: 0,
        form: {
          reject_reason: null,
          reject_reason_id: null,
          reason: null,
          is_rejected: null,
        },
      },
      dispDistChannel: null,
      dispSalesChannel: null,
      dispTerritory: null,
      external: false,
      deliveryOrderCodeIsDisabled: true,

      selectTerritory: {},
      selectTax: {
        Name: "",
        Rate: 0,
      },
      selectPaymentTerm: {},
      selectDeliveryType: {},
      existing_fg: false,
      resetSalesOrder: {},
      salesOrder: {
        Code: "",
        PoReferenceCode: "",
        DeliveryOrderCode: "",
        ReservationCode: "",
        Customer: {
          ID: 0,
          Name: "",
          Code: "",
        },
        Territorry: {
          Code: "",
        },
        Company: {
          Code: "",
        },
        SalesChannel: {
          Code: "",
          PriceProposal: "Disallow",
        },
        BillToAddressID: 0,
        BillAddress: {
          Address: "",
          City: "",
        },
        ShipmentToAddressID: 0,
        ShipAddress: {
          Address: "",
          City: "",
        },
        Date: "",
        PoReferenceDate: "",
        DueDate: "",
        AllowPartial: false,
        IsFlushOut: false,
        flushOut: 0,
        IsPbf: false,
        pbf: 0,
        allowPartialNum: 1, //defult yes
        Notes: "",
        PaymentTermID: 0,
        SubtotalCharge: 0,
      },
      DueDatePost: "",

      priceProposal: "Disallow",

      itemsCode: [],
      itemsName: [],
      itemsUom: [],
      itemsQuantityReq: [],
      itemsQuantityAdj: [],

      itemsPrice: [],
      itemsPriceCustom: [],
      itemsSubtotal: [],
      itemsDiscount: [],
      itemsTax: [],
      itemsLineTotal: [],

      itemsSubtotalEngine: [],
      itemsDiscountEngine: [],
      itemsTaxEngine: [],
      itemsLineTotalEngine: [],

      pricingStep: [],
      discountStep: [],
      totalDiscountStep: [],

      totalBrutto: 0,
      totalDiscount: 0,
      totalNetto: 0,
      totalTax: 0,
      totalBilling: 0,
      promotionCode: [],
      limitedItem: [],

      optionItemAvailable: [{}],
      optionTax: [{}],
      optionPaymentTerm: [{}],
      optionDeliveryType: [{}],

      limits: [10],
      limitShow: 10,
      isActive: 1,
      search: "",
      recordsTotal: 0,
      maxPage: 2,
      dataEnd: 0,

      // CO FORM
      // baseUrl: "/api/sfa/v1/customer-order/create",
      baseUrl: "/api/v3/sales-order/update",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      isErrorBillTo: false,
      msgErrorBillto: "",
      isErrorShippTop: false,
      msgErrorShippTo: "",
      deleteId: null,
      AllOptionTerritory: [],
      optionCustomer: [],
      optionBillTo: [],
      optionShippTo: [],
      isLoadingCus: false,
      isLoadingSales: false,
      isLoadingShipto: false,
      selectedCustomer: {
        code: "",
        name: "",
      },
      selectedBillTo: {
        ID: null,
        customer_id: "",
        code: "",
        address: "",
      },
      selectedShippTo: {
        ID: null,
        customer_id: "",
        code: "",
        address: "",
      },
      searchCust: "",
      searchItem: "",
      searchSales: "",
      dataCustomerOrder: {
        code: null,
      },
      dataCustomerOrderLine: {},
      table: this.tableDefaultState(),
      dateCo: null,
      duedate: null,
      note: null,
      rDeliveryDate: null,
      pararelRunDateDisabled: {},
      disabledDates: {
        // from: new Date(Date.now())
        from: new Date(),
        to: new Date(),
      },
      disabledDateRdds: {
        to: new Date(),
      },
      title: "Form Customer-order",
      allowPartial: false,
      isFlushOut: false,
      flushOut: 0,
      isPbf: false,
      pbf: 0,
      allowPartialNum: 0,
      poRefCode: null,
      isOneTime: false,
      sameAddress: false,
      oneTimeCust: {
        id: null,
        customer: null,
        name: null,
        address_manual: null,
        address: {
          address: "",
          postalCode: "",
          countryName: "",
          countryID: 0,
          provinceName: "",
          provinceID: 0,
          cityName: "",
          cityID: 0,
          transportZone: "",
          districtName: "",
          districtID: 0,
          subdistrictName: "",
          subdistrictID: 0,
          showWarningAddress: false,
        },
        contact_person: null,
        nik: null,
        phone: null,
        payment_method_id: null,
        tax_id: null,
      },
      pricingGroup: {
        selected: null,
        options: [],
        all_options: [],
      },
      update: false,
      disable_form: true,
      calculated: false,
      optionSoType: [],
      soType: null,
      zone: {
        selected: null,
        option: [],
        all_options: [],
      },
      salesChannel: {
        selected: null,
        option: [],
        all_options: [],
      },
      customerCategory: {
        selected: null,
        option: [],
        all_options: [],
      },
      salesPersonal: {
        selected: null,
        option: [],
        all_options: [],
      },
      paymentTerm: {
        selected: null,
        option: [],
        all_options: [],
      },
      deliveryType: {
        selected: null,
        option: [],
      },
      showOTCForm: false,
      CalcRespData: null,
      manual_calc_resp: {
        total_netto: 0,
        total_rounding: 0,
        total_tax: 0,
        total_billing: 0,
        total_discount: 0,
      },
      isExistSkuCombo: false,
      comboRowSpanPlus: 0,
      selectedDiscount: null,
      detailPricing: {},
      detailDisc: false,
      temp_free_good: {
        discount_type: 0,
        discount_string: "",
        promotion_type: "",
        promotion_code: "",
        promotion_id: 0,
        promotion_item_req_id: 0,
        promotion_sequence: 0,
        promotion_step_id: 0,
        line: 0,
        qty: 0,
        to: 0,
        from: 0,
        base: 0,
        base_amount: 0,
        base_string: "",
        fg_component: {
          disabled: false,
          validate: "required",
          name: "fg_sku",
          placeholder: "Sku Code",
          head: "Sku Code",
          type: "multiselect",
          track_by: "item_unit_id",
          isLoading: false,
          multiple: false,
          allowempty: false,
          value: {
            sku_code: "",
            item_id: 0,
            item_name: "",
            item_unit: "",
            item_unit_id: 0,
            text: "",
            qty: 0,
            price: "0",
            discount: 0,
            cap: 0,
            capped: false,
            cogs: 0,
          },
          optionValue: [],
        },
      },
      pricing: "0",
      pricingCode: "",
      sku: "",
      subtotal: "0",
      rounding: "0",
      selectedCustDeposit: [],
      optionCustDeposit: [],
      deposits: [],
      total_deposit: 0,
      initSelectedFg: [],
      disableAllowPartial: false,
    };
  },
  methods: {
    doUnReject(index) {
      this.calculated = false;
      this.$set(this.table.data, index, {
        ...this.table.data[index], // Preserve existing properties
        reject_reason: null,
        reject_reason_id: null,
        reason: null,
        is_rejected: false,
      });
      this.table.data[index].recalculate = true;
      this.table.data.forEach((_, ix) => {
        this.table.data[ix].isBlink = false;
      });
      this.$vs.notify({
        color: "warning",
        title: "Warning",
        text: "Add qty uom and or recalculate to show price.",
        position: "top-right",
        iconPack: "feather",
        icon: "icon-alert-circle",
      });
    },
    setZeroLineItem(index) {
      console.log("this.CalcRespData", this.CalcRespData);
      console.log("this.manual_calc_resp", this.manual_calc_resp);
      console.log("this.table.data[index]", index);
      console.log("data nya", this.table.data[index]);

      let minSubTotal = 0;
      let minGrandTotal = 0;

      if (this.table.data[index].data_combo.length == 0) {
        this.table.data[index].grand_total = []; //["0.00","0.00","0.00"]
        this.table.data[index].items_qty_hu = []; //[0,0,0]
        this.table.data[index].qty_hu = []; //[0,0,0]

        this.table.data[index].subtotal.price.forEach((element, x) => {
          minSubTotal += parseFloat(element);
          this.table.data[index].grand_total.push("0.00");
          this.table.data[index].items_qty_hu.push(0);
          this.table.data[index].qty_hu.push(0);
        });

        this.table.data[index].grand_total.forEach((elGt) => {
          minGrandTotal += elGt != "0.00" ? parseFloat(elGt) : 0;
        });

        console.log("minSubTotal", minSubTotal);
        console.log("minGrandTotal", minGrandTotal);

        this.table.data[index].net_weight = 0.0;
        this.table.data[index].total_height = 0.0;
        this.table.data[index].total_weight = 0.0;
        this.table.data[index].total_width = 0.0;
        this.table.data[index].total_volume = 0.0;
        this.table.data[index].total_length = 0.0;
        this.table.data[index].total_net_weight = 0.0;
        this.table.data[index].items_qty = "0";

        this.table.data[index].subtotal = {
          disc: 0.0,
          all_price: "0.00",
          all_tax: "0.00",
          price: ["0.00", "0.00", "0.00"],
          tax: ["0.00", "0.00", "0.00"],
        };
      } else {
        // perlakuan beda untuk item combo
        console.log("data nya", this.table.data[index]);
        this.table.data[index].items_hu = 0;
        this.table.data[index].items_qty = 0;
        this.table.data[index].qty_hu = [];
        this.table.data[index].list_price = [];
        this.table.data[index].items_qty_hu = [];
        this.table.data[index].items_qty_hu.push(0);
        this.table.data[index].data_combo.forEach((elCombo, ic) => {
          this.table.data[index].data_combo[ic].total_qty = 0;
          this.table.data[index].data_combo[ic].total_qty_combo = 0;
          this.table.data[index].qty_hu.push(0);
        });
      }

      console.log("data Akhir nya", index);
      console.log("this.table.data[index]", this.table.data[index]);
      console.log("this.table.data", this.table.data);
    },
    setNullFormModalReject() {
      this.modalReject.form.reason = null;
      this.modalReject.form.reject_reason = null;
      this.modalReject.form.reject_reason_id = null;
      this.modalReject.form.is_rejected = null;
    },
    addRejectFlag(index) {
      console.log("this.modalReject", this.modalReject);
      if (
        this.modalReject.form.reject_reason_id == 0 ||
        this.modalReject.form.reject_reason_id == null ||
        this.modalReject.form.reject_reason == null
      ) {
        this.$vs.notify({
          color: "warning",
          title: "Warning",
          text: "Please select reject reason !",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        this.modalReject.activeModal = true;
        return;
      } else {
        this.table.data[index].reason = this.modalReject.form.reason;
        this.table.data[index].reject_reason =
          this.modalReject.form.reject_reason;
        this.table.data[index].reject_reason_id =
          this.modalReject.form.reject_reason_id;
        this.table.data[index].is_rejected = this.modalReject.form.is_rejected;
        this.calculated = false;
        this.setNullFormModalReject();
        this.setZeroLineItem(index);
      }
    },
    setSelectMasterReasonReject(data) {
      this.modalReject.form.reason = data;
      this.modalReject.form.reject_reason = data.reason;
      this.modalReject.form.reject_reason_id = data.ID;
      this.modalReject.form.is_rejected = true;
    },
    openModalReject(index) {
      this.modalReject.activeModal = true;
      this.modalReject.activeIndex = index;
    },
    getTempPromoFG() {
      const data = {
        discount_type: 0,
        discount_string: "",
        promotion_type: "",
        promotion_code: "",
        promotion_id: 0,
        promotion_item_req_id: 0,
        promotion_sequence: 0,
        promotion_step_id: 0,
        line: 0,
        qty: 0,
        to: 0,
        from: 0,
        base: 0,
        base_amount: 0,
        base_string: "",
        fg_component: {
          disabled: false,
          validate: "required",
          name: "fg_sku",
          placeholder: "Sku Code",
          head: "Sku Code",
          type: "multiselect",
          track_by: "item_unit_id",
          isLoading: false,
          multiple: false,
          allowempty: false,
          value: {
            sku_code: "",
            item_id: 0,
            item_name: "",
            item_unit: "",
            item_unit_id: 0,
            text: "",
            qty: 0,
            price: "0",
            discount: 0,
            cap: 0,
            capped: false,
            cogs: 0,
          },
          optionValue: [],
        },
      };
      return data;
    },
    tableDefaultState() {
      return {
        data: [
          {
            id: 1,
            formInputs: [
              {
                id: 1,
                parentId: 1,
                disabled: true,
                validate: "required",
                name: "sku_code",
                placeholder: "Sku Code",
                head: "Sku Code",
                type: "multiselect",
                isLoading: false,
                multiple: false,
                allowempty: false,
                value: {},
                optionValue: [],
              },
            ],
            items_hu: "0",
            items_qty: "0",
            qty_hu: [],
            hu_labels: [],
            list_price: [],
            reg_disc: [],
            promotion: [],
            tax: [],
            tax_rate: null,
            subtotal: {
              price: [],
              disc: 0,
              tax: [],
              tax_rate: [],
              all_price: 0,
              all_tax: 0,
            },
            colWidth: 0,
          },
        ],
        free_goods: [
          {
            discount_type: 0,
            discount_string: "",
            promotion_type: "",
            promotion_code: "",
            promotion_id: 0,
            promotion_item_req_id: 0,
            promotion_sequence: 0,
            promotion_step_id: 0,
            line: 0,
            qty: 0,
            to: 0,
            from: 0,
            base: 0,
            base_amount: 0,
            base_string: "",
            fg_component: {
              disabled: false,
              validate: "required",
              name: "fg_sku",
              placeholder: "Sku Code",
              head: "Sku Code",
              type: "multiselect",
              track_by: "item_unit_id",
              isLoading: false,
              multiple: false,
              allowempty: false,
              value: {
                sku_code: "",
                item_id: 0,
                item_name: "",
                item_unit: "",
                item_unit_id: 0,
                text: "",
                qty: 0,
                price: "0",
                discount: 0,
                cap: 0,
                capped: false,
                cogs: 0,
              },
              optionValue: [],
            },
          },
        ],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    getData() {
      return new Promise((resolve, reject) => {
        // this.$http.get("/api/v1/sales-order", {
        this.$http
          .get("/api/v1/sales-order-mix2", {
            params: {
              sales_order_id: this.selectedSO.DefaultAttribute.ID,
              is_cancel: this.IsCancel,
            },
          })
          .then((resp) => {
            console.log("resp", resp);
            if (resp.code == 200) {
              console.log(
                "resp.data.SalesOrders[0].PoReferenceDate",
                resp.data.SalesOrders[0].PoReferenceDate
              );
              this.salesOrder = resp.data.SalesOrders[0];
              resolve(true);
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              reject(false);
            }
          });
      });
    },
    setDataSO() {
      // show Shipto sesuai customers, keperluan edit Shipto on SO
      // console.log("this.selectedSO", this.selectedSO)
      // console.log("this.salesOrder", this.salesOrder);
      console.log("this.selectedSO.Territory", this.selectedSO.Territory);

      let params = {
        // territory_id: this.selectedTerritory != null ? (this.selectedTerritory.id != undefined ? this.selectedTerritory.id : 0) : 0,
        territory_id:
          this.selectedSO.Territory != null
            ? this.selectedSO.Territory.DefaultAttribute.ID != undefined
              ? this.selectedSO.Territory.DefaultAttribute.ID
              : 0
            : 0,
        customer_id: this.salesOrder.CustomerID,
        search: "",
        length: 40,
        so_type:
          this.salesOrder.OnetimeCustomerID != 0
            ? "ZDOT"
            : this.salesOrder.Category,
      };
      this.getAllAddress(params);

      this.allowPartial = this.salesOrder.AllowPartial == 1 ? true : false;
      this.allowPartialNum = this.salesOrder.AllowPartial;
      this.isFlushOut = this.salesOrder.IsFlushOut;
      this.flushOut = this.salesOrder.IsFlushOut ? 1 : 0;
      this.isPbf = this.salesOrder.IsPbf;
      this.pbf = this.salesOrder.IsPbf ? 1 : 0;
      if (this.salesOrder.Category != "") {
        this.soType = this.optionSoType.filter((v) => {
          return v.Code == this.salesOrder.Category;
        });
        this.soType = this.soType[0];
      } else {
        this.soType = { name: "COD", desc: "Cash Order", code: "ZDCO" }; // default saat tidak ada yang match, hardcode ben cantik
      }
      // filter payment term
      this.paymentTerm.selected = this.paymentTerm.all_options.filter((v) => {
        return v.id == this.salesOrder.PaymentTermID;
      });
      this.paymentTerm.selected = this.paymentTerm.selected[0];

      console.log("this.salesPersonal.option", this.salesPersonal.option);
      console.log("this.salesPersonal.selected", this.salesPersonal.selected);

      this.salesPersonal.selected = this.salesPersonal.option.filter((v) => {
        return v.ID == this.salesOrder.SalesPersonalID;
      });
      this.salesPersonal.selected = this.salesPersonal.selected[0];
      console.log("this.salesPersonal.option", this.salesPersonal.option);
      console.log("this.salesPersonal.selected", this.salesPersonal.selected);

      this.isOneTime =
        this.salesOrder.IsOneTime || this.salesOrder.OnetimeCustomerID != 0;
      if (this.isOneTime) {
        this.oneTimeCust = {
          id: this.salesOrder.OcData.ID,
          customer: this.salesOrder.Customer,
          name: this.salesOrder.OcData.name,
          address_manual: this.salesOrder.OcData.shipto_address,
          address: {
            address: this.salesOrder.OcData.shipto_address,
            postalCode: this.salesOrder.OcData.postal_code,
            countryName: this.salesOrder.OcData.country,
            countryID: 0,
            provinceName: this.salesOrder.OcData.province,
            provinceID: 0,
            cityName: this.salesOrder.OcData.city,
            cityID: 0,
            transportZone: "",
            districtName: this.salesOrder.OcData.district,
            districtID: 0,
            subdistrictName: this.salesOrder.OcData.sub_district,
            subdistrictID: 0,
            showWarningAddress: false,
          },
          contact_person: this.salesOrder.OcData.contact_person,
          nik: this.salesOrder.OcData.nik,
          phone: this.salesOrder.OcData.phone,
          payment_method_id: this.salesOrder.OcData.payment_method_id,
          tax_id: this.salesOrder.OcData.tax_id,
        };
      }
      // Set SO Lines
      this.setSalesOrderLines();
    },
    setSalesOrderLines() {
      console.log("this.selectedSO.Items");
      console.log(this.selectedSO.Items);
      console.log("this.salesOrder.Items");
      console.log(this.salesOrder.Items);

      this.initSelectedFg = [];
      console.log("this.initSelectedFg 2368");
      console.log(this.initSelectedFg);
      // Persiapan data lines, extract resp.data.records_line
      let manual_calc_resp = {
        total_netto: 0,
        total_rounding: 0,
        total_tax: 0,
        total_billing: 0,
        total_discount: 0,
      };
      let data = [];
      let fgHolder = [];
      this.table.free_goods = [];

      let combo_code_added = [];

      this.salesOrder.Items.forEach((skus, i) => {
        // console.log("skus", skus)
        let tempData = null,
          detail_hu = {},
          iqty = 0,
          qty_hu = [],
          items_qty_hu = [];
        let list_price = [],
          reg_disc = [],
          tax = [],
          tax_rate = [],
          sub_total = [],
          grand_total = [];

        let this_hu = [];
        let current_combo_code =
          skus.combo_data != null ? skus.combo_data.combo_code : null;
        console.log("is_combo ? ", skus.combo_data != null);
        console.log("combo_code_added", combo_code_added);
        console.log("current_combo_code", current_combo_code);

        if (skus.combo_data == null && !skus.free_item) {
          // Ini persiapan item non combo
          let this_hu = skus.item_units;
          this_hu.forEach((thu, i) => {
            thu.sku_code = skus.lines[0].sku_code;
          });
          skus.lines.forEach((dt) => {
            detail_hu[dt.unit] = dt.unit;
          });
          console.log("detail_hu", detail_hu);
          let unit_added = [];
          let is_head = false,
            ex_head = false;
          for (let m = 0; m < this_hu.length; m++) {
            let detail = detail_hu[this_hu[m].unit];
            skus.lines.forEach((dtitems, j) => {
              if (this_hu[m].unit == dtitems.unit && !unit_added.includes()) {
                unit_added.push(this_hu[m].unit);
                iqty += dtitems.qty_general;
                qty_hu.push(dtitems.qty);
                if (!ex_head && dtitems.qty > 0) {
                  is_head = true;
                  ex_head = true;
                }
                items_qty_hu.push(dtitems.qty);
                list_price.push({
                  is_head: is_head,
                  item_name: dtitems.item_name,
                  sku_code: dtitems.sku_code,
                  unit: dtitems.unit,
                  price: dtitems.price.toFixed(2),
                  rounding: dtitems.rounding,
                  gross_up: dtitems.gross_up,
                  cogs: dtitems.cogs.toFixed(2),
                  height: dtitems.height,
                  width: dtitems.width,
                  length: dtitems.length,
                  volume: dtitems.volume,
                  weight: dtitems.weight,
                  price_rule_code: dtitems.price_rule_code
                    ? dtitems.price_rule_code
                    : "-",
                  price_rule_id: dtitems.price_rule_id
                    ? dtitems.price_rule_id
                    : 0,
                  price_rule_valid_to: dtitems.price_rule_valid_to
                    ? moment(dtitems.price_rule_valid_to).format("YYYY-MM-DD")
                    : " - ",
                  price_rule_valid_from: dtitems.price_rule_valid_from
                    ? moment(dtitems.price_rule_valid_from).format("YYYY-MM-DD")
                    : " - ",
                  division_code: dtitems.division_code
                    ? dtitems.division_code
                    : " - ",
                  division_description: dtitems.division_description
                    ? dtitems.division_description
                    : " - ",
                  net_weight: dtitems.net_weight ? dtitems.net_weight : 0,
                  total_height: dtitems.total_height
                    ? dtitems.total_height
                    : dtitems.qty * dtitems.height,
                  total_weight: dtitems.total_weight
                    ? dtitems.total_weight
                    : dtitems.qty * dtitems.weight,
                  total_width: dtitems.total_width
                    ? dtitems.total_width
                    : dtitems.qty * dtitems.width,
                  total_volume: dtitems.total_volume
                    ? dtitems.total_volume
                    : dtitems.qty * dtitems.volume,
                  total_length: dtitems.total_length
                    ? dtitems.total_length
                    : dtitems.qty * dtitems.length,
                  total_net_weight: dtitems.total_net_weight
                    ? dtitems.total_net_weight
                    : dtitems.net_weight
                    ? dtitems.qty * dtitems.net_weight
                    : 0,
                  is_expand: false,
                  is_rejected: dtitems.is_rejected,
                  reject_reason_id: dtitems.rejected_reason_id,
                  reason: dtitems.reason_reject,
                  rejected_reason: "",
                  discount: dtitems.discount.toFixed(2),
                  promo_calculation: dtitems.so_line_promo,
                  // rejected_reason_id: 0,
                });
                is_head = false;
                reg_disc.push(dtitems.discount.toFixed(2));
                tax.push(dtitems.tax.toFixed(2));
                tax_rate.push(dtitems.tax_rate);
                let gt = 0;
                if (dtitems.gross_up != undefined && dtitems.gross_up != 0) {
                  gt =
                    dtitems.subtotal +
                    dtitems.tax +
                    dtitems.gross_up -
                    dtitems.discount;
                } else {
                  gt = dtitems.subtotal + dtitems.tax - dtitems.discount;
                }
                sub_total.push(dtitems.subtotal.toFixed(2));
                grand_total.push(gt.toFixed(2));

                // persiapan Total Semua
                // jika direject, tidak ikut dihitung
                console.log("dtitems", dtitems);
                if (!dtitems.is_rejected) {
                  manual_calc_resp.total_netto += dtitems.subtotal;
                  manual_calc_resp.total_rounding += dtitems.rounding;
                  manual_calc_resp.total_tax += dtitems.tax;
                  manual_calc_resp.total_discount += dtitems.discount;
                  if (dtitems.gross_up != undefined && dtitems.gross_up != 0) {
                    manual_calc_resp.total_billing +=
                      dtitems.subtotal -
                      dtitems.discount +
                      dtitems.tax +
                      dtitems.gross_up +
                      dtitems.rounding;
                  } else {
                    manual_calc_resp.total_billing +=
                      dtitems.subtotal -
                      dtitems.discount +
                      dtitems.tax +
                      dtitems.rounding;
                  }
                  // if (dtitems.rounding != undefined && dtitems.rounding !=0) {
                  //   manual_calc_resp.total_billing +=parseFloat(dtitems.rounding)
                  // }
                }
              } else if (
                detail == undefined &&
                !unit_added.includes(this_hu[m].unit)
              ) {
                unit_added.push(this_hu[m].unit);
                qty_hu.push(0);
                items_qty_hu.push(0);
                list_price.push({
                  item_name: dtitems.item_name,
                  sku_code: dtitems.sku_code,
                  unit: this_hu[m].unit,
                  price: (0).toFixed(2),
                  rounding: 0,
                  gross_up: (0).toFixed(2),
                  discount: (0).toFixed(2),
                  promo_calculation: [],
                });
                reg_disc.push((0).toFixed(2));
                tax.push((0).toFixed(2));
                sub_total.push((0).toFixed(2));
                grand_total.push((0).toFixed(2));
              }

              tempData = {
                allow_partial: dtitems.allow_partial,
                rowSpanPlus: 1,
                items_id: dtitems.item_id,
                data_combo: [],
                id: i + j,
                freeItems: false,
                formInputs: [
                  {
                    id: 1,
                    parentId: i + 1,
                    disabled: true, //tidak diperbolehkan mengubah existing SKU
                    validate: "required",
                    name: "sku_code",
                    placeholder: "Sku Code",
                    head: "Sku Code",
                    type: "multiselect",
                    multiple: false,
                    allowempty: false,
                    value: {
                      item_unit_id: dtitems.item_unit_id,
                      code: dtitems.sku_code,
                      text: "[" + dtitems.sku_code + "]" + dtitems.item_name,
                      sku_name: dtitems.item_name,
                      is_combo: false,
                      item_type: dtitems.item_type,
                      item_type_name: dtitems.item_type_name,
                      item_id: dtitems.item_id,
                    },
                    optionValue: [],
                  },
                ],
                items_hu: "0",
                hu_labels: this_hu,
                promotion: [],
                tax_rate: dtitems.tax_rate,
                subtotal: {
                  disc: dtitems.discount,
                  all_price: 0,
                  all_tax: 0,
                  tax_rate: [],
                },
                is_rejected: dtitems.is_rejected,
                reject_reason_id: dtitems.rejected_reason_id,
                reason: dtitems.reason_reject,
              };

              // // end pecah price
            });
          }
          tempData.items_qty = iqty;
          tempData.qty_hu = qty_hu;
          tempData.list_price = list_price;
          tempData.reg_disc = reg_disc;
          tempData.tax = tax;
          tempData.tax_rate = tax_rate;
          tempData.grand_total = grand_total;

          tempData.subtotal.price = sub_total;
          tempData.subtotal.tax = tax;
          tempData.subtotal.tax_rate = tax_rate;
          tempData.subtotal.all_price = tempData.subtotal.all_price.toFixed(2);
          tempData.subtotal.all_tax = tempData.subtotal.all_tax.toFixed(2);
          tempData.items_hu = tempData.qty_hu.join(".");
          tempData.items_qty_hu = items_qty_hu;
          tempData.colWidth =
            skus.combo_data == null ? 12 / tempData.qty_hu.length : 12;
        } else if (skus.combo_data != null && !skus.free_item) {
          // Ini persiapan item combo
          this.isExistSkuCombo = true;
          // this_hu.push(skus.combo_data.combo_hu)

          // Kalau combo code blm ditambah, maka set Parent combo baru, dan tambahkan first item combo nya
          if (!combo_code_added.includes(current_combo_code)) {
            this_hu = {
              allow_sfa: 0,
              amount_uom: skus.combo_data.combo_hu[0].amount_uom,
              batch: "",
              combo_items: [],
              expired_date: null,
              height: skus.combo_data.combo_hu[0].height,
              is_combo: true,
              item_unit_id: skus.combo_data.combo_hu[0].item_unit_id,
              length: skus.combo_data.combo_hu[0].length,
              net_weight: skus.combo_data.combo_hu[0].net_weight,
              sfa_default: skus.combo_data.combo_hu[0].sfa_default,
              sku_code: skus.combo_data.combo_code,
              stock: 0,
              unit: skus.combo_data.combo_hu[0].unit,
              volume: skus.combo_data.combo_hu[0].volume,
              weight: skus.combo_data.combo_hu[0].weight,
              width: skus.combo_data.combo_hu[0].width,
            };

            tempData = {
              rowSpanPlus: 1,
              data_combo: [],
              id: i,
              formInputs: [
                {
                  id: 1,
                  parentId: i + 1,
                  disabled: true, //tidak diperbolehkan mengubah existing SKU
                  validate: "required",
                  name: "sku_code",
                  placeholder: "Sku Code",
                  head: "Sku Code",
                  type: "multiselect",
                  multiple: false,
                  allowempty: false,
                  value: {
                    item_unit_id: skus.combo_data.combo_hu[0].item_unit_id,
                    code: skus.combo_data.combo_code,
                    text:
                      "[" +
                      skus.combo_data.combo_code +
                      "]" +
                      skus.combo_data.combo_name,
                    sku_name: skus.combo_data.combo_name,
                    is_combo: true,
                  },
                  optionValue: [],
                },
              ],
              items_hu: skus.combo_data.quantity_combo,
              items_qty: skus.combo_data.quantity_combo,
              qty_hu: [],
              hu_labels: [],
              promotion: [],
              subtotal: {
                all_price: 0,
                all_tax: 0,
              },
              is_rejected: skus.lines[0].is_rejected,
              reject_reason_id: skus.lines[0].rejected_reason_id,
              reason: skus.lines[0].reason_reject,
            };
            items_qty_hu.push(parseInt(skus.combo_data.quantity_combo));
            let is_head2 = false,
              ex_head2 = false;
            skus.lines.forEach((dtitems) => {
              if (!ex_head2 && dtitems > 0) {
                ex_head2 = true;
                is_head2 = true;
              }
              let combo = {
                CompanyID: this.salesOrder.CompanyID,
                Code: skus.combo_data.combo_code,
                Name: skus.combo_data.combo_name,
                ValidFrom: skus.combo_data.valid_from,
                ValidTo: skus.combo_data.valid_to,
                ItemUnitID: dtitems.item_unit_id,
                Quantity: dtitems.qty / dtitems.quantity_combo,
                Type: "",
                ItemName: dtitems.item_name,
                ItemCode: dtitems.sku_code,
                UnitName: dtitems.unit,
                ComboID: dtitems.combo_id,
                ComboName: dtitems.combo_name,
                ComboCode: dtitems.combo_code,
                total_qty: dtitems.qty,
                total_qty_combo: dtitems.qty,
                rowSpanPlusCombo: 1,
                amount_uom: dtitems.amount_uom,
                is_expand: false,
                ItemID: dtitems.item_id,
              };
              this_hu.combo_items.push(combo);
              tempData.data_combo.push(combo);
              tempData.qty_hu.push(dtitems.qty);

              list_price.push({
                is_head: is_head2,
                sku_code: dtitems.sku_code,
                price: dtitems.price.toFixed(2),
                item_name: dtitems.item_name,
                unit: dtitems.unit,
                rounding: dtitems.rounding,
                gross_up: dtitems.gross_up,
                cogs: dtitems.cogs.toFixed(2),
                height: dtitems.height,
                width: dtitems.width,
                length: dtitems.length,
                volume: dtitems.volume,
                weight: dtitems.weight,
                price_rule_code: dtitems.price_rule_code
                  ? dtitems.price_rule_code
                  : "-",
                price_rule_id: dtitems.price_rule_id
                  ? dtitems.price_rule_id
                  : 0,
                price_rule_valid_to: dtitems.price_rule_valid_to
                  ? moment(dtitems.price_rule_valid_to).format("YYYY-MM-DD")
                  : " - ",
                price_rule_valid_from: dtitems.price_rule_valid_from
                  ? moment(dtitems.price_rule_valid_from).format("YYYY-MM-DD")
                  : " - ",
                division_code: dtitems.division_code
                  ? dtitems.division_code
                  : " - ",
                division_description: dtitems.division_description
                  ? dtitems.division_description
                  : " - ",
                net_weight: dtitems.net_weight ? dtitems.net_weight : 0,
                total_height: dtitems.total_height
                  ? dtitems.total_height
                  : dtitems.qty * dtitems.height,
                total_weight: dtitems.total_weight
                  ? dtitems.total_weight
                  : dtitems.qty * dtitems.weight,
                total_width: dtitems.total_width
                  ? dtitems.total_width
                  : dtitems.qty * dtitems.width,
                total_volume: dtitems.total_volume
                  ? dtitems.total_volume
                  : dtitems.qty * dtitems.volume,
                total_length: dtitems.total_length
                  ? dtitems.total_length
                  : dtitems.qty * dtitems.length,
                total_net_weight: dtitems.total_net_weight
                  ? dtitems.total_net_weight
                  : dtitems.net_weight
                  ? dtitems.qty * dtitems.net_weight
                  : 0,
                is_expand: false,
                promo_calculation: dtitems.so_line_promo,
                discount: dtitems.discount.toFixed(2),
              });
              is_head2 = false;
              reg_disc.push(dtitems.discount.toFixed(2));
              tax.push(dtitems.tax.toFixed(2));
              let gt =
                dtitems.subtotal +
                dtitems.tax -
                dtitems.discount +
                dtitems.gross_up;
              sub_total.push(dtitems.subtotal.toFixed(2));
              grand_total.push(gt.toFixed(2));

              // MANUAL CALC
              // persiapan Total Semua
              // jika direject, tidak ikut dihitung
              console.log("dtitems", dtitems);
              if (!dtitems.is_rejected) {
                manual_calc_resp.total_netto += dtitems.subtotal;
                manual_calc_resp.total_rounding += dtitems.rounding;
                manual_calc_resp.total_discount += dtitems.discount;
                manual_calc_resp.total_tax += dtitems.tax;
                // manual_calc_resp.total_billing += (dtitems.subtotal - dtitems.discount + dtitems.tax + dtitems.gross_up);
                if (dtitems.gross_up != undefined && dtitems.gross_up != 0) {
                  manual_calc_resp.total_billing +=
                    dtitems.subtotal -
                    dtitems.discount +
                    dtitems.tax +
                    dtitems.gross_up +
                    dtitems.rounding;
                } else {
                  manual_calc_resp.total_billing +=
                    dtitems.subtotal -
                    dtitems.discount +
                    dtitems.tax +
                    dtitems.rounding;
                }
                // MANUAL CALC
              }
            });

            tempData.hu_labels.push(this_hu);

            tempData.items_qty = skus.combo_data.quantity_combo;
            tempData.list_price = list_price;
            tempData.reg_disc = reg_disc;
            tempData.tax = tax;
            tempData.grand_total = grand_total;

            tempData.subtotal.disc = reg_disc;
            tempData.subtotal.price = sub_total;
            tempData.subtotal.tax = tax;
            tempData.subtotal.tax_rate = tax_rate;
            tempData.subtotal.all_price =
              tempData.subtotal.all_price.toFixed(2);
            tempData.subtotal.all_tax = tempData.subtotal.all_tax.toFixed(2);
            tempData.items_hu = skus.combo_data.quantity_combo;
            tempData.items_qty_hu = items_qty_hu;
            tempData.colWidth =
              skus.combo_data == null ? 12 / tempData.qty_hu.length : 12;
            tempData.item_id = skus.item_id;
          } else {
            // Kalau combo code sdh ditambah, cuma tambahkan item combo nya aja
            console.log("cuma tambahkan item combo nya aja");
            // Cari dulu parent nya
            console.log("data tempNya", data);
            let i_parent = 0;
            data.forEach((dt, iC) => {
              console.log("cek tiap data", dt);
              console.log("isTemp Combo ? ", dt.data_combo.length != 0);
              if (
                dt.data_combo.length != 0 &&
                dt.data_combo[0].ComboCode == skus.combo_data.combo_code
              ) {
                i_parent = iC;
                console.log("ketemu parent nya, i ke ", iC);
                console.log("i_parent ", i_parent);
              }
            });
            console.log("data parent nya | data[i_parent] ", data[i_parent]);
            // Tambah combo
            skus.lines.forEach((dtitems) => {
              console.log("Yang akan ditambahkan ", dtitems);
              let combo = {
                CompanyID: this.salesOrder.CompanyID,
                Code: skus.combo_data.combo_code,
                Name: skus.combo_data.combo_name,
                ValidFrom: skus.combo_data.valid_from,
                ValidTo: skus.combo_data.valid_to,
                ItemUnitID: dtitems.item_unit_id,
                Quantity: dtitems.qty / dtitems.quantity_combo,
                Type: "",
                ItemName: dtitems.item_name,
                ItemCode: dtitems.sku_code,
                UnitName: dtitems.unit,
                ComboID: dtitems.combo_id,
                ComboName: dtitems.combo_name,
                ComboCode: dtitems.combo_code,
                total_qty: dtitems.qty,
                total_qty_combo: dtitems.qty,
                rowSpanPlusCombo: 1,
                amount_uom: dtitems.amount_uom,
                is_expand: false,
                ItemID: dtitems.item_id,
              };

              // Mulai
              // tempData.hu_labels
              // data[i_parent].hu_labels.combo_items.push(combo);
              data[i_parent].data_combo.push(combo);
              data[i_parent].qty_hu.push(dtitems.qty);

              data[i_parent].list_price.push({
                is_head: false,
                sku_code: dtitems.sku_code,
                price: dtitems.price.toFixed(2),
                item_name: dtitems.item_name,
                unit: dtitems.unit,
                rounding: dtitems.rounding,
                gross_up: dtitems.gross_up,
                cogs: dtitems.cogs.toFixed(2),
                height: dtitems.height,
                width: dtitems.width,
                length: dtitems.length,
                volume: dtitems.volume,
                weight: dtitems.weight,
                price_rule_code: dtitems.price_rule_code
                  ? dtitems.price_rule_code
                  : "-",
                price_rule_id: dtitems.price_rule_id
                  ? dtitems.price_rule_id
                  : 0,
                price_rule_valid_to: dtitems.price_rule_valid_to
                  ? moment(dtitems.price_rule_valid_to).format("YYYY-MM-DD")
                  : " - ",
                price_rule_valid_from: dtitems.price_rule_valid_from
                  ? moment(dtitems.price_rule_valid_from).format("YYYY-MM-DD")
                  : " - ",
                division_code: dtitems.division_code
                  ? dtitems.division_code
                  : " - ",
                division_description: dtitems.division_description
                  ? dtitems.division_description
                  : " - ",
                net_weight: dtitems.net_weight ? dtitems.net_weight : 0,
                total_height: dtitems.total_height
                  ? dtitems.total_height
                  : dtitems.qty * dtitems.height,
                total_weight: dtitems.total_weight
                  ? dtitems.total_weight
                  : dtitems.qty * dtitems.weight,
                total_width: dtitems.total_width
                  ? dtitems.total_width
                  : dtitems.qty * dtitems.width,
                total_volume: dtitems.total_volume
                  ? dtitems.total_volume
                  : dtitems.qty * dtitems.volume,
                total_length: dtitems.total_length
                  ? dtitems.total_length
                  : dtitems.qty * dtitems.length,
                total_net_weight: dtitems.total_net_weight
                  ? dtitems.total_net_weight
                  : dtitems.net_weight
                  ? dtitems.qty * dtitems.net_weight
                  : 0,
                is_expand: false,
                promo_calculation: dtitems.so_line_promo,
                discount: dtitems.discount.toFixed(2),
              });

              let gt =
                dtitems.subtotal +
                dtitems.tax -
                dtitems.discount +
                dtitems.gross_up;
              data[i_parent].subtotal.disc.push(dtitems.discount.toFixed(2));
              data[i_parent].subtotal.tax.push(dtitems.tax.toFixed(2));
              data[i_parent].subtotal.price.push(dtitems.subtotal.toFixed(2));

              data[i_parent].reg_disc.push(dtitems.discount.toFixed(2));
              data[i_parent].tax.push(dtitems.tax.toFixed(2));
              data[i_parent].grand_total.push(gt.toFixed(2));

              // tempData.subtotal.disc      = reg_disc;
              // tempData.subtotal.price     = sub_total;
              // tempData.subtotal.tax       = tax;
              // tempData.subtotal.tax_rate       = tax_rate;
              // tempData.subtotal.all_price = tempData.subtotal.all_price;
              // tempData.subtotal.all_tax   = tempData.subtotal.all_tax;
              // tempData.items_hu           = skus.combo_data.quantity_combo;
              // tempData.items_qty_hu       = items_qty_hu;
              // tempData.colWidth           = skus.combo_data == null ? (12/tempData.qty_hu.length) : 12;
              // tempData.item_id            = skus.item_id;

              // MANUAL CALC
              // persiapan Total Semua
              // jika direject, tidak ikut dihitung
              console.log("dtitems", dtitems);
              if (!dtitems.is_rejected) {
                manual_calc_resp.total_netto += dtitems.subtotal;
                manual_calc_resp.total_rounding += dtitems.rounding;
                manual_calc_resp.total_discount += dtitems.discount;
                manual_calc_resp.total_tax += dtitems.tax;
                // manual_calc_resp.total_billing += (dtitems.subtotal - dtitems.discount + dtitems.tax + dtitems.gross_up);
                if (dtitems.gross_up != undefined && dtitems.gross_up != 0) {
                  manual_calc_resp.total_billing +=
                    dtitems.subtotal -
                    dtitems.discount +
                    dtitems.tax +
                    dtitems.gross_up +
                    dtitems.rounding;
                } else {
                  manual_calc_resp.total_billing +=
                    dtitems.subtotal -
                    dtitems.discount +
                    dtitems.tax +
                    dtitems.rounding;
                }
                // MANUAL CALC
              }
              // Batas ngetes new logic
            });
          }
        } else {
          // ini handle untuk free items
          console.log("skus.free_item", skus.free_item);
          console.log("skus", skus);
          // Part setting freegoods
          const disc_type_choice = 9;
          skus.lines.forEach((skul) => {
            var temp_data_fg = this.getTempPromoFG();
            var temp_fg_select = {
              sku_code: "",
              item_id: 0,
              item_name: "",
              item_unit: "",
              item_unit_id: 0,
              text: "",
              qty: 0,
              price: "0",
              discount: 0,
              cap: 0,
              capped: false,
              cogs: 0,
            };
            console.log("temp_fg_select==>>", temp_fg_select);
            let promoData = skul.so_line_promo[0];
            if (promoData) {
              // jika so line promo kosong di skip aja biar g error (data prod masih ada yang kosong)
              var discount_string = this.getDiscTypeString(
                promoData.discount_type
              );
              var base_string = this.getBaseString(promoData.base);
              console.log("discount_string", discount_string);
              console.log("base_string", base_string);

              if (parseInt(promoData.discount_type) == disc_type_choice) {
                console.log("=======2289");
                let fg_filter = [];
                fg_filter = this.table.free_goods.filter((el) => {
                  return (
                    promoData.promotion_item_req_id == el.promotion_item_req_id
                  );
                });

                if (fg_filter.length > 0) {
                  console.log("2297");
                  temp_data_fg = fg_filter[0];
                  temp_fg_select.sku_code = skul.sku_code;
                  temp_fg_select.item_id = skul.item_id;
                  temp_fg_select.item_name = skul.item_name;
                  temp_fg_select.item_unit = skul.unit;
                  temp_fg_select.item_unit_id = skul.item_unit_id;
                  temp_fg_select.text =
                    "[" + skul.sku_code + "] " + skul.item_name;
                  temp_fg_select.qty = skul.qty;
                  temp_fg_select.price = skul.price;
                  temp_fg_select.discount = skul.qty * skul.price;
                  temp_fg_select.cap = promoData.cap;
                  temp_fg_select.promotion_step_id =
                    promoData.promotion_step_id;
                  temp_fg_select.capped = promoData.capped;
                  temp_fg_select.cogs = skul.cogs.toFixed(2);
                  temp_data_fg.fg_component.optionValue.push(temp_fg_select);
                  temp_data_fg.allow_partial = skul.allow_partial;
                } else {
                  console.log("=======2314");

                  temp_fg_select.sku_code = skul.sku_code;
                  temp_fg_select.item_id = skul.item_id;
                  temp_fg_select.item_name = skul.item_name;
                  temp_fg_select.item_unit = skul.unit;
                  temp_fg_select.item_unit_id = skul.item_unit_id;
                  temp_fg_select.text =
                    "[" + skul.sku_code + "] " + skul.item_name;
                  temp_fg_select.qty = skul.qty;
                  temp_fg_select.price = skul.price;
                  temp_fg_select.discount = skul.qty * skul.price;
                  temp_fg_select.cap = promoData.cap;
                  temp_fg_select.capped = promoData.capped;
                  temp_fg_select.cogs = skul.cogs.toFixed(2);

                  temp_data_fg.discount_type = promoData.discount_type;
                  temp_data_fg.discount_string = discount_string;
                  temp_data_fg.promotion_type = promoData.promotion_type;
                  temp_data_fg.promotion_code = promoData.promotion_code;
                  temp_data_fg.promotion_id = promoData.promotion_id;
                  temp_data_fg.promotion_item_req_id =
                    promoData.promotion_item_req_id;
                  temp_data_fg.promotion_sequence =
                    promoData.promotion_sequence;
                  temp_data_fg.promotion_step_id = promoData.promotion_step_id;
                  temp_data_fg.line = i;
                  temp_data_fg.i_parent = i;
                  temp_data_fg.qty = promoData.qty;
                  temp_data_fg.to = promoData.to;
                  temp_data_fg.from = promoData.from;
                  temp_data_fg.base = promoData.base;
                  temp_data_fg.base_amount = promoData.base_amount;
                  temp_data_fg.base_string = base_string;
                  temp_data_fg.fg_component.optionValue.push(temp_fg_select);
                  // temp_data_fg.fg_component.value = this.temp_free_good.fg_component.value;
                  temp_data_fg.fg_component.value = temp_fg_select;
                  temp_data_fg.fg_component.disabled = false;
                  temp_data_fg.allow_partial = skul.allow_partial;
                  temp_data_fg.fg_component.i_parent = i;
                }
              } else {
                console.log("=======2351");
                console.log("=======2351 temp_fg_select", temp_fg_select);
                temp_fg_select.sku_code = skul.sku_code;
                temp_fg_select.item_id = skul.item_id;
                temp_fg_select.item_name = skul.item_name;
                temp_fg_select.item_unit = skul.unit;
                temp_fg_select.item_unit_id = skul.item_unit_id;
                temp_fg_select.text =
                  "[" + skul.sku_code + "] " + skul.item_name;
                temp_fg_select.qty = skul.qty;
                temp_fg_select.price = skul.price;
                temp_fg_select.discount = skul.qty * skul.price;
                temp_fg_select.cap = promoData.cap;
                temp_fg_select.capped = promoData.capped;
                temp_fg_select.promotion_step_id = promoData.promotion_step_id;
                temp_fg_select.cogs = skul.cogs.toFixed(2);

                temp_data_fg.discount_type = promoData.discount_type;
                temp_data_fg.discount_string = discount_string;
                temp_data_fg.promotion_type = promoData.promotion_type;
                temp_data_fg.promotion_code = promoData.promotion_code;
                temp_data_fg.promotion_id = promoData.promotion_id;
                temp_data_fg.promotion_item_req_id =
                  promoData.promotion_item_req_id;
                temp_data_fg.promotion_sequence = promoData.promotion_sequence;
                temp_data_fg.promotion_step_id = promoData.promotion_step_id;
                temp_data_fg.line = i;
                temp_data_fg.i_parent = i;
                temp_data_fg.qty = promoData.qty;
                temp_data_fg.to = promoData.to;
                temp_data_fg.from = promoData.from;
                temp_data_fg.base = promoData.base;
                temp_data_fg.base_amount = promoData.base_amount;
                temp_data_fg.base_string = base_string;
                temp_data_fg.fg_component.optionValue.push(temp_fg_select);
                temp_data_fg.fg_component.value = {
                  sku_code: skul.sku_code,
                  item_id: skul.item_id,
                  item_name: skul.item_name,
                  item_unit: skul.unit,
                  item_unit_id: skul.item_unit_id,
                  text: "[" + skul.sku_code + "] " + skul.item_name,
                  qty: skul.qty,
                  price: skul.price,
                  discount: skul.qty * skul.price,
                  cap: promoData.cap,
                  capped: promoData.capped,
                  cogs: skul.cogs.toFixed(2),
                };
                temp_data_fg.fg_component.disabled = true;
                temp_data_fg.allow_partial = skul.allow_partial;
                temp_data_fg.fg_component.i_parent = i;
              }
              temp_data_fg.fg_component.disabled = true;
              console.log("temp_data_fg.line=>", temp_data_fg.line, ", i=>", i);
              console.log("temp_data_fg", temp_data_fg);
              fgHolder.push(temp_data_fg);

              let selectedFG = {
                promotion_id: temp_data_fg.promotion_id,
                promotion_item_req_id: temp_data_fg.promotion_item_req_id,
                promotion_step_id: temp_data_fg.promotion_step_id,
                item_unit_id: temp_data_fg.fg_component.value.item_unit_id,
              };
              this.initSelectedFg.push(selectedFG);

              manual_calc_resp.total_netto +=
                temp_data_fg.fg_component.value.discount;
              manual_calc_resp.total_discount +=
                temp_data_fg.fg_component.value.discount;
            }
          });
          console.log("this.initSelectedFg 3017");
          console.log(this.initSelectedFg);
          // end setting Line freegood
        }

        console.log("__tempData__", tempData);
        console.log("__tempData__", tempData);
        console.log("fgHolder", fgHolder);
        console.log("this.isExistSkuCombo", this.isExistSkuCombo);

        if (
          skus.combo_data != null &&
          !combo_code_added.includes(current_combo_code)
        ) {
          tempData.isBlink = false;
          tempData.recalculate = false;
          combo_code_added.push(current_combo_code);
          console.log("combo_code_added 2735", combo_code_added);
          data.push(tempData);
        } else {
          console.log("2738");
          if (tempData != null) {
            console.log("2740");
            tempData.isBlink = false;
            tempData.recalculate = false;
            data.push(tempData);
          }
        }
      });
      this.table.data = data;
      this.table.free_goods = fgHolder;
      this.manual_calc_resp = manual_calc_resp;
      console.log("this.table.data", this.table.data);
      console.log("this.table.free_goods", this.table.free_goods);
      if (this.table.free_goods.length > 0) {
        this.setPromoDiscountTypeLowestPrice();
        this.existing_fg = true;
      } else {
        this.existing_fg = false;
      }
      this.setAllowPartialSkus(false);
    },
    getOptionZones() {
      return new Promise((resolve, reject) => {
        this.zone.option = [];
        this.zone.all_options = [];
        // this.$vs.loading();
        this.$http.get("api/v1/master/zone").then((resp) => {
          if (resp.status == "success") {
            this.zone.option = resp.data.records;
            this.zone.all_options = resp.data.records;

            this.zone.selected = this.zone.all_options.filter((v) => {
              return v.territory_code == this.selectedSO.Territory.Code;
            });
            resolve(true);
          } else {
            console.log(resp);
            reject(true);
          }
        });
      });
    },
    getOptionSalesID(is_default) {
      return new Promise((resolve, reject) => {
        let superadmin, territory_id, sales_channel_id, sales_personal_id;
        this.$userLogin.roles.filter(function (item) {
          if (item.Name == "superadmin") {
            superadmin = true;
          } else {
            superadmin = false;
          }
        });
        this.isLoadingSales = true;
        // territory_id = (this.selectedTerritory != null && this.selectedTerritory.id) ? this.selectedTerritory.id : 0;
        territory_id = this.salesOrder.TerritoryID
          ? this.salesOrder.TerritoryID
          : 0;
        // sales_channel_id = (this.selectedSalesChannel != null && this.selectedSalesChannel.ID) ? this.selectedSalesChannel.ID : 0;
        sales_personal_id =
          this.selectedSO.SalesPersonalID && this.disable_form
            ? this.selectedSO.SalesPersonalID
            : 0;

        this.$http
          .get("/api/v1/sales-order/personals", {
            params: {
              search: this.searchSales,
              territory_id: territory_id,
              // sales_channel_id: sales_channel_id,
              sales_personal_id: sales_personal_id,
            },
          })
          .then((resp) => {
            this.isLoadingSales = false;
            if (resp.code == 200) {
              this.salesPersonal.option = [];
              this.salesPersonal.all_options = [];
              let data = resp.data.salesman;
              for (var idx = 0; idx < data.length; idx++) {
                this.salesPersonal.option.push(data[idx]);
                this.salesPersonal.all_options.push(data[idx]);
              }
              if (!superadmin) {
                // set default Sales ID jika bukan superadmin
                this.salesPersonal.selected = this.salesPersonal.option.filter(
                  (v) => {
                    return v.ID == this.$userLogin.id;
                  }
                );
              }

              // ini ku comment karena bikin stoper, ketika sales tidak punya sales channel
              // if(is_default && sales_personal_id != 0){
              //   // set default Sales ID saat dipanggil dari selectedCust
              //   this.salesPersonal.selected = this.salesPersonal.option[0];
              //   // set sales channel
              //   console.log("set sales channel")
              //   this.salesChannel.selected = this.salesChannel.all_options.filter((v) => {
              //     return v.ID == this.salesPersonal.option[0].SalesChannelId;
              //   });
              //   this.salesChannel.selected = this.salesChannel.selected[0];
              // }
              resolve(true);
            } else {
              console.log(resp);
              reject(true);
            }
          });
      });
    },
    getAllDataPricingGroup() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("api/v1/master/pricing-group", {
            params: {
              order: "name",
              sort: "asc",
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.pricingGroup.options = resp.data.records;
              this.pricingGroup.all_options = resp.data.records;

              console.log(
                "this.salesOrder.Customer.PricingGroupID",
                this.salesOrder.Customer.PricingGroupID
              );
              this.pricingGroup.selected = this.pricingGroup.all_options.filter(
                (v) => {
                  return v.ID == this.salesOrder.Customer.PricingGroupID;
                }
              );
              this.pricingGroup.selected = this.pricingGroup.selected[0];

              resolve(true);
            } else {
              console.log(resp);
              reject(false);
            }
          });
      });
    },
    getOptionSalesChannel() {
      return new Promise((resolve, reject) => {
        let url = "/api/oms/v1/sfa-sales-order/sales-channel/list";
        url = "/api/v1/sales-channel/list";
        this.$http.get(url).then((resp) => {
          if (resp.code == 200) {
            this.salesChannel.option = [];
            this.salesChannel.all_options = [];
            for (
              var salesChannel = 0;
              salesChannel < resp.data.length;
              salesChannel++
            ) {
              if (resp.data[salesChannel].name == "Sales Taking Order") {
                this.salesChannel.option.push(resp.data[salesChannel]);
                this.salesChannel.all_options.push(resp.data[salesChannel]);
              }
            }
            this.salesChannel.selected = this.salesChannel.option[0];
            resolve(true);
          } else {
            console.log(resp);
            reject(false);
          }
        });
      });
    },
    updateSelectedPromotionCode(selectedPromotionCode) {
      this.calculated = false;
      this.promotionCode = selectedPromotionCode;
      console.log("updateSelectedPromotionCode", this.promotionCode);
    },
    updateLimitItemByPromotion(limitedItem) {
      this.calculated = false;

      for (let index = 0; index < limitedItem.length; index++) {
        this.limitedItem[index] = limitedItem[index].item_unit_id;
      }
      console.log("updateLimitItemByPromotion", this.limitedItem);

      this.itemsCode = [];
      this.itemsName = [];
      this.itemsUom = [];
      this.itemsQuantityReq = [];
      this.itemsQuantityAdj = [];

      this.itemsPrice = [];
      this.itemsPriceCustom = [];
      this.itemsSubtotal = [];
      this.itemsDiscount = [];
      this.itemsTax = [];
      this.itemsLineTotal = [];

      this.itemsSubtotalEngine = [];
      this.itemsDiscountEngine = [];
      this.itemsTaxEngine = [];
      this.itemsLineTotalEngine = [];

      this.pricingStep = [];
      this.discountStep = [];
      this.totalDiscountStep = [];

      this.totalBrutto = 0;
      this.totalDiscount = 0;
      this.totalNetto = 0;
      this.totalTax = 0;
      this.totalBilling = 0;
    },
    updateSelectedReservationCode(selectedReservationCode) {
      this.calculated = false;
      this.salesOrder.ReservationCode = selectedReservationCode;
      console.log(
        "updateSelectedReservationCode",
        this.salesOrder.ReservationCode
      );
    },
    deleteItemLine(index) {
      this.calculated = false;
      if (this.external == false) {
        this.itemsCode.splice(index, 1);
        this.itemsName.splice(index, 1);
        this.itemsUom.splice(index, 1);
        this.itemsQuantityReq.splice(index, 1);
        this.itemsQuantityAdj.splice(index, 1);

        this.itemsPrice.splice(index, 1);
        this.itemsPriceCustom.splice(index, 1);
        this.itemsSubtotal.splice(index, 1);
        this.itemsDiscount.splice(index, 1);
        this.itemsTax.splice(index, 1);
        this.itemsLineTotal.splice(index, 1);

        this.itemsSubtotalEngine.splice(index, 1);
        this.itemsDiscountEngine.splice(index, 1);
        this.itemsTaxEngine.splice(index, 1);
        this.itemsLineTotalEngine.splice(index, 1);

        this.pricingStep.splice(index, 1);
        this.discountStep.splice(index, 1);
        this.totalDiscountStep.splice(index, 1);
      } else {
        this.$vs.notify({
          title: "Warning",
          text: "You're not allowed to add or remove external Sales Order item line",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    getOptionItemAvailable(page = 1) {
      this.isActive = page;
      this.$vs.loading();

      this.$http
        .get("/api/v1/item-available", {
          params: {
            territory_code: this.selectTerritory.code,
            search: this.search,
            limit: this.limitShow,
            offset: this.limitShow * (page - 1),
            item_unit_id: this.limitedItem,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionItemAvailable = resp.data.ItemsAvailable;
            this.recordsTotal = resp.data.RecordsTotal;
            this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
            this.dataEnd = resp.data.ResultsTotal;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Item Available",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionCustomer(page = 1) {
      this.isActive = page;
      this.$vs.loading();

      this.$http
        .get("/api/v1/master/customer", {
          params: {
            search: this.search,
            length: this.limitShow,
            page: page,
            type: "End Customer",
            territory_id: this.territoryId,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionCustomer = resp.data.records;
            this.recordsTotal = resp.data.total_record;
            this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
            this.dataEnd = resp.data.total_record_search;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Customer",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionBillTo(page = 1, autoDefault = false) {
      this.isActive = page;
      this.$vs.loading();

      this.$http
        .get("/api/v1/master/customer-address/bill-to", {
          params: {
            search: this.search,
            length: this.limitShow,
            page: page,
            customer_id: this.salesOrder.CustomerID,
            territory_id: this.territoryId,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionBillTo = resp.data.records;
            this.recordsTotal = resp.data.total_record;
            this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
            this.dataEnd = resp.data.total_record_search;

            if (autoDefault == true && this.optionBillTo.length > 0) {
              this.salesOrder.BillAddress.Address =
                this.optionBillTo[0].address;
              this.salesOrder.BillAddress.City = this.optionBillTo[0].city;
              this.salesOrder.BillToAddressID = this.optionBillTo[0].ID;
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Bill To Address",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionShipTo(page = 1, autoDefault = false) {
      this.isActive = page;
      this.$vs.loading();

      this.$http
        .get("/api/v1/master/customer-address/ship-to", {
          params: {
            search: this.search,
            length: this.limitShow,
            page: page,
            customer_id: this.salesOrder.CustomerID,
            territory_id: this.territoryId,
            so_type:
              this.salesOrder.OnetimeCustomerID != 0
                ? "ZDOT"
                : this.salesOrder.Category,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionShipTo = resp.data.records;
            this.recordsTotal = resp.data.total_record;
            this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
            this.dataEnd = resp.data.total_record_search;

            if (autoDefault == true && this.optionShipTo.length > 0) {
              this.salesOrder.ShipAddress.Address =
                this.optionShipTo[0].address;
              this.salesOrder.ShipAddress.City = this.optionShipTo[0].city;
              this.salesOrder.ShipmentToAddressID = this.optionShipTo[0].ID;
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Ship To Address",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionTax(page = 1) {
      this.isActive = page;
      this.$vs.loading();

      this.$http
        .get("/api/v1/master/tax", {
          params: {
            search: this.search,
            length: this.limitShow,
            page: page,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionTax = resp.data.records;
            this.recordsTotal = resp.data.total_record;
            this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
            this.dataEnd = resp.data.total_record_search;

            for (let index = 0; index < this.optionTax.length; index++) {
              if (
                this.optionTax[index].IsDefault == 1 &&
                this.selectTax.Name == ""
              ) {
                this.selectTax = {
                  Name: this.optionTax[index].Name,
                  Rate: this.optionTax[index].Rate,
                };
                break;
              }
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Tax option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionPaymentTerm() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("api/v1/master/payment-term", {
            params: {
              order: "name",
              sort: "asc",
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.paymentTerm.all_options = resp.data.records;
              this.paymentTerm.selected = null;
              // Set Payment Term
              console.log(
                "this.salesOrder.Customer.PricingGroupID",
                this.salesOrder.Customer.PricingGroupID
              );
              this.paymentTerm.selected = this.paymentTerm.all_options.filter(
                (v) => {
                  return v.id == this.salesOrder.Customer.PaymentTermID;
                }
              );
              this.paymentTerm.selected = this.paymentTerm.selected[0];
              resolve(true);
            } else {
              this.$vs.notify({
                title: "Error",
                text: "Failed to get Payment Term option",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
              console.log(resp);
              reject(false);
            }
          });
      });
    },
    getSOTypes() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("api/v1/master/sales-order-type", {
            params: {
              order: "name",
              sort: "asc",
            },
          })
          .then((resp) => {
            console.log(resp);
            if (resp.code == 200) {
              this.optionSoType = resp.data.records;
              resolve(true);
            } else {
              this.$vs.notify({
                title: "Error",
                text: "Failed to get Sales Order Types option",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
              reject(false);
            }
          });
      });
    },
    getOptionDeliveryType() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("api/v1/master/delivery-type", {
            params: {
              order: "id",
              sort: "asc",
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.deliveryType.option = resp.data.records;
              this.deliveryType.selected = {
                id: this.deliveryType.option[0].id,
                name: this.deliveryType.option[0].name,
                own_delivery: this.deliveryType.option[0].own_delivery,
                pickup: this.deliveryType.option[0].pickup,
                parcel_services: this.deliveryType.option[0].parcel_services,
                code: this.deliveryType.option[0].code,
              };
              // Set Delivery Type
              console.log(
                "this.salesOrder.DeliveryType.ID",
                this.salesOrder.DeliveryType.ID
              );
              this.deliveryType.selected = this.deliveryType.option.filter(
                (v) => {
                  return v.id == this.salesOrder.DeliveryType.ID;
                }
              );
              this.deliveryType.selected = this.deliveryType.selected[0];
              resolve(true);
            } else {
              this.$vs.notify({
                title: "Error",
                text: "Failed to get Delivery Type option",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
              console.log(resp);
              reject(false);
            }
          });
      });
    },
    onselected(select, i) {
      let checkItem,
        is_duplicate = false;
      // check duplicate items
      if (this.table.data.length > 1) {
        this.table.data.forEach((item, j) => {
          checkItem = item.formInputs[0].value;
          if (checkItem.code == select.code && i != j) {
            is_duplicate = true;
          }
        });
      }
      if (is_duplicate) {
        // if duplicate, value item dinolkan, dan etc" nya juga dinolkan
        this.table.data[i].formInputs[0].value = {};
        this.table.data[i].items_qty_hu = [];
        this.table.data[i].items_hu = "0";
        this.table.data[i].items_qty = "0";
        this.table.data[i].hu_labels = [];
        this.table.data[i].list_price = [];
        this.table.data[i].reg_disc = [];
        this.table.data[i].promotion = [];
        this.table.data[i].tax = [];
        this.table.data[i].tax_rate = [];
        this.table.data[i].subtotal = {
          price: [],
          disc: 0,
          tax: [],
          all_price: 0,
          all_tax: 0,
        };
        this.$vs.notify({
          title: "Warning",
          text: "Duplicate item detected!",
          color: "warning",
          position: "top-right",
        });
        this.calculated = false;
        return;
      } else {
        this.calculated = false;
        this.$http
          .get("api/v1/item-hu", {
            params: {
              sku_code: select.code,
              co_type: this.soType == null ? "" : this.soType.Code,
              sales_personal_id: this.salesPersonal.selected.ID,
              is_combo: select.is_combo ? true : false,
            },
          })
          .then((resp) => {
            // console.log(resp)
            if (resp.status == "success") {
              // set column width property
              // console.log("colwidth before",this.table.data[i].colWidth);
              if (resp.data.hu.length > 0) {
                this.table.data[i].colWidth = 12 / resp.data.hu.length;
              } else {
                this.table.data[i].colWidth = 12;
              }
              // console.log("colwidth after",this.table.data[i].colWidth);
              //
              // ======= Add items dan HU =======
              //
              // untuk HU length 2, colspan 7, untuk HU length 3 colspan 8
              console.log("i", i);
              console.log(this.table.data[i]);
              console.log(this.table.data[i].hu_labels);
              console.log("resp.data.hu", resp.data.hu);
              this.table.data[i].hu_labels = resp.data.hu;
              this.table.data[i].tax_rate = resp.data.tax_rate;
              this.table.data[i].items_id = select.item_id;
              let sumHU = [];
              let data_Combo = [];
              for (let k = 0; k < resp.data.hu.length; k++) {
                console.log("k", k);
                console.log("resp.data.hu[k]", resp.data.hu[k]);
                sumHU.push(0);
                if (resp.data.hu[k].combo_items) {
                  resp.data.hu[k].combo_items.forEach((el) => {
                    el.total_qty = 0;
                    el.total_qty_combo = 0;
                    el.ComboID = el.ID;
                    el.ComboName = el.Name;
                    el.ComboCode = el.Code;
                    el.rowSpanPlusCombo = 1;
                    el.amount_uom = 1;
                    el.is_expand = false;
                    data_Combo.push(el);
                  });
                  this.isExistSkuCombo = true;
                }
              }
              this.table.data[i].items_qty_hu = sumHU;
              this.table.data[i].qty_hu = sumHU;
              this.table.data[i].items_hu = sumHU.join(".");
              this.table.data[i].items_qty = 0;
              // reset line items price
              this.table.data[i].list_price = [];
              this.table.data[i].reg_disc = [];
              this.table.data[i].promotion = [];
              this.table.data[i].tax = [];
              if (data_Combo && data_Combo.length > 0) {
                this.table.data[i].rowSpanPlus = data_Combo.length - 1;
              }
              this.table.data[i].data_combo = data_Combo;
              // this.table.data[i].tax_rate = null;
              this.table.data[i].subtotal = {
                price: [],
                disc: 0,
                tax: [],
                all_price: 0,
                all_tax: 0,
              };
            } else {
              this.$vs.notify({
                title: "error",
                text: resp.message,
                color: "danger",
                position: "top-right",
              });
            }
            console.log("this.table.data[i]", this.table.data[i]);
          });
      }
    },
    async getCustDeposit_(cust_id) {
      let resp = await this.$http.get(
        `api/v1/cash-bank/customer-deposit/${cust_id}`
      );
      console.log("resp", resp);
      console.log(resp);
      if (resp.status == "success") {
        console.log(
          "data cust order inside getCustDeposit_",
          this.dataCustomerOrder
        );
        if (resp.data.cashBank.length > 0) {
          this.optionCustDeposit = resp.data.cashBank;
          this.optionCustDeposit.forEach((resCo, i) => {
            this.optionCustDeposit[
              i
            ].TrackByKey = `${resCo.ID}${resCo.Code}${resCo.DepositValue}`;
          });

          console.log("677 this.optionCustDeposit", this.optionCustDeposit);
        }
        // Push selected CUST Deposit by data SO
        this.salesOrder.DataDeposits.forEach((cod) => {
          let cd = {
            Code: cod.DepositCode,
            CustomerID: this.selectedSO.Customer.DefaultAttribute.ID,
            DepositValue: cod.Value,
            TrackByKey: `${cod.DepositID}${cod.DepositCode}${cod.Value}`,
            ID: cod.DepositID,
          };
          this.selectedCustDeposit.push(cd);
          this.optionCustDeposit.push(cd);
        });
        console.log("688 this.selectedCustDeposit", this.selectedCustDeposit);
      }
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLableCustDeposit({ Code, DepositValue }) {
      return `${Code} - Value : ${this.formatCurrency(DepositValue)}`;
    },
    customLableCustomer({ Code, Name }) {
      return `${Code} - ${Name}`;
    },
    customLableBillto({ Code, ContactName }) {
      if (Code === undefined) {
        return `-`;
      } else {
        return `${Code} - ${ContactName}`;
      }
    },
    customLableShippTo({ Code, ContactName, Address }) {
      if (Code === undefined) {
        return `-`;
      } else {
        return `${Code} - ${ContactName} - ${Address}`;
      }
    },
    customLableCOType({ Code, Description }) {
      return `${Code} - ${Description}`;
    },
    customLableSalesman({ Name, CodeExternal }) {
      return CodeExternal != "" && CodeExternal
        ? `[ ${CodeExternal} ] ${Name}`
        : `[ - ] ${Name}`;
    },
    onselectedSalesman(select) {
      console.log("onselectedSalesman", select);
      this.$emit("onSelectedSalesChannel", select.SalesChannelId);
    },
    onselectedPricingGroup() {
      if (!this.isOneTime) {
        this.getDataCustomer();
        this.resetShipBillto();
      }
    },
    onselectedZone(select) {
      if (!this.isOneTime) {
        this.getDataCustomer();
        this.getOptionSalesID(false);
        this.resetShipBillto();
      }
    },
    onSelectedShipto(select) {
      if (!this.isOneTime) {
        console.log(select);

        console.log("this.selectedBillTo", this.selectedBillTo);
        console.log("this.selectedShippTo", this.selectedShippTo);
      }
    },
    onSelectedBillto() {},
    onselectedPaymentTerm_() {},
    onselectedPaymentTerm(select) {
      let due_inv_period = select.due_invoice_period;

      let coDate = moment(this.dateCo).format("MM/DD/YYYY");
      let rdd = new Date(coDate);
      rdd.setDate(rdd.getDate() + due_inv_period);
      this.rDeliveryDate = rdd;
    },
    getItem(search, comp) {
      return new Promise((resolve, reject) => {
        let idx = 0;
        if (comp) {
          idx = comp[0].parentId - 1;
          this.table.data[idx].formInputs[0].isLoading = true;
        } else {
          this.table.data.forEach((dt, i) => {
            this.table.data[i].formInputs[0].isLoading = true;
          });
        }
        let searchItem = "";
        if (search) {
          searchItem = search;
        }
        this.$http
          .get("api/v1/get-item-available", {
            params: {
              limit: 40,
              offset: 0,
              search: searchItem,
              co_type_code: this.soType == null ? null : this.soType.Code,
            },
          })
          .then((resp) => {
            this.table.data[idx].formInputs[0].isLoading = false;
            if (resp.code == 200) {
              let data = [];
              resp.data.records.forEach((dt) => {
                data.push({
                  id: dt.item_id,
                  item_id: dt.item_id,
                  code: dt.sku_code,
                  text: "[" + dt.sku_code + "] " + dt.item_name,
                  sku_name: dt.item_name,
                });
              });
              if (comp) {
                this.table.data[idx].formInputs[0].optionValue = data;
              } else {
                this.table.data.forEach((dt, i) => {
                  this.table.data[i].formInputs[0].optionValue = data;
                  this.table.data[i].formInputs[0].isLoading = false;
                });
              }
              resolve(true);
            } else {
              console.log(resp);
              reject(false);
            }
          });
      });
    },
    getOnetimeCustomer() {
      this.$vs.loading();
      this.$http.get("/api/v1/master/customer-onetime").then((resp) => {
        if (resp.code == 200) {
          this.selectedCustomer.code =
            "[ " + resp.data.records.code + " ] " + resp.data.records.name;
          this.selectedCustomer.name = "";
          this.oneTimeCust.customer = resp.data.records;
          // filter dist channel
          this.customerCategory.selected =
            this.customerCategory.all_options.filter((v) => {
              return v.ID == resp.data.records.customer_category_id;
            });
          this.customerCategory.selected = this.customerCategory.selected[0];
          // filter pricing group
          this.pricingGroup.selected = this.pricingGroup.all_options.filter(
            (v) => {
              return v.ID == resp.data.records.pricing_group_id;
            }
          );
          this.pricingGroup.selected = this.pricingGroup.selected[0];
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Customer",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
        this.$vs.loading.close();
      });
    },
    handleCustOnetime() {
      /*
          Save temporary UI data Onetime Customer
          Check apakah form terisi dengan benar? jika ya :
          1. set column field Customer Name
          2. set column field Bill to
          3. Set column field Ship to
        */

      let form_empty;
      // Buat save customer onetime disini, ke tabel baru
      form_empty = this.oneTimeCust.name == null || this.oneTimeCust.name == "";
      form_empty =
        this.oneTimeCust.contact_person == null ||
        this.oneTimeCust.contact_person == "";
      form_empty = this.oneTimeCust.nik == null || this.oneTimeCust.nik == "";
      form_empty =
        this.oneTimeCust.phone == null || this.oneTimeCust.phone == "";

      if (form_empty) {
        this.$vs.notify({
          title: "Error",
          text: "Please fill form correctly !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      this.selectedCustomer.name = this.oneTimeCust.name;
      this.$vs.notify({
        color: "success",
        title: "Success",
        text: "Set customer data success",
        position: "top-right",
        iconPack: "feather",
        icon: "icon-check-circle",
      });
      window.scrollTo(0, 0);
      // this.handleCloseOt();
    },
    submitCustOnetime() {
      return new Promise((resolve, reject) => {
        let form_empty;
        // Buat save customer onetime disini, ke tabel baru
        form_empty =
          this.oneTimeCust.name == null || this.oneTimeCust.name == "";
        form_empty =
          this.oneTimeCust.contact_person == null ||
          this.oneTimeCust.contact_person == "";
        form_empty = this.oneTimeCust.nik == null || this.oneTimeCust.nik == "";
        form_empty =
          this.oneTimeCust.phone == null || this.oneTimeCust.phone == "";

        if (form_empty) {
          this.$vs.notify({
            title: "Error",
            text: "Please fill form correctly !",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        let params, url;
        url =
          this.oneTimeCust.id != null
            ? "/api/v1/sales-order/onetime_cust/" + this.oneTimeCust.id
            : "/api/v1/sales-order/onetime_cust/store";
        params = {
          // Default by Customer - ONETIME CUSTOMER
          type: this.oneTimeCust.customer.Type,
          customer_id: this.oneTimeCust.customer.ID,
          payment_method_id: this.oneTimeCust.customer.PaymentMethodID,

          // Default init value, updated after SO created
          payment_term_id: this.oneTimeCust.customer.PaymentTermID,

          // Value by input
          name: this.oneTimeCust.name,
          country: this.oneTimeCust.address.countryName,
          province: this.oneTimeCust.address.provinceName,
          city: this.oneTimeCust.address.cityName,
          district: this.oneTimeCust.address.districtName,
          sub_district: this.oneTimeCust.address.subdistrictName,
          address: this.oneTimeCust.address_manual,
          postal_code: this.oneTimeCust.address.postalCode,
          contact_person: this.oneTimeCust.contact_person,
          nik: this.oneTimeCust.nik,
          phone: this.oneTimeCust.phone,
          tax_id: this.oneTimeCust.tax_id,
        };

        // console.log('params', params)
        this.$http.post(url, params).then((resp) => {
          // console.log(url, resp);
          if (resp.code == 200) {
            /*
                Sukses
                1. set column field Customer Name
                2. set column field Bill to
                3. Set column field Ship to
              */
            this.oneTimeCust.id =
              this.oneTimeCust.id == null ? resp.data.ID : this.oneTimeCust.id;
            this.selectedCustomer.name = this.oneTimeCust.name;

            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            resolve({
              line: "2332",
              resp: resp,
              status: "success",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            reject({
              line: "2346",
              resp: resp,
              status: "failed",
            });
          }
        });
      });
    },
    resetOTC_data() {
      let oneTimeCust = {
        id: null,
        customer: null,
        name: null,
        address_manual: null,
        address: {
          address: "",
          postalCode: "",
          countryName: "",
          provinceName: "",
          cityName: "",
          districtName: "",
          subdistrictName: "",
        },
        contact_person: null,
        nik: null,
        phone: null,
        payment_method_id: null,
        tax_id: null,
      };
      this.oneTimeCust = oneTimeCust;
    },
    setDataAddress(val) {
      console.log("val", val);
      console.log("oneTimeCust.address", this.oneTimeCust.address);
      this.oneTimeCust.address = val;
    },
    calc_itemsLineQty(index) {
      // console.log('index', 1658)
      var uomConv,
        sumHU,
        sumUOM = 0;
      sumHU = this.table.data[index].items_hu.split(".");
      uomConv = this.table.data[index].hu_labels;

      if (uomConv.length == 0) {
        this.table.data[index].items_qty = 0;
        this.$vs.notify({
          title: "Error",
          text: "Select item first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.table.data[index].items_hu = "0";
        this.table.data[index].items_qty = "0";
        return;
      } else if (sumHU.length != uomConv.length) {
        // this.table.data[index].items_qty = 0;
        this.$vs.notify({
          title: "Error",
          text: "Invalid input !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      } else {
        for (let i = 0; i < sumHU.length; i++) {
          sumUOM += parseInt(sumHU[i] * parseInt(uomConv[i].amount_uom));
          this.table.data[index].items_qty = sumUOM;
        }
        this.calculated = false;
      }
    },
    calc_itemsLineQtyConv(index) {
      console.log(3688, "log");
      var uomConv,
        sumHU,
        amount_uom,
        sumUOM = 0;
      let dataCombo = [];
      let val_input = this.table.data[index].items_qty;

      if (parseInt(val_input) <= 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please add more qty !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        this.table.data[index].items_qty = 1;
        return;
      }

      if (
        this.isExistSkuCombo &&
        this.table.data[index].data_combo.length > 0
      ) {
        dataCombo = this.table.data[index].data_combo;
        console.log("dataCombo", dataCombo);
        console.log("this.table.data[index]", this.table.data[index]);
        this.table.data[index].qty_hu = [];
        dataCombo.forEach((el, i) => {
          if (this.table.data[index].data_combo[i].Quantity) {
            var qtySum =
              this.table.data[index].data_combo[i].Quantity *
              parseInt(val_input);
            console.log("qtySum", qtySum);
            if (qtySum > 0) {
              this.table.data[index].data_combo[i].total_qty = qtySum;
              this.table.data[index].data_combo[i].total_qty_combo = qtySum;
              this.table.data[index].qty_hu.push(qtySum);
            } else {
              this.table.data[index].data_combo[i].total_qty = 0;
            }
          }
          console.log("el", el);
        });
        console.log("this.table.data[index]", this.table.data[index]);
      }

      sumHU = [];
      uomConv = this.table.data[index].hu_labels;
      sumUOM = parseInt(val_input);

      if (uomConv.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Select item first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.table.data[index].items_hu = "0";
        this.table.data[index].items_qty = 0;
        return;
      }

      for (let i = 0; i < uomConv.length; i++) {
        amount_uom = parseInt(uomConv[i].amount_uom);
        if (sumUOM / amount_uom > 0) {
          sumHU.push(parseInt(sumUOM / amount_uom));
          sumUOM = sumUOM % amount_uom;
        } else {
          sumHU.push(0);
        }
      }
      console.log("sumHU", sumHU);
      this.calculated = false;
      this.table.data[index].items_hu = sumHU.join(".");
      this.table.data[index].items_qty_hu = sumHU;
      console.log(
        "this.table.data[index] , index",
        this.table.data[index],
        index
      );
      this.table.data[index].data_combo.forEach((dc, i) => {
        console.log("test2");
        this.table.data[index].data_combo[i].width = this.getWidthByValues(
          this.table.data[index].items_qty_hu,
          15,
          dc.total_qty
        );
      });
      if (this.table.data[index].data_combo.length == 0) {
        this.table.data[index].width = this.getWidthByValues(
          this.table.data[index].items_qty_hu,
          7
        );
      }
    },
    calc_itemsLineQtyHU(index, hu_index) {
      console.log(hu_index);
      console.log(
        "this.table.data[index].items_qty_hu",
        this.table.data[index].items_qty_hu
      );
      console.log(
        "this.table.data[index].items_qty_hu[hu_index]",
        this.table.data[index].items_qty_hu[hu_index]
      );
      var qtyHU,
        uomConv,
        sumUOM = 0,
        lowest_hu;
      uomConv = this.table.data[index].hu_labels;
      qtyHU = this.table.data[index].items_qty_hu;
      lowest_hu = hu_index == uomConv.length - 1 ? true : false;
      if (qtyHU[hu_index] == undefined) {
        this.table.data[index].items_qty = 0;
        this.$vs.notify({
          title: "Error",
          text: "Select item first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.table.data[index].items_hu = "0";
        this.table.data[index].items_qty = "0";
        return;
      } else if (isNaN(qtyHU[hu_index]) || qtyHU[hu_index] == "") {
        console.log("Then");
        console.log(
          "this.table.data[index].items_qty_hu",
          this.table.data[index].items_qty_hu
        );
        console.log(
          "this.table.data[index].items_qty_hu[hu_index]",
          this.table.data[index].items_qty_hu[hu_index]
        );
        qtyHU[hu_index] = "0";
        this.table.data[index].items_hu = qtyHU.join(".");
        // this.table.data[index].items_qty = '0';
        this.table.data[index].items_qty_hu[hu_index] = 0;
        // this.$vs.notify({
        //   title: "Error",
        //   text: "Invalid input !",
        //   color: "danger",
        //   position: "top-right",
        //   iconPack: "feather",
        //   icon: "icon-x-circle"
        // });
        return;
      } else {
        if (lowest_hu && uomConv.length != 1) {
          qtyHU[hu_index] = parseInt(qtyHU[hu_index], 10);
          qtyHU[hu_index - 1] = parseInt(qtyHU[hu_index - 1], 10);
          // cek jika yang berubaha adalah lowest HU, dan jumlah yang diinput lebih dari amount_uom HU atasnya, maka ditambah ke UOM atasnya, dan value diset jadi mod nya
          if (qtyHU[hu_index] >= parseInt(uomConv[hu_index - 1].amount_uom)) {
            let divRes, modRes;
            divRes =
              qtyHU[hu_index] / parseInt(uomConv[hu_index - 1].amount_uom);
            modRes =
              qtyHU[hu_index] % parseInt(uomConv[hu_index - 1].amount_uom);
            // tambah uom atasnya
            qtyHU[hu_index - 1] += divRes;
            // ubah input HU sekarang
            qtyHU[hu_index] = modRes;
          }
        }
        for (let i = 0; i < uomConv.length; i++) {
          qtyHU[i] = parseInt(qtyHU[i], 10);
          sumUOM += parseInt(qtyHU[i] * parseInt(uomConv[i].amount_uom));
        }
        this.table.data[index].items_hu = qtyHU.join(".");
        this.table.data[index].items_qty = sumUOM;
        this.calc_itemsLineQtyConv(index);
        this.calculated = false;
      }
    },
    formatCurrency_old(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    formatCurrency(nominal) {
      if (nominal && nominal > 0) {
        // nominal > 0 untuk handle return NaN
        nominal = (nominal / 1).toFixed(2).replace(".", ",");
        return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        return 0;
      }
    },
    checkFgFrom(i) {
      console.log(i);
      if (i.line !== undefined) {
        let head = null;
        this.detailDisc = true;
        for (let index = 0; index < this.table.data.length; index++) {
          if (index == i.line) {
            this.table.data[index].isBlink = !this.table.data[index].isBlink;
            head = this.table.data[index];
          } else {
            this.table.data[index].isBlink = false;
          }
        }
        this.detailDisc = false;
      }
    },
    resetShipBillto() {
      this.optionBillTo = [];
      this.optionShippTo = [];
      this.selectedBillTo = {
        ID: null,
        customer_id: "",
        code: "",
        address: "",
      };
      this.selectedShippTo = {
        ID: null,
        customer_id: "",
        code: "",
        address: "",
      };
    },
    handlerSearchShipto(search) {
      console.log(this.selectedTerritory);
      if (search != "") {
        let params = {
          // territory_id: this.selectedTerritory != null ? (this.selectedTerritory.id != undefined ? this.selectedTerritory.id : 0) : 0,
          territory_id:
            this.selectedSO.Territory != null
              ? this.selectedSO.Territory.DefaultAttribute.ID != undefined
                ? this.selectedSO.Territory.DefaultAttribute.ID
                : 0
              : 0,
          customer_id: this.salesOrder.CustomerID,
          search: search,
          length: 40,
          so_type:
            this.salesOrder.OnetimeCustomerID != 0
              ? "ZDOT"
              : this.salesOrder.Category,
        };
        this.getAllAddress(params);
      }
    },
    handlerSearchCustomer(search) {
      this.searchCust = search;
      this.getDataCustomer();
    },
    handlerSearchZone(search) {
      // search zone disini from all_options
      search = new RegExp(search, "gi");
      this.zone.option = this.zone.all_options.filter((v) => {
        return v.name.match(search) != null;
      });
    },
    handlerSearchSales(search) {
      this.searchSales = search;
      if (search != "") {
        this.getOptionSalesID(false);
      }
    },
    getAllAddress(params) {
      console.log("this.selectedTerritory", this.selectedTerritory);
      console.log("this.selectedSO.Territory", this.selectedSO.Territory);

      console.log("params", params);
      this.isLoadingShipto = true;
      // var url1 = "/api/sfa/v1/customer-address/list"
      var url2 = "/api/v1/master/customer-address/ship-to";
      this.$http.get(url2, { params: params }).then((resp) => {
        console.log(resp);
        this.isLoadingShipto = false;
        if (resp.code == 200) {
          if (resp.data.records != null) {
            this.optionShippTo = [];
            this.optionShippTo.push(this.salesOrder.ShipAddress);
            resp.data.records.forEach((st) => {
              this.optionShippTo.push({
                Address: st.address,
                Code: st.code,
                ContactName: st.contact_name,
                CustomerID: st.customer_id,
                DefaultAttribute: {
                  ID: st.ID,
                },
              });
            });
          } else {
            this.optionShippTo = [];
          }
        } else {
          console.log(resp);
        }
      });
    },
    getDataCustomer() {
      console.log("this.selectedShippTo", this.selectedShippTo);
      if (this.selectedShippTo.ID != null) {
        let params = {
          id: this.selectedShippTo.customer_id,
        };
        this.isLoadingCus = true;
        // this.$http.get("/api/sfa/v1/customer/filter", {params: params}).then((resp) => {
        this.$http
          .get("/api/v1/master/customer/" + this.selectedShippTo.customer_id)
          .then((resp) => {
            this.isLoadingCus = false;
            if (resp.code == 200) {
              this.selectedCustomer = resp.data;
              this.optionCustomer = [resp.data];
            } else {
              console.log(resp);
            }
            console.log("this.selectedCustomer", this.selectedCustomer);
          });
      }
    },
    // Space for submit
    //
    handlerSearchSelect(searching, comp) {
      this.getItem(searching, this.table.data[comp.parentId - 1].formInputs);
    },
    handlerSearchSelect_fg(searching, comp) {
      console.log("this.table.free_goods", this.table.free_goods);
      console.log("searching", searching);
      console.log("comp", comp);
      console.log("comp.i_parent", comp.i_parent);

      this.table.free_goods.forEach((el, i) => {
        // console.log("i, isEqual", i, _.isEqual(comp, el))
        console.log("comp", comp);
        console.log("el", el);

        let condSamePromo =
          el.fg_component.value.promotion_id == comp.value.promotion_id &&
          el.fg_component.value.promotion_item_req_id ==
            comp.value.promotion_item_req_id &&
          el.fg_component.value.promotion_step_id ==
            comp.value.promotion_step_id;
        if (condSamePromo) {
          console.log("Promo sama persis dengan sebelum recalculate");
          console.log("this.table.free_goods[ix]", this.table.free_goods[i]);

          if (
            searching != "" &&
            this.table.free_goods[i].fg_component.allOptionValue
          ) {
            this.table.free_goods[i].fg_component.optionValue =
              this.table.free_goods[i].fg_component.allOptionValue.filter(
                (v) => {
                  return v.sku_code.match(searching) != null;
                }
              );
          } else if (
            searching == "" &&
            this.table.free_goods[i].fg_component.allOptionValue
          ) {
            this.table.free_goods[i].fg_component.optionValue =
              this.table.free_goods[i].fg_component.allOptionValue;
          }

          if (
            this.table.free_goods[comp.i_parent].fg_component.optionValue
              .length == 0
          ) {
            this.table.free_goods[comp.i_parent].fg_component.optionValue =
              this.table.free_goods[comp.i_parent].fg_component.allOptionValue;
          }
          //
        } else {
          console.log("Tidak ada promo yang sama");
        }
      });
    },
    onselected_fg(index) {
      if (this.table.free_goods[index].fg_component.optionValue.length > 0) {
        this.table.free_goods[index].fg_component.optionValue =
          this.table.free_goods[index].fg_component.allOptionValue;
      }
    },
    handleAddItem() {
      console.log(this.table);
      let i_new = this.table.data.length;

      this.table.data.push({
        allow_partial: 0,
        rowSpanPlus: 1,
        items_id: 0,
        data_combo: [],
        id: i_new + 1,
        freeItems: false,
        formInputs: [
          {
            id: 1,
            parentId: i_new + 1,
            disabled: false,
            validate: "required",
            name: "sku_code",
            placeholder: "Sku Code",
            head: "Sku Code",
            type: "multiselect",
            multiple: false,
            allowempty: false,
            value: {},
            optionValue: [],
          },
        ],
        items_hu: "0",
        hu_labels: [],
        promotion: [],
        tax_rate: [],
        subtotal: {
          disc: 0,
          all_price: "0.00",
          all_tax: "0.00",
          tax_rate: [],
          price: [],
          tax: [],
        },
        is_rejected: false,
        items_qty: 0,
        qty_hu: [],
        list_price: [],
        reg_disc: [],
        tax: [],
        grand_total: [],
        items_qty_hu: [],
        colWidth: 12,
        isBlink: false,
        recalculate: false,
        reject_reason_id: null,
        reason: null,
      });

      this.getItem(null, this.table.data[i_new].formInputs);
      this.calculated = false;
    },
    handleRemoveItem(index) {
      if (this.table.data.length == 1) {
        this.$vs.notify({
          title: "Warning",
          text: "Cannot delete all items, please reject instead!",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }
      let tempIsExistCombo = false;
      this.table.data = this.table.data.filter((v, i) => {
        if (i != index && !tempIsExistCombo) {
          tempIsExistCombo = v.data_combo.length > 0;
        }
        return i != index;
      });
      this.isExistSkuCombo = tempIsExistCombo;
      this.calculated = false;
    },
    setDefaultDate() {
      var day = new Date();
      var nextDay = new Date(day);
      nextDay.setDate(day.getDate() + 1);

      this.salesOrder.Date = day;
      this.salesOrder.DueDate = nextDay;
    },
    getTime(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/customer/work-time/" + id).then((resp) => {
        if (resp.code == 200) {
          var day = new Date();
          var nextDay = new Date(day);
          nextDay.setDate(day.getDate() + 1);
          nextDay.setHours(resp.data[0]);
          nextDay.setMinutes(resp.data[1]);
          this.salesOrder.DueDate = nextDay;
        }
        this.$vs.loading.close();
      });
    },
    handleCalculate() {
      console.log(this.table.data);
      console.log(this.salesOrder);
      let is_complete = true,
        msg_incomplete = [];
      if (
        this.salesOrder.Customer == {} ||
        this.salesOrder.Customer == null ||
        this.salesOrder.Customer.Code == "" ||
        this.salesOrder.Customer.Code == null
      ) {
        is_complete = false;
        msg_incomplete.push("Please select customer !");
      }

      if (
        this.salesOrder.BillAddress.DefaultAttribute.ID == null &&
        !this.isOneTime
      ) {
        is_complete = false;
        msg_incomplete.push("Please select bill to address !");
      }

      if (
        this.salesOrder.ShipAddress.DefaultAttribute.ID == null &&
        !this.isOneTime
      ) {
        is_complete = false;
        msg_incomplete.push("Please select shipp to address !");
      }

      if (this.selectedTerritory == null) {
        is_complete = false;
        msg_incomplete.push("Please select territory !");
      }

      if (this.zone.selected == null) {
        is_complete = false;
        msg_incomplete.push("Please select zone !");
      }

      if (
        this.salesOrder.SalesChannel.DefaultAttribute.ID == undefined ||
        this.salesOrder.SalesChannel.DefaultAttribute.ID == null ||
        this.salesChannel.selected == null
      ) {
        is_complete = false;
        msg_incomplete.push("Please select sales channel !");
      }

      if (
        this.salesOrder.Customer.CustomerCategory.DefaultAttribute.ID ==
          undefined ||
        this.salesOrder.Customer.CustomerCategory.DefaultAttribute.ID == null
      ) {
        is_complete = false;
        msg_incomplete.push("Please select distribution channel !");
      }

      if (
        this.salesPersonal.selected == null ||
        this.salesPersonal.selected.length == 0
      ) {
        is_complete = false;
        msg_incomplete.push("Please select salesman !");
      }

      if (this.pricingGroup.selected == null) {
        is_complete = false;
        msg_incomplete.push("Please select pricing group !");
      }

      if (this.paymentTerm.selected == null) {
        is_complete = false;
        msg_incomplete.push("Please select payment term !");
      }

      if (this.soType == null) {
        is_complete = false;
        msg_incomplete.push("Please select SO Type !");
      }

      let complete_item = true;
      this.table.data.forEach((td) => {
        if (td.formInputs[0].value.code == undefined) {
          complete_item = false;
        }
      });

      if (!complete_item) {
        is_complete = false;
        msg_incomplete.push("Please select item !");
      }

      // check if jumlah UOM tiap item > 0 | if 0 maka return tidak bisa calculate
      for (let i = 0; i < this.table.data.length; i++) {
        let itmCheck = this.table.data[i];
        if (
          parseInt(itmCheck.items_qty) == 0 &&
          !itmCheck.freeItems &&
          !itmCheck.is_rejected
        ) {
          is_complete = false;
          msg_incomplete.push(
            "Please add qty order for item [" +
              itmCheck.formInputs[0].value.code +
              "]" +
              itmCheck.formInputs[0].value.sku_name
          );
        }
      }

      if (!is_complete) {
        msg_incomplete.forEach((msg) => {
          this.$vs.notify({
            title: "Warning",
            text: msg,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        });
        this.$vs.notify({
          title: "Warning",
          text: "Please fill the form correctly!",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        this.calculated = false;
        return;
      }

      let params = {
        sales_channel_code: this.salesChannel.selected.code,
        territory_code: this.salesOrder.Territory.Code,
        company_code: this.$store.state.user.currentUser.company_code,
        company_id: this.$store.state.user.currentUser.company_id,
        customer_code: this.isOneTime
          ? this.oneTimeCust.customer.Code
          : this.salesOrder.Customer.Code,
        pricing_group_id: this.salesOrder.Customer.PricingGroupID
          ? this.salesOrder.Customer.PricingGroupID
          : 0,
        ship_to_id: this.salesOrder.ShipAddress.DefaultAttribute.ID,
        bill_to_id: this.salesOrder.BillAddress.DefaultAttribute.ID,
        promo_code: [],
        tax_rate: 0,
        subtotal_charge: 0,
        items: [],
        so_type: this.soType.Code,
        date: this.salesOrder.Date
          ? moment(String(this.salesOrder.Date)).format("YYYY-MM-DD")
          : null,
        category: this.soType != null ? this.soType.Code : "",
      };

      for (let i = 0; i < this.table.data.length; i++) {
        if (this.table.data[i].is_rejected || this.table.data[i].freeItems) {
          continue;
        }

        let rounding;
        let _items = this.table.data[i].formInputs[0].value;
        let items_qty_hu = this.table.data[i].items_qty_hu;

        for (let j = 0; j < this.table.data[i].hu_labels.length; j++) {
          let is_combo = false;
          is_combo = this.table.data[i].data_combo.length != 0;
          console.log("is_combo", is_combo);
          console.log(
            "this.table.data[i].data_combo",
            this.table.data[i].data_combo
          );
          console.log("this.table.data[i], i", this.table.data[i], i);
          if (is_combo) {
            let _combo_items = [];
            _combo_items = this.table.data[i].data_combo;
            if (
              _combo_items == undefined ||
              (_combo_items != undefined && _combo_items.length == 0)
            ) {
              this.$vs.notify({
                title: "The child combo has not been set",
                text: "please set the child combo first",
                color: "danger",
                position: "top-right",
              });
              return;
            }
            _combo_items.forEach((el, ici) => {
              rounding =
                this.table.data[i].list_price.length > 0
                  ? parseFloat(this.table.data[i].list_price[ici].rounding)
                  : 0;
              let temp_item = {
                item_code: el.ItemCode,
                combo_code: el.Code,
                item_name: el.ItemName,
                item_uom: el.UnitName,
                item_quantity_request: 0,
                item_quantity_adjusted: parseInt(el.total_qty),
                total_qty: parseInt(
                  this.table.data[i].items_qty
                    ? this.table.data[i].items_qty
                    : 0
                ),
                item_price: 0,
                is_combo: true,
                rounding: rounding,
              };
              params.items.push(temp_item);
            });
          } else if (parseInt(items_qty_hu[j]) > 0) {
            // rounding = parseFloat(this.table.data[i].list_price[j].rounding);
            rounding =
              this.table.data[i].list_price.length == 0
                ? 0
                : parseFloat(this.table.data[i].list_price[j].rounding);
            let temp_item = {
              item_code: _items.code,
              combo_code: null,
              item_name: _items.sku_name,
              item_uom: this.table.data[i].hu_labels[j].unit,
              item_quantity_request: 0,
              item_quantity_adjusted: parseInt(items_qty_hu[j]),
              item_price: 0,
              is_combo: false,
              rounding: rounding,
              total_qty: parseInt(
                this.table.data[i].items_qty ? this.table.data[i].items_qty : 0
              ),
            };
            params.items.push(temp_item);
          }
        }
      }

      console.log("NEW params", params);
      console.log("this.initSelectedFg", this.initSelectedFg);

      this.$vs.loading();
      this.$http.post("/api/v3/sales-order/calculate", params).then((resp) => {
        // console.log(resp);
        this.table.free_goods = [];
        let total_net_value = 0,
          total_weight = 0,
          total_volume = 0;
        if (resp.code == 200) {
          this.$vs.loading.close();
          var calc_resp = resp.data;
          var is_available_price = true;
          // this.calculated = true;
          for (var i = 0; i < calc_resp.pricing.length; i++) {
            if (
              calc_resp.pricing[i].sub_total <= 0 &&
              calc_resp.pricing[i].sub_total_engine <= 0
            ) {
              this.$vs.loading.close();
            }
            if (calc_resp.pricing[i].error_message != "") {
              this.$vs.notify({
                title: "Error",
                text: calc_resp.pricing[i].error_message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle",
              });
              this.$vs.notify({
                title: "Error",
                text:
                  "Please set price for item : " + calc_resp.pricing[i].code,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle",
              });
              is_available_price = false;
            }
          }

          if (is_available_price) {
            // Loops price/pricing per SO Line, per HU
            console.log("this.table.data == ", this.table.data);
            for (let i = 0; i < this.table.data.length; i++) {
              if (this.table.data[i].is_rejected) {
                continue;
              }

              let isComboData = this.table.data[i].data_combo.length > 0;
              this.table.data[i].list_price = [];
              this.table.data[i].reg_disc = [];
              this.table.data[i].tax = [];
              this.table.data[i].items_qty_hu = [];
              this.table.data[i].qty_hu = [];
              this.table.data[i].subtotal.price = [];
              this.table.data[i].subtotal.tax = [];
              this.table.data[i].subtotal.tax_rate = [];
              this.table.data[i].subtotal.disc = [];
              this.table.data[i].subtotal.gross_up = [];
              this.table.data[i].grand_total = [];
              this.table.data[i].rowSpanPlus = 1;

              let sku = this.table.data[i];
              // let warn_item = !isComboData ? this.table.data[i].formInputs[0].value : null;
              let warn_item = this.table.data[i].formInputs[0].value;
              let sku_item_unit_ids = [];
              let calc_item_unit_ids = [];

              calc_resp.pricing.forEach((calc) => {
                calc_item_unit_ids.push(parseInt(calc.item_unit_id));
              });

              if (isComboData) {
                this.table.data[i].hu_labels[0].combo_items.forEach((v) => {
                  sku_item_unit_ids.push(parseInt(v.ItemUnitID));
                });
              } else {
                this.table.data[i].hu_labels.forEach((v) => {
                  sku_item_unit_ids.push(parseInt(v.item_unit_id));
                });
              }

              let all_tax = 0,
                grand_total = 0,
                st_price = 0,
                st_disc = 0,
                st_gross_up = 0,
                all_gross_up = 0,
                temp_listprice = null;
              let sku_item_unitID_added = [];
              let is_head = false,
                is_head2 = false,
                ex_head = false,
                ex_head2 = false;
              this.table.data[i].hu_labels.forEach((val, j) => {
                if (val.is_combo) {
                  this.table.data[i].items_qty_hu.push(
                    this.table.data[i].items_qty
                  );
                }

                calc_resp.pricing.forEach((calc, i_calc) => {
                  if (
                    this.soType.Code == "ZDSM" &&
                    calc.price > 0 &&
                    calc.qty > 0
                  ) {
                    calc.sub_total = calc.price * calc.qty;
                  }

                  console.log("so type:", this.soType);
                  console.log("calc.sub_total:", calc.sub_total);
                  console.log("calc.price:", calc.price);
                  console.log("calc.qty:", calc.qty);
                  let combo_items = [];
                  combo_items = this.table.data[i].data_combo;
                  if (calc.is_combo) {
                    combo_items.forEach((elm) => {
                      if (
                        elm.ItemCode == calc.code &&
                        calc.unit == elm.UnitName &&
                        (elm.ID == calc.combo_id ||
                          elm.ComboID == calc.combo_id)
                      ) {
                        console.log(4566);
                        console.log(elm.ItemCode, "==", calc.code);
                        if (calc.unit == elm.UnitName) {
                          warn_item = {
                            item_unit_id: elm.ItemUnitID,
                            code: elm.ItemCode,
                            text: "[" + elm.ItemCode + "] " + elm.ItemName,
                            sku_name: elm.ItemName,
                            is_combo: true,
                          };

                          // calc_item_unit_ids.push( parseInt(calc.item_unit_id));
                          elm.total_qty_combo = elm.total_qty;
                          if (!ex_head && calc.qty > 0) {
                            ex_head = true;
                            is_head = true;
                          }
                          temp_listprice = {
                            is_head: is_head,
                            sku_code: calc.code,
                            price: calc.price ? calc.price.toFixed(2) : 0,
                            discount: calc.discount
                              ? calc.discount.toFixed(2)
                              : this.salesOrder.Category == "ZDSM"
                              ? calc.sub_total.toFixed(2)
                              : "0",
                            dpp: calc.dpp,
                            item_name: calc.name,
                            unit: calc.unit,
                            rounding: calc.rounding,
                            gross_up: calc.gross_up ? calc.gross_up : 0,
                            cogs: calc.cogs.toFixed(2),
                            height: calc.height,
                            width: calc.width,
                            length: calc.length,
                            volume: calc.volume,
                            weight: calc.weight,
                            price_rule_code: calc.price_rule_code,
                            price_rule_id: calc.price_rule_id,
                            price_rule_valid_to: moment(
                              calc.price_rule_valid_to
                            ).format("YYYY-MM-DD"),
                            price_rule_valid_from: moment(
                              calc.price_rule_valid_from
                            ).format("YYYY-MM-DD"),
                            division_code: calc.division_code,
                            division_description: calc.division_description,
                            net_weight: calc.net_weight,
                            total_height: calc.total_height,
                            total_weight: calc.total_weight,
                            total_width: calc.total_width,
                            total_volume: calc.total_volume,
                            total_length: calc.total_length,
                            is_expand: false,
                            total_net_weight: calc.total_net_weight,
                            promo_calculation: calc.promo_calculation,
                          };
                          is_head = false;
                          total_net_value += calc.total_net_weight;
                          total_weight += calc.total_weight;
                          total_volume += calc.total_volume;

                          console.log("temp_listprice=>", temp_listprice);

                          this.table.data[i].list_price.push(temp_listprice);
                          this.table.data[i].reg_disc.push(
                            calc.discount
                              ? calc.discount.toFixed(2)
                              : this.salesOrder.Category == "ZDSM"
                              ? calc.sub_total.toFixed(2)
                              : "0"
                          );
                          this.table.data[i].qty_hu.push(calc.qty);
                          sku_item_unitID_added.push(
                            this.table.data[i].hu_labels[j].item_unit_id
                          );

                          if (calc.promo_free_good.length > 0) {
                            this.addFreeGoods(i, calc, i_calc);
                          }

                          st_price += calc.sub_total;
                          st_gross_up += calc.gross_up ? calc.gross_up : 0;

                          all_tax += calc.tax;
                          all_gross_up += calc.gross_up ? calc.gross_up : 0;
                          this.table.data[i].subtotal.tax.push(
                            calc.tax.toFixed(2)
                          );
                          this.table.data[i].tax.push(calc.tax.toFixed(2));
                          this.table.data[i].subtotal.tax_rate.push(
                            calc.tax_rate
                          );

                          console.log("trigger");

                          this.table.data[i].subtotal.price.push(
                            calc.sub_total.toFixed(2)
                          );

                          console.log("calc log", calc);

                          st_disc =
                            this.soType != null && this.soType.Code == "ZDSM"
                              ? calc.sub_total + calc.tax
                              : calc.discount
                              ? calc.discount
                              : 0;
                          this.table.data[i].subtotal.disc.push(
                            st_disc.toFixed(2)
                          ); // ini subtotal discount (disc * qty)
                          grand_total =
                            this.soType != null && this.soType.Code == "ZDSM"
                              ? calc.sub_total - calc.sub_total
                              : calc.sub_total -
                                (calc.discount > 0 ? calc.discount : 0) +
                                calc.tax +
                                calc.gross_up;
                          console.log(
                            "masuk sini 3392, grand_total",
                            grand_total
                          );
                          console.log("calc.sub_total", calc.sub_total);
                          console.log("calc.discount", calc.discount);
                          console.log("calc.tax", calc.tax);
                          this.table.data[i].grand_total.push(
                            grand_total.toFixed(2)
                          );
                        }
                      }
                    });
                  }

                  if (!val.is_combo) {
                    console.log("val.sku_code <==>", val.sku_code);

                    if (val.sku_code == calc.code && !calc.is_combo) {
                      if (
                        calc.unit == val.unit &&
                        !sku_item_unitID_added.includes(calc.item_unit_id)
                      ) {
                        if (!ex_head2 && calc.qty > 0) {
                          is_head2 = true;
                          ex_head2 = true;
                        }
                        temp_listprice = {
                          is_head: is_head2,
                          sku_code: calc.code,
                          price: calc.price ? calc.price.toFixed(2) : 0,
                          discount: calc.discount
                            ? calc.discount.toFixed(2)
                            : this.salesOrder.Category == "ZDSM"
                            ? calc.sub_total.toFixed(2)
                            : "0",
                          dpp: calc.dpp,
                          item_name: calc.name,
                          unit: calc.unit,
                          rounding: calc.rounding,
                          gross_up: calc.gross_up ? calc.gross_up : 0,
                          cogs: calc.cogs.toFixed(2),
                          height: calc.height,
                          width: calc.width,
                          length: calc.length,
                          volume: calc.volume,
                          weight: calc.weight,
                          price_rule_code: calc.price_rule_code,
                          price_rule_id: calc.price_rule_id,
                          price_rule_valid_to: moment(
                            calc.price_rule_valid_to
                          ).format("YYYY-MM-DD"),
                          price_rule_valid_from: moment(
                            calc.price_rule_valid_from
                          ).format("YYYY-MM-DD"),
                          division_code: calc.division_code,
                          division_description: calc.division_description,
                          net_weight: calc.net_weight,
                          total_height: calc.total_height,
                          total_weight: calc.total_weight,
                          total_width: calc.total_width,
                          total_volume: calc.total_volume,
                          total_length: calc.total_length,
                          is_expand: false,
                          total_net_weight: calc.total_net_weight,
                          promo_calculation: calc.promo_calculation,
                        };
                        is_head2 = false;
                        total_net_value += calc.total_net_weight;
                        total_weight += calc.total_weight;
                        total_volume += calc.total_volume;

                        this.table.data[i].list_price.push(temp_listprice);
                        this.table.data[i].reg_disc.push(
                          calc.discount
                            ? calc.discount.toFixed(2)
                            : this.salesOrder.Category == "ZDSM"
                            ? calc.sub_total.toFixed(2)
                            : "0"
                        );
                        this.table.data[i].qty_hu.push(calc.qty);
                        this.table.data[i].items_qty_hu.push(calc.qty);
                        sku_item_unitID_added.push(val.item_unit_id);

                        if (calc.promo_free_good.length > 0) {
                          this.addFreeGoods(i, calc, i_calc);
                        }

                        st_price += calc.sub_total;
                        st_gross_up += calc.gross_up ? calc.gross_up : 0;

                        all_tax += calc.tax;
                        all_gross_up += calc.gross_up ? calc.gross_up : 0;
                        this.table.data[i].subtotal.tax.push(
                          calc.tax.toFixed(2)
                        );
                        this.table.data[i].subtotal.tax_rate.push(
                          calc.tax_rate
                        );

                        this.table.data[i].subtotal.price.push(
                          calc.sub_total.toFixed(2)
                        );

                        st_disc =
                          this.soType != null && this.soType.Code == "ZDSM"
                            ? calc.sub_total + calc.tax
                            : calc.discount;
                        this.table.data[i].subtotal.disc.push(
                          st_disc.toFixed(2)
                        ); // ini subtotal discount (disc * qty)
                        // grand_total = (this.soType != null && this.soType.Code == "ZDSM") ? calc.sub_total - calc.sub_total_discountable : calc.sub_total - calc.discountable + calc.tax;
                        // this.table.data[i].grand_total.push( (grand_total).toFixed(2) );

                        // Penyesuaian ZDSM
                        grand_total =
                          this.soType != null && this.soType.Code == "ZDSM"
                            ? calc.sub_total - calc.sub_total
                            : calc.sub_total -
                              (calc.discount > 0 ? calc.discount : 0) +
                              calc.tax +
                              calc.gross_up;
                        console.log(
                          "masuk sini 4198, grand_total",
                          grand_total
                        );
                        console.log("calc.sub_total", calc.sub_total);
                        console.log("calc.discount", calc.discount);
                        console.log("calc.tax", calc.tax);
                        this.table.data[i].grand_total.push(
                          grand_total.toFixed(2)
                        );
                        console.log("tapi masuk sini kan? 4901");
                      } else if (
                        !calc_item_unit_ids.includes(
                          parseInt(val.item_unit_id)
                        ) &&
                        !sku_item_unitID_added.includes(val.item_unit_id)
                      ) {
                        // jika loops per item per HU tidak ditemukan di keduanya (calc resp, dan skuIUid yang sudah ditambah) maka add price nol
                        console.log("masuk sini ga sih? 4904");
                        temp_listprice = {
                          price: "0",
                          sku_code: "",
                          discount: "0",
                          item_name: calc.name,
                          unit: val.unit,
                          rounding: 0,
                          gross_up: 0,
                          cogs: 0,
                          height: 0,
                          width: 0,
                          length: 0,
                          volume: 0,
                          weight: 0,
                          price_rule_code: calc.price_rule_code,
                          price_rule_id: calc.price_rule_id,
                          price_rule_valid_to: moment(
                            calc.price_rule_valid_to
                          ).format("YYYY-MM-DD"),
                          price_rule_valid_from: moment(
                            calc.price_rule_valid_from
                          ).format("YYYY-MM-DD"),
                          division_code: calc.division_code,
                          division_description: calc.division_description,
                          net_weight: 0,
                          total_height: 0,
                          total_weight: 0,
                          total_width: 0,
                          total_volume: 0,
                          total_length: 0,
                          is_expand: false,
                          total_net_weight: 0,
                          promo_calculation: [],
                          promo_free_good: [],
                          dpp: 0,
                        };

                        this.table.data[i].list_price.push(temp_listprice);
                        this.table.data[i].reg_disc.push((0).toFixed(2));
                        this.table.data[i].qty_hu.push(0);
                        this.table.data[i].items_qty_hu.push(0);

                        if (calc.promo_free_good.length > 0) {
                          this.addFreeGoods(i, calc, i_calc);
                        }

                        st_price += 0;
                        st_gross_up += 0;

                        all_tax += 0;
                        all_gross_up += 0;
                        this.table.data[i].subtotal.tax.push((0).toFixed(2));
                        this.table.data[i].subtotal.tax_rate.push(
                          calc.tax_rate
                        );

                        this.table.data[i].subtotal.price.push((0).toFixed(2));

                        // push grandtotal
                        st_disc = 0;
                        this.table.data[i].subtotal.disc.push(
                          st_disc.toFixed(2)
                        ); // ini subtotal discount (disc * qty)
                        grand_total = 0;
                        this.table.data[i].grand_total.push(
                          grand_total.toFixed(2)
                        );

                        // push itemUnitID
                        sku_item_unitID_added.push(
                          this.table.data[i].hu_labels[j].item_unit_id
                        );
                      }
                    }
                    // })
                  }
                });
              });

              this.table.data[i].subtotal.gross_up.push(st_gross_up.toFixed(2));
              this.calculated = true;

              if (st_price <= 0) {
                this.calculated = false;
                this.$vs.notify({
                  title: "Error",
                  text:
                    "Please add qty or set price for item : " +
                    warn_item.sku_name,
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }

              this.table.data[i].subtotal.all_price = st_price.toFixed(2);
              this.table.data[i].subtotal.all_tax = all_tax.toFixed(2);
              this.table.data[i].subtotal.all_gross_up =
                all_gross_up.toFixed(2);
            }
          }

          let lenSOLine = this.table.data.length;
          let rejectedSOLine = 0;
          this.table.data.forEach((td) => {
            if (td.is_rejected) {
              rejectedSOLine++;
            }
          });

          if (lenSOLine == rejectedSOLine) {
            this.calculated = true;
            this.$vs.notify({
              title: "Warning",
              text: "All items is rejected.",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
          }
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to calculate Sales Order",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          this.calculated = false;
          this.$vs.loading.close();
        }

        this.setDefaultSelectedFG();

        if (this.calculated == true) {
          this.CalcRespData = resp.data;
          this.CalcRespData.total_net_value = total_net_value;
          this.CalcRespData.total_weight = total_weight;
          this.CalcRespData.total_volume = total_volume;
          this.setAllowPartialSkus(this.calculated);
          this.setSubtotalDiscWithFreegoods();
          this.$vs.notify({
            title: "Success",
            text: resp.message,
            color: "success",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          if (this.table.free_goods.length > 0) {
            this.setPromoDiscountTypeLowestPrice();
            this.existing_fg = true;
          } else {
            this.existing_fg = false;
          }
        }
        console.log(this.table);
        console.log(this.table.data);
        console.log(this.calculated);
        this.$vs.loading.close();
      });
    },
    setPromoDiscountTypeLowestPrice() {
      const disc_type_lower_price = 5;
      console.log("4346 this.table.free_goods", this.table.free_goods);
      this.table.free_goods.forEach((elm, i_fg) => {
        console.log("elm", elm);
        if (
          parseInt(this.table.free_goods[i_fg].discount_type) ==
          disc_type_lower_price
        ) {
          let temp_selectFGLowestPrice = {};
          let temp_price_lower = 0;
          elm.fg_component.optionValue.forEach((el_FGLowestPrice) => {
            console.log(
              "lowest price=>",
              i_fg + ".",
              el_FGLowestPrice.price,
              "<",
              temp_price_lower,
              "=",
              el_FGLowestPrice.price < temp_price_lower
            );
            if (
              temp_price_lower <= 0 &&
              elm.fg_component.optionValue.length > 1
            ) {
              temp_price_lower = el_FGLowestPrice.price;
            } else if (el_FGLowestPrice.price < temp_price_lower) {
              temp_price_lower = el_FGLowestPrice.price;
              temp_selectFGLowestPrice = el_FGLowestPrice;
            } else if (
              temp_price_lower <= 0 &&
              elm.fg_component.optionValue.length == 1
            ) {
              temp_price_lower = el_FGLowestPrice.price;
              temp_selectFGLowestPrice = el_FGLowestPrice;
            }
          });
          temp_selectFGLowestPrice.price =
            temp_selectFGLowestPrice.price.toFixed(2);
          temp_selectFGLowestPrice.discount =
            temp_selectFGLowestPrice.discount.toFixed(2);
          this.table.free_goods[i_fg].fg_component.value =
            temp_selectFGLowestPrice;
          this.table.free_goods[i_fg].fg_component.disabled = true;

          let selectedFG = {
            promotion_id: elm.promotion_id,
            promotion_item_req_id: elm.promotion_item_req_id,
            promotion_step_id: elm.promotion_step_id,
            item_unit_id: elm.fg_component.value.item_unit_id,
          };
          this.initSelectedFg[i_fg] = selectedFG;
        }

        // Titip untuk set All Option Value Freegoods, untuk handle search freegood
        this.table.free_goods[i_fg].fg_component.allOptionValue =
          this.table.free_goods[i_fg].fg_component.optionValue;
        console.log("this.initSelectedFg 5342");
        console.log(this.initSelectedFg);
      });
      console.log("this.table.free_goods,", this.table.free_goods);
    },
    onInputRounding(index, i) {
      this.calculated = false;
    },
    handleSubmit() {
      this.deposits = [];
      this.total_deposit = 0;
      console.log("this.selectedCustDeposit", this.selectedCustDeposit);
      // Cek deposits, mandatory untuk ZDCBD

      if (this.soType.Code == "ZDCBD" && this.selectedCustDeposit.length > 0) {
        this.selectedCustDeposit.forEach((cd) => {
          this.deposits.push({
            deposit_id: cd.ID,
            deposit_code: cd.Code,
            value: cd.DepositValue,
          });
          this.total_deposit += cd.DepositValue;
        });

        if (
          parseInt(this.CalcRespData.total_billing) >
          parseInt(this.total_deposit)
        ) {
          let exceed =
            parseInt(this.CalcRespData.total_billing) -
            parseInt(this.total_deposit);
          this.$vs.notify({
            color: "warning",
            title: "Warning",
            text:
              "Insufficent deposit ! Billing exceed : " +
              this.formatCurrency(exceed.toFixed(2)),
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          this.$vs.loading.close();
          return;
        }
      } else if (
        this.soType.Code == "ZDCBD" &&
        this.selectedCustDeposit.length == 0
      ) {
        this.$vs.notify({
          color: "warning",
          title: "Warning",
          text: "Please select deposit first!",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }

      console.log(this.deposits);

      let bst_condition =
        this.salesOrder.BillAddress.DefaultAttribute.ID != undefined &&
        this.salesOrder.ShipAddress.DefaultAttribute.ID != undefined;
      let ot_address_condition =
        this.isOneTime && this.oneTimeCust.address_manual != null;
      if (bst_condition || ot_address_condition) {
        bst_condition =
          this.salesOrder.ShipAddress.DefaultAttribute.ID &&
          this.salesOrder.BillAddress.DefaultAttribute.ID;
        if (bst_condition || ot_address_condition) {
          this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: `Confirm`,
            text: "Please confirm to submit",
            accept: this.promiseSubmit,
            // accept: this.acceptSubmit,
          });
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Bill to & Ship to is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
      }
      // console.log(this.selectedBillTo)
      if (this.salesOrder.BillAddress || ot_address_condition) {
        if (!this.salesOrder.BillAddress.DefaultAttribute.ID) {
          this.isErrorBillTo = true;
          this.msgErrorBillto =
            "bill to is required " +
            this.salesOrder.BillAddress.DefaultAttribute.ID;
        } else if (!ot_address_condition && this.isOneTime) {
          this.isErrorBillTo = true;
          this.msgErrorBillto =
            "bill to is required " +
            this.salesOrder.BillAddress.DefaultAttribute.ID;
        } else {
          this.isErrorBillTo = false;
          this.msgErrorBillto = "";
        }
      } else {
        this.isErrorBillTo = true;
        this.msgErrorBillto = "bill to is required ";
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Bill to is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }

      if (this.selectedShippTo || ot_address_condition) {
        if (
          !this.salesOrder.ShipAddress.DefaultAttribute.ID ||
          ot_address_condition
        ) {
          this.isErrorShippTop = true;
          this.msgErrorShippTo =
            "shipp to is required " +
            this.salesOrder.ShipAddress.DefaultAttribute.ID;
        } else {
          this.isErrorShippTop = false;
          this.msgErrorShippTo = "";
        }
      } else {
        this.isErrorShippTop = true;
        this.msgErrorShippTo = "shipp to is required ";
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Shipp to is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    promiseSubmit() {
      if (this.isOneTime) {
        Promise.all([this.submitCustOnetime()]).then((r) => {
          console.log(r);
          console.log(r);
          if (r[0].resp.code == 200) {
            this.acceptSubmit();
          } else {
            this.$vs.loading.close();
          }
        });
      } else {
        this.acceptSubmit();
      }
    },
    acceptSubmit() {
      this.$vs.loading();
      let cout,
        sum_units,
        qty_per_HU,
        items = [];
      // console.log(this.table.data)
      let isExistFg = false;
      let isValidFg = true;
      this.table.data.forEach((dt, dt_i) => {
        console.log(dt);
        // handle items biasa, no combo
        let lp_valid_fromTo = {
          from: null,
          to: null,
        };
        if (dt.data_combo.length == 0) {
          console.log("dt non combo", dt);
          sum_units = dt.hu_labels.length;
          qty_per_HU = dt.items_hu.split(".");
          console.log("qty_per_HU", qty_per_HU);
          // Loops by lsit_price, price > 0
          dt.list_price.forEach((lp, idx) => {
            cout = 0;
            for (let i = 0; i < sum_units; i++) {
              if (lp.unit == dt.hu_labels[i].unit && qty_per_HU[cout] > 0) {
                // if qty <= 0 maka tidak dikirimkan ke BE

                let qty_general = parseFloat(
                  qty_per_HU[cout] * dt.hu_labels[i].amount_uom
                );

                // Cek apakah promo order_line dan order_line_after discount masih string, klo iya, maka diParse Float
                console.log("dt.subtotal.tax_rate[idx]", dt.subtotal.tax_rate);
                console.log("dt.tax_rate[0]", dt.tax_rate[0]);
                let a = parseFloat(
                  dt.subtotal.tax_rate
                    ? dt.subtotal.tax_rate[idx] > 0
                      ? dt.subtotal.tax_rate[idx]
                      : dt.tax_rate[0]
                    : dt.tax_rate[0]
                );
                console.log("a", a);
                lp.promo_calculation.forEach((pc) => {
                  console.log("Sini");
                  if (typeof pc.order_line == "string") {
                    pc.order_line = parseFloat(pc.order_line);
                  }
                  if (typeof pc.order_line_after_discount == "string") {
                    pc.order_line_after_discount = parseFloat(
                      pc.order_line_after_discount
                    );
                  }
                });

                items.push({
                  discount: parseFloat(lp.discount),
                  item_unit: dt.hu_labels[i].unit,
                  item_id: dt.formInputs[0].value.item_id,
                  item_unit_id: parseInt(dt.hu_labels[i].item_unit_id),
                  item_code: dt.formInputs[0].value.code,
                  item_name: dt.formInputs[0].value.sku_name,
                  item_uom:
                    typeof dt.hu_labels[i].amount_uom == "string"
                      ? parseFloat(dt.hu_labels[i].amount_uom)
                      : dt.hu_labels[i].amount_uom,
                  quantity: parseFloat(qty_per_HU[cout]),
                  quantity_uom: parseFloat(dt.hu_labels[i].amount_uom),
                  quantity_general: qty_general,
                  price: parseFloat(lp.price),
                  tax_rate: parseFloat(
                    dt.subtotal.tax_rate
                      ? dt.subtotal.tax_rate[idx] > 0
                        ? dt.subtotal.tax_rate[idx]
                        : dt.tax_rate[0]
                      : dt.tax_rate[0]
                  ),
                  tax: parseFloat(dt.subtotal.tax[idx]),
                  total_rounding: parseFloat(lp.rounding),
                  gross_up: parseFloat(lp.gross_up),
                  allow_partial:
                    parseInt(this.allowPartialNum) == 0 ? 0 : dt.allow_partial,
                  cogs: lp.cogs ? parseFloat(lp.cogs) : 0,
                  height: lp.height,
                  width: lp.width,
                  length: lp.length,
                  volume: lp.volume,
                  weight: lp.weight,
                  price_rule_code: lp.price_rule_code,
                  price_rule_id: lp.price_rule_id,
                  price_rule_valid_to: new Date(lp.price_rule_valid_to),
                  price_rule_valid_from: new Date(lp.price_rule_valid_from),
                  division_code: lp.division_code,
                  division_description: lp.division_description,
                  net_weight: lp.net_weight,
                  total_height: lp.total_height,
                  total_weight: lp.total_weight,
                  total_width: lp.total_width,
                  total_volume: lp.total_volume,
                  total_length: lp.total_length,
                  total_net_weight: lp.total_net_weight,
                  is_rejected: dt.is_rejected,
                  rejected_reason_id: dt.reject_reason_id,
                  free_item: false,
                  promo_calculation: lp.promo_calculation,
                });
              }

              cout++;
            }
            lp_valid_fromTo.from = new Date(lp.price_rule_valid_from);
            lp_valid_fromTo.to = new Date(lp.price_rule_valid_to);
          });
        } else {
          // handle item combo
          /*
              Pecah data mengunakan
              - dt.list_price dan
              - dt.hu_labels[0].combo_items
            */
          console.log("dt.list_price", dt.list_price);
          console.log(
            "dt.hu_labels[0].combo_items lemgth?? ",
            dt.hu_labels[0].combo_items.length
          );
          console.log(
            "dt.hu_labels[0].combo_items",
            dt.hu_labels[0].combo_items
          );
          // if (dt.hu_labels[0].combo_items==undefined||dt.hu_labels[0].combo_items.length==0) {
          if (
            dt.hu_labels[0].combo_items == undefined ||
            (dt.hu_labels[0].combo_items != undefined &&
              dt.hu_labels[0].combo_items.length == 0)
          ) {
            this.$vs.notify({
              title: "The child combo has not been set",
              text: "please set the child combo first",
              color: "danger",
              position: "top-right",
            });
            return;
          }
          // }
          let comboID_anditemUnitID = [];
          dt.hu_labels[0].combo_items.forEach((ci, idCombo) => {
            dt.list_price.forEach((lp, ipx) => {
              cout = 0;
              let cidIUid =
                ipx +
                "_" +
                dt.data_combo[ipx].ComboID +
                "_" +
                dt.data_combo[ipx].ItemUnitID;
              console.log("cidIUid");
              console.log(cidIUid);
              console.log(comboID_anditemUnitID);
              console.log("comboID_anditemUnitID");

              if (
                lp.unit == dt.data_combo[ipx].UnitName &&
                parseInt(dt.data_combo[ipx].total_qty) > 0 &&
                !comboID_anditemUnitID.includes(cidIUid)
              ) {
                // if qty <= 0 maka tidak dikirimkan ke BE

                comboID_anditemUnitID.push(cidIUid);

                let qty_general = parseFloat(
                  parseFloat(dt.data_combo[ipx].total_qty_combo) *
                    parseFloat(dt.data_combo[ipx].amount_uom)
                );
                console.log(qty_general);

                // Cek apakah promo order_line dan order_line_after discount masih string, klo iya, maka diParse Float
                lp.promo_calculation.forEach((pc) => {
                  if (typeof pc.order_line == "string") {
                    pc.order_line = parseFloat(pc.order_line);
                  }
                  if (typeof pc.order_line_after_discount == "string") {
                    pc.order_line_after_discount = parseFloat(
                      pc.order_line_after_discount
                    );
                  }
                });

                items.push({
                  discount: parseFloat(dt.list_price[ipx].discount),
                  item_unit: dt.data_combo[ipx].UnitName,
                  item_unit_id: parseInt(dt.data_combo[ipx].ItemUnitID),
                  item_code: dt.data_combo[ipx].ItemCode,
                  item_name: dt.data_combo[ipx].ItemName,
                  item_uom: parseFloat(dt.data_combo[ipx].amount_uom),
                  item_id: dt.data_combo[ipx].ItemID,
                  quantity: parseFloat(dt.data_combo[ipx].total_qty_combo),
                  quantity_uom: parseFloat(dt.data_combo[ipx].amount_uom),
                  quantity_general: qty_general,
                  price: parseFloat(dt.list_price[ipx].price),
                  tax_rate: parseFloat(dt.subtotal.tax_rate[ipx]),
                  tax: parseFloat(dt.subtotal.tax[ipx]),
                  total_rounding: parseFloat(lp.rounding),
                  gross_up: parseFloat(lp.gross_up),
                  // "allow_partial" : this.allowPartial ? 1 : 0,
                  allow_partial:
                    parseInt(this.allowPartialNum) == 0 ? 0 : dt.allow_partial,
                  cogs: lp.cogs ? parseFloat(lp.cogs) : 0,
                  height: lp.height,
                  width: lp.width,
                  length: lp.length,
                  volume: lp.volume,
                  weight: lp.weight,
                  price_rule_code: lp.price_rule_code,
                  price_rule_id: lp.price_rule_id,
                  price_rule_valid_to: new Date(lp.price_rule_valid_to),
                  price_rule_valid_from: new Date(lp.price_rule_valid_from),
                  division_code: lp.division_code,
                  division_description: lp.division_description,
                  net_weight: lp.net_weight,
                  total_height: lp.total_height,
                  total_weight: lp.total_weight,
                  total_width: lp.total_width,
                  total_volume: lp.total_volume,
                  total_length: lp.total_length,
                  total_net_weight: lp.total_net_weight,
                  combo_code: ci.ComboCode,
                  combo_name: ci.ComboName,
                  combo_id: ci.ComboID,
                  quantity_combo: parseFloat(dt.items_qty),
                  is_rejected: dt.is_rejected,
                  rejected_reason_id: dt.reject_reason_id,
                  promo_calculation: lp.promo_calculation,
                  free_item: false,
                });
              }
              lp_valid_fromTo.from = new Date(lp.price_rule_valid_from);
              lp_valid_fromTo.to = new Date(lp.price_rule_valid_to);
            });
          });
        }
        let fg_filter = [];
        fg_filter = this.table.free_goods.filter((el) => {
          return el.line == dt_i;
        });

        // memasukkan item Freegoods
        if (fg_filter.length > 0) {
          // check if item doesnt have price
          isExistFg = true;
          let priceless = [];
          let isValidFreeGood = true;
          for (let dt_promo of fg_filter) {
            console.log("In loops freegoods item");
            console.log("dt_promo", dt_promo);
            // handle free items
            if (
              dt_promo.fg_component.value == undefined ||
              dt_promo.fg_component.value == null
            ) {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: "Please select item free_good",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              isValidFreeGood = false;
              continue;
            }
            if (parseFloat(dt_promo.fg_component.value.price) == 0) {
              this.$vs.notify({
                title: "Warning",
                text: "Please select free good item first",
                color: "danger",
                position: "top-right",
              });
              isValidFreeGood = false;
              isValidFg = false;
              priceless.push(
                "DBP for SKU " +
                  dt_promo.fg_component.value.sku_code +
                  " not found"
              );
              continue;
            }
            items.push({
              // "discount" : dt.subtotal.disc, //belum ada diskon, sementara nol aja dulu
              discount: parseFloat(dt_promo.fg_component.value.discount),
              item_unit: dt_promo.fg_component.value.item_unit,
              item_unit_id: parseInt(dt_promo.fg_component.value.item_unit_id),
              item_code: dt_promo.fg_component.value.sku_code,
              item_name: dt_promo.fg_component.value.item_name,
              item_id: dt_promo.fg_component.value.item_id,
              quantity: dt_promo.fg_component.value.qty,
              quantity_general: dt_promo.fg_component.value.qty,
              price: parseFloat(dt_promo.fg_component.value.price),
              tax_rate: parseFloat("0"),
              tax: parseFloat("0"),
              total_rounding: parseFloat("0"),
              gross_up: parseFloat("0"),
              // "allow_partial" : this.allowPartial ? 1 : 0,
              allow_partial:
                parseInt(this.allowPartialNum) == 0 ? 0 : dt.allow_partial,
              cogs: parseFloat(dt_promo.fg_component.value.cogs),
              height: parseFloat("0"),
              width: parseFloat("0"),
              length: parseFloat("0"),
              volume: parseFloat("0"),
              weight: parseFloat("0"),
              price_rule_code: "",
              price_rule_id: 0,
              price_rule_valid_to: lp_valid_fromTo.to,
              price_rule_valid_from: lp_valid_fromTo.from,
              division_code: "",
              division_description: "",
              net_weight: parseFloat("0"),
              total_height: parseFloat("0"),
              total_weight: parseFloat("0"),
              total_width: parseFloat("0"),
              total_volume: parseFloat("0"),
              total_length: parseFloat("0"),
              total_net_weight: parseFloat("0"),
              is_rejected: false,
              rejected_reason_id: 0,
              free_item: true,
              promo_calculation: [
                {
                  base: dt_promo.base,
                  base_amount: dt_promo.base_amount,
                  cap: dt_promo.fg_component.value.cap,
                  capped: dt_promo.fg_component.value.capped,
                  discount_type: dt_promo.discount_type,
                  from: dt_promo.from,
                  promotion_code: dt_promo.promotion_code,
                  promotion_id: dt_promo.promotion_id,
                  promotion_item_req_id: dt_promo.promotion_item_req_id,
                  promotion_sequence: dt_promo.promotion_sequence,
                  promotion_step_id: dt_promo.promotion_step_id,
                  promotion_type: dt_promo.promotion_type,
                  qty: dt_promo.qty,
                  to: dt_promo.to,
                },
              ],
            });
          }

          // notif alert jika ada FG yang tidak punya price
          if (priceless.length > 0) {
            let uniquePriceless = priceless.filter(function (item, pos) {
              return priceless.indexOf(item) == pos;
            });
            uniquePriceless.forEach((msg) => {
              this.$vs.notify({
                color: "warning",
                title: "Warning",
                text: msg,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle",
              });
            });
          }

          if (!isValidFreeGood) {
            this.$vs.loading.close();
            return;
          }
        }
      });
      if (isExistFg && !isValidFg) {
        setTimeout(() => {
          //klw g dikasih timeout loadingnya g bisa di close, karena terlalu cepat
          this.$vs.loading.close();
        }, 1000);
        return;
      }
      let dataOneTimeCust = {};
      if (this.isOneTime) {
        dataOneTimeCust = {
          domisili: this.oneTimeCust.domisili,
          npwp_type: this.oneTimeCust.npwp_type,
          npwp: this.oneTimeCust.npwp,
          npwp_name: this.oneTimeCust.npwp_name,
          sppkp: this.oneTimeCust.sppkp,
          tax_address: this.oneTimeCust.tax_address,
          onetime_customer_id:
            this.oneTimeCust.id != null && this.isOneTime
              ? this.oneTimeCust.id
              : 0,
          customer_id: this.oneTimeCust.customer.ID,
        };
      }

      const params = {
        items: items,
        user_id: this.$userLogin.id,
        personal_id: this.$userLogin.id,
        company_id: this.salesOrder.CompanyID,
        sales_order_id: this.salesOrder.DefaultAttribute.ID,
        sales_order_code: this.salesOrder.Code,
        territory_id: this.salesOrder.TerritoryID,
        source: "oms-web",
        promo_code: [],
        allow_partial: parseInt(this.allowPartialNum),
        rounding: parseFloat(this.CalcRespData.total_rounding), //ini total semua rounding, disave di header / tabel SO
        total_gross_up: parseFloat(this.CalcRespData.total_gross_up), //ini total semua gross up, disave di header / tabel SO
        subtotal: parseFloat(this.CalcRespData.total_netto), //ini subtotal semua Items, semua HU, tanpa pajak
        total_tax: parseFloat(this.CalcRespData.total_tax), //ini total pajak
        total_discount: parseFloat(this.CalcRespData.total_discount), //ini total disc
        total_billing: parseFloat(this.CalcRespData.total_billing), //ini subtotal semua Items, semua HU, sudah dengan pajak, dikurangi disc
        deposits: this.deposits,
        date: this.salesOrder.Date,
        shipment_to_address_id: this.salesOrder.ShipAddress.DefaultAttribute.ID,
        sales_personal_id: this.salesPersonal.selected.ID,
        sales_personal_code_external: this.salesPersonal.selected.CodeExternal,
        sales_personal_work_id_number: this.salesPersonal.selected.WorkIDNumber,
        sales_personal_name: this.salesPersonal.selected.Name,
        sales_personal_mobile: this.salesPersonal.selected.Mobile,
        delivery_type_id: this.deliveryType.selected.id,
        delivery_type_name: this.deliveryType.selected.name,
        delivery_type_code: this.deliveryType.selected.code,
        po_refrence_code: this.salesOrder.PoReferenceCode,
        pricing_group_id: this.pricingGroup.selected.ID
          ? this.pricingGroup.selected.ID
          : this.salesOrder.Customer.PricingGroupID,
        rdd: this.salesOrder.RequestDeliveryDate,
        notes: this.salesOrder.Notes,
        is_pbf: parseInt(this.pbf) == 1 ? true : false,
        is_one_time: this.isOneTime,
        onetime_customer_data: dataOneTimeCust,
      };

      console.log("params", params);
      // uncomment sampai line 4499
      this.$http
        .put(this.baseUrl, params)
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully submitted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            // back to tab new SO
            this.$emit("successSave", 0);
            this.$emit("redirectTab", 0, "");
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    expandDetail(i1, i2) {
      console.log(i1, i2);
      if (
        this.table.data.length > 0 &&
        this.table.data[i1].list_price.length > 0
      ) {
        this.table.data[i1].list_price[i2].is_expand =
          !this.table.data[i1].list_price[i2].is_expand;
        if (this.table.data[i1].list_price[i2].is_expand) {
          this.table.data[i1].rowSpanPlus++;
        } else if (this.table.data[i1].rowSpanPlus > 1) {
          this.table.data[i1].rowSpanPlus--;
        }
      }
    },
    expandDetailCombo(i1, i2) {
      console.log("expandDetailCombo");
      console.log(i1, i2);
      if (
        this.table.data.length > 0 &&
        this.table.data[i1].data_combo.length > 0
      ) {
        console.log("this.table.data[i1].data_combo[i2].is_expand");
        console.log(this.table.data[i1].data_combo[i2].is_expand);
        this.table.data[i1].data_combo[i2].is_expand =
          !this.table.data[i1].data_combo[i2].is_expand;
        if (
          this.table.data[i1].data_combo[i2].is_expand &&
          this.table.data[i1].data_combo[i2].rowSpanPlusCombo == 1
        ) {
          this.table.data[i1].data_combo[i2].rowSpanPlusCombo++;
          this.comboRowSpanPlus++;
        } else if (this.table.data[i1].data_combo[i2].rowSpanPlusCombo > 1) {
          this.table.data[i1].data_combo[i2].rowSpanPlusCombo--;
          this.comboRowSpanPlus--;
        }
        console.log("After ? is_expand");
        console.log(this.table.data[i1].data_combo[i2].is_expand);
      }
    },
    showDetailDisc(data, subtotal = "0", i, i2) {
      console.log("data", data);
      data.promo_calculation.forEach((pc) => {
        console.log("typeof pc.order_line", typeof pc.order_line);
        console.log(
          "typeof pc.order_line_after_discount",
          typeof pc.order_line_after_discount
        );
        if (
          typeof pc.order_line != "string" &&
          typeof pc.order_line_after_discount != "string"
        ) {
          pc.order_line = pc.order_line.toFixed(2);
          pc.order_line_after_discount =
            pc.order_line_after_discount.toFixed(2);
        }
        let base_string, disc_type_string;
        if (pc.base_string == undefined) {
          switch (pc.base) {
            case 1:
              base_string = "quantity";
              break;
            case 2:
              base_string = "value";
              break;
            default:
              base_string = "";
              break;
          }
          pc.base_string = base_string;
        }
        // 1: percentage; 2: amount; 3: fix amount; 4: fix item; 5: lowest price; 6: sku; 7: merchandise; 8: lowest price;
        if (pc.discount_string == undefined) {
          switch (pc.discount_type) {
            case 1:
              disc_type_string = "percentage";
              break;
            case 2:
              disc_type_string = "amount";
              break;
            case 3:
              disc_type_string = "fix amount";
              break;
            case 4:
              disc_type_string = "fix item";
              break;
            case 5:
              disc_type_string = "lowest price";
              break;
            case 6:
              disc_type_string = "sku";
              break;
            case 7:
              disc_type_string = "merchandise";
              break;
            default:
              disc_type_string = "";
              break;
          }
          pc.discount_string = disc_type_string;
        }
      });
      this.detailDisc = true;
      this.selectedDiscount = data;
      this.pricingCode = data.price_rule_code;
      this.pricing = data.price;
      this.sku = data.item_name + " " + data.unit;
      this.subtotal = subtotal;
      this.rounding = data.rounding;

      console.log("this.table.data", this.table.data);
      this.detailPricing = {
        pricing_code: this.table.data[i].list_price[i2].price_rule_code,
        price: this.table.data[i].list_price[i2].price,
        sub_total: this.table.data[i].subtotal.price[i2],
        tax: this.table.data[i].subtotal.tax[i2],
        rounding: this.table.data[i].list_price[i2].rounding,
        nett:
          this.table.data[i].subtotal.price[i2] -
          this.table.data[i].list_price[i2].discount +
          this.table.data[i].list_price[i2].rounding,
        gross_up: this.table.data[i].list_price[i2].gross_up,
        dpp: this.table.data[i].list_price[i2].dpp
          ? this.table.data[i].list_price[i2].dpp
          : this.table.data[i].subtotal.price[i2] -
            this.table.data[i].list_price[i2].discount +
            this.table.data[i].list_price[i2].gross_up +
            (this.table.data[i].list_price[i2].rounding
              ? parseFloat(this.table.data[i].list_price[i2].rounding)
              : 0),
        grand_total:
          parseFloat(this.table.data[i].grand_total[i2]) +
          (this.table.data[i].list_price[i2].rounding
            ? parseFloat(this.table.data[i].list_price[i2].rounding)
            : 0),
        sku: data.item_name + " - " + data.unit,
        total_discount: parseFloat(data.discount), // MIX2-2218
      };
    },
    formatDate(value) {
      // console.log(value)
      if (value) {
        return moment(String(value)).format("yyyy - MM - dd");
      } else {
        return "-";
      }
    },
    getDiscTypeString(type) {
      var discType = "";
      switch (type) {
        case 1:
          discType = "percentage";
          break;
        case 2:
          discType = "amount";
          break;
        case 5:
          discType = "lowest price";
          break;
        case 6:
          discType = "sku";
          break;
        case 9:
          discType = "choice";
          break;
        default:
          discType = "unspecified";
          break;
      }
      return discType;
    },
    getBaseString(base) {
      var baseStr = "";
      switch (base) {
        case 1:
          baseStr = "quantity";
          break;
        case 2:
          baseStr = "value";
          break;
        case 3:
          baseStr = "weight";
          break;
        case 4:
          baseStr = "volume";
          break;
        default:
          baseStr = "unspecified";
          break;
      }
      return baseStr;
    },
    setAllowPartialSkus(recalculate) {
      console.log("this.table.data", this.table.data);
      if (recalculate) {
        this.allowPartialNum = 1;
      }
      this.table.data.forEach((el, idx) => {
        let allow_partial = 0;
        el.list_price.forEach((lp) => {
          lp.promo_calculation.forEach((elPc) => {
            if (elPc.allow_partial_fulfill) {
              allow_partial = 1;
            }
            if (elPc.allow_partial_fulfill == false) {
              this.allowPartialNum = 0;
            }
          });
        });
        this.table.data[idx].allow_partial = allow_partial;
      });
      if (this.allowPartialNum == 0) {
        this.disableAllowPartial = true;
      } else {
        this.disableAllowPartial = false;
      }
      console.log("this.table", this.table);
    },
    setDefaultSelectedFG() {
      console.log("this.table.free_goods", this.table.free_goods);
      console.log("this.initSelectedFg", this.initSelectedFg);

      this.table.free_goods.forEach((_, i_fg) => {
        console.log("this.table.free_goods[i_fg]", this.table.free_goods[i_fg]);
        console.log(
          "this.table.free_goods[i_fg].discount_type",
          this.table.free_goods[i_fg].discount_type
        );

        if (this.table.free_goods[i_fg].discount_type == 9) {
          console.log("choice ini");
          this.table.free_goods[i_fg].fg_component.optionValue.forEach(
            (opt) => {
              this.initSelectedFg.forEach((initFg) => {
                /*
                  cek kesamaan
                  - item_unit_id
                  - promotion_id
                  - promo_item_req_id
                  - promo_step_id
                */
                let condSamePromo =
                  opt.promotion_id == initFg.promotion_id; //&& (opt.promotion_item_req_id == initFg.promotion_item_req_id)
                  // &&  (opt.promotion_step_id == initFg.promotion_step_id)

                console.log("condSamePromo", condSamePromo);
                if (condSamePromo) {
                  if (opt.item_unit_id == initFg.item_unit_id) {
                    console.log(
                      "Promo dan item_unit fg sama persis dengan sebelum recalculate"
                    );
                    console.log(
                      "opt.item_unit_id == initFg.item_unit_id",
                      opt.item_unit_id == initFg.item_unit_id
                    );
                    this.table.free_goods[i_fg].fg_component.value = opt;
                  }
                }
              });
            }
          );
        }
      });
    },
    addFreeGoods(i, calc, i_calc) {
      // Part setting freegoods dari item reguler
      const disc_type_choice = 9;
      const disc_type_lower_price = 5;
      var temp_data_fg = this.getTempPromoFG();
      console.log("temp_data_fg==>>", temp_data_fg);

      let tempDataFgOption = [];
      let is_push = false;
      let tempKeyFgChoices = [];
      let tempKeyFgChoice = {
        promotion_item_req_id: 0,
        promotion_id: 0,
      };

      console.log("calc.promo_free_good", calc.promo_free_good);
      let last_fg_bp = "";
      calc.promo_free_good.forEach((promo_fg) => {
        if (promo_fg.allow_partial_fulfill == false) {
          this.allowPartialNum = 0;
        }
        if (last_fg_bp == "" || last_fg_bp != promo_fg.promotion_code) {
          temp_data_fg = this.getTempPromoFG();
          tempDataFgOption = [];
        }
        if (last_fg_bp == "" || last_fg_bp != promo_fg.promotion_code) {
          var temp_fg_select = { ...temp_data_fg.fg_component.value };
          console.log("temp_fg_select==>>", temp_fg_select);
          temp_fg_select = {};
          if (parseInt(promo_fg.discount_type) == disc_type_choice) {
            console.log(4286);
            let fg_filter = [];
            fg_filter = this.table.free_goods.filter((el) => {
              // return promo_fg.promotion_item_req_id == el.promotion_item_req_id && el.fg_component.i_parent == i_calc
              return (
                promo_fg.promotion_id == el.promotion_id &&
                promo_fg.promotion_item_req_id == el.promotion_item_req_id &&
                el.fg_component.i_parent == i_calc
              );
            });
            if (fg_filter.length > 0) {
              console.log(4292);
              this.table.free_goods.forEach((elm, i_fg) => {
                console.log(
                  i_fg + ".",
                  "(",
                  promo_fg.fg_item_code,
                  ")",
                  promo_fg.promotion_item_req_id,
                  "==",
                  elm.promotion_item_req_id,
                  "=>",
                  promo_fg.promotion_item_req_id == elm.promotion_item_req_id
                );
                // if (promo_fg.promotion_item_req_id == elm.promotion_item_req_id&&elm.fg_component.i_parent==i_calc) {
                if (
                  promo_fg.promotion_id == elm.promotion_id &&
                  promo_fg.promotion_item_req_id == elm.promotion_item_req_id &&
                  elm.fg_component.i_parent == i_calc
                ) {
                  if (
                    promo_fg.sku_code != undefined &&
                    promo_fg.item_name != undefined
                  ) {
                    this.table.free_goods[i_fg].fg_component.optionValue.push({
                      sku_code: promo_fg.fg_item_code,
                      item_id: promo_fg.fg_item_id,
                      item_name: promo_fg.fg_item_name,
                      item_unit: promo_fg.fg_item_unit,
                      item_unit_id: promo_fg.fg_item_unit_id,
                      promotion_id: promo_fg.promotion_id,
                      promotion_item_req_id: promo_fg.promotion_item_req_id,
                      promotion_step_id: promo_fg.promotion_step_id,
                      text: "[" + promo_fg.sku_code + "] " + promo_fg.item_name,
                      qty: promo_fg.fg_qty,
                      price: promo_fg.price,
                      discount: promo_fg.qty * promo_fg.price,
                      cap: promo_fg.cap,
                      capped: promo_fg.capped,
                      cogs: promo_fg.cogs.toFixed(2),
                    });
                  } else {
                    console.log("skip");
                    console.log(
                      "length optionValue Fg ke i ",
                      i_fg,
                      this.table.free_goods[i_fg].fg_component.optionValue
                        .length
                    );
                  }
                  console.log("lanjut 5817 kan");
                  this.table.free_goods[i_fg].fg_component.value = null;
                  this.table.free_goods[i_fg].fg_component.value = {};
                }
              });
              is_push = false;
            } else {
              console.log(4315);
              if (
                tempKeyFgChoice.promotion_id != promo_fg.promotion_id ||
                tempKeyFgChoice.promotion_item_req_id !=
                  promo_fg.promotion_item_req_id
              ) {
                tempKeyFgChoice.promotion_id = promo_fg.promotion_id;
                tempKeyFgChoice.promotion_item_req_id =
                  promo_fg.promotion_item_req_id;
                tempKeyFgChoices.push({
                  line: i,
                  promotion_type: promo_fg.promotion_type,
                  promotion_code: promo_fg.promotion_code,
                  discount_type: promo_fg.discount_type,
                  discount_string: promo_fg.discount_string,
                  promotion_sequence: promo_fg.promotion_sequence,
                  promotion_step_id: promo_fg.promotion_step_id,
                  qty: promo_fg.qty,
                  to: promo_fg.to,
                  from: promo_fg.from,
                  base: promo_fg.base,
                  base_amount: promo_fg.base_amount,
                  base_string: promo_fg.base_string,
                  promotion_item_req_id: promo_fg.promotion_item_req_id,
                  promotion_id: promo_fg.promotion_id,
                });
              }
              temp_fg_select.sku_code = promo_fg.fg_item_code;
              temp_fg_select.item_id = promo_fg.fg_item_id;
              temp_fg_select.item_name = promo_fg.fg_item_name;
              temp_fg_select.item_unit = promo_fg.fg_item_unit;
              temp_fg_select.item_unit_id = promo_fg.fg_item_unit_id;
              temp_fg_select.text =
                "[" + temp_fg_select.sku_code + "] " + temp_fg_select.item_name;
              temp_fg_select.qty = promo_fg.fg_qty;
              temp_fg_select.price = promo_fg.price;
              temp_fg_select.discount =
                temp_fg_select.qty * temp_fg_select.price;
              temp_fg_select.cap = promo_fg.cap;
              temp_fg_select.capped = promo_fg.capped;
              temp_fg_select.cogs = promo_fg.cogs.toFixed(2);
              temp_fg_select.promotion_id = promo_fg.promotion_id;
              temp_fg_select.promotion_item_req_id =
                promo_fg.promotion_item_req_id;
              temp_fg_select.promotion_step_id = promo_fg.promotion_step_id;

              temp_data_fg.allow_partial = promo_fg.allow_partial_fulfill
                ? 1
                : 0;
              temp_data_fg.discount_type = promo_fg.discount_type;
              temp_data_fg.discount_string = promo_fg.discount_string;
              temp_data_fg.promotion_type = promo_fg.promotion_type;
              temp_data_fg.promotion_code = promo_fg.promotion_code;
              temp_data_fg.promotion_id = promo_fg.promotion_id;
              temp_data_fg.promotion_item_req_id =
                promo_fg.promotion_item_req_id;
              temp_data_fg.promotion_sequence = promo_fg.promotion_sequence;
              temp_data_fg.promotion_step_id = promo_fg.promotion_step_id;
              temp_data_fg.line = i;
              temp_data_fg.qty = promo_fg.qty;
              temp_data_fg.to = promo_fg.to;
              temp_data_fg.from = promo_fg.from;
              temp_data_fg.base = promo_fg.base;
              temp_data_fg.base_amount = promo_fg.base_amount;
              temp_data_fg.base_string = promo_fg.base_string;

              let cloneDtSelect = { ...temp_fg_select };
              console.log("cloneDtSelect", cloneDtSelect);
              console.log("temp_fg_select", temp_fg_select);
              tempDataFgOption.push(cloneDtSelect);

              temp_data_fg.fg_component.optionValue.push(temp_fg_select);
              // temp_data_fg.fg_component.value = null
              temp_data_fg.fg_component.value =
                this.temp_free_good.fg_component.value;
              temp_data_fg.fg_component.disabled = false;
              temp_data_fg.fg_component.i_parent = i_calc;
              is_push = true;
              if (last_fg_bp != promo_fg.promotion_code) {
                this.table.free_goods.push(temp_data_fg);
                last_fg_bp = promo_fg.promotion_code;
              }
            }
          } else {
            console.log(4355);
            console.log("promo_fg", promo_fg);
            temp_fg_select.sku_code = promo_fg.fg_item_code;
            temp_fg_select.item_id = promo_fg.fg_item_id;
            temp_fg_select.item_name = promo_fg.fg_item_name;
            temp_fg_select.item_unit = promo_fg.fg_item_unit;
            temp_fg_select.item_unit_id = promo_fg.fg_item_unit_id;
            temp_fg_select.text =
              "[" + temp_fg_select.sku_code + "] " + temp_fg_select.item_name;
            temp_fg_select.qty = promo_fg.fg_qty;
            temp_fg_select.price = promo_fg.price;
            temp_fg_select.discount = temp_fg_select.qty * temp_fg_select.price;
            temp_fg_select.cap = promo_fg.cap;
            temp_fg_select.capped = promo_fg.capped;
            temp_fg_select.cogs = promo_fg.cogs.toFixed(2);
            temp_fg_select.promotion_id = promo_fg.promotion_id;
            temp_fg_select.promotion_item_req_id =
              promo_fg.promotion_item_req_id;
            temp_fg_select.promotion_step_id = promo_fg.promotion_step_id;

            temp_data_fg.allow_partial = promo_fg.allow_partial_fulfill ? 1 : 0;
            temp_data_fg.discount_type = promo_fg.discount_type;
            temp_data_fg.discount_string = promo_fg.discount_string;
            temp_data_fg.promotion_type = promo_fg.promotion_type;
            temp_data_fg.promotion_code = promo_fg.promotion_code;
            temp_data_fg.promotion_id = promo_fg.promotion_id;
            temp_data_fg.promotion_item_req_id = promo_fg.promotion_item_req_id;
            temp_data_fg.promotion_sequence = promo_fg.promotion_sequence;
            temp_data_fg.promotion_step_id = promo_fg.promotion_step_id;
            temp_data_fg.line = i;
            temp_data_fg.qty = promo_fg.qty;
            temp_data_fg.to = promo_fg.to;
            temp_data_fg.from = promo_fg.from;
            temp_data_fg.base = promo_fg.base;
            temp_data_fg.base_amount = promo_fg.base_amount;
            temp_data_fg.base_string = promo_fg.base_string;

            let cloneDtSelect = { ...temp_fg_select };
            tempDataFgOption.push(cloneDtSelect);
            // temp_data_fg.fg_component.value = temp_fg_select
            temp_data_fg.fg_component.value = tempDataFgOption[0]; //temp_fg_select
            temp_data_fg.fg_component.optionValue = tempDataFgOption;
            temp_data_fg.fg_component.optionValue.push(temp_fg_select);
            temp_data_fg.fg_component.optionValue.push(
              this.temp_free_good.fg_component.value
            );
            temp_data_fg.fg_component.disabled = true;
            temp_data_fg.fg_component.i_parent = i_calc;
            if (parseInt(promo_fg.discount_type) == 6) {
              //disc type SKU
              let this_temp = JSON.stringify(temp_data_fg);
              let is_push = false;
              this.table.free_goods.forEach((fg_el) => {
                console.log("is_push", is_push);
                is_push = JSON.stringify(fg_el) == this_temp;
                console.log("is_push", is_push);
              });
              console.log("Push FG 4517");
              console.log("is_push", is_push);
              if (!is_push) {
                this.table.free_goods.push(temp_data_fg);
                if (last_fg_bp != promo_fg.promotion_code) {
                  last_fg_bp = promo_fg.promotion_code;
                }
              }
            }
            is_push = true;
          }
          console.log("temp_data_fg=>", temp_data_fg, ", i=>", i);
          console.log("temp_data_fg.line=>", temp_data_fg.line, ", i=>", i);
        } else if (
          last_fg_bp == promo_fg.promotion_code &&
          this.table.free_goods.length > 0
        ) {
          console.log(
            "last_fg_bp == promo_fg.promotion_code",
            last_fg_bp == promo_fg.promotion_code
          );
          console.log("last_fg_bp == ", last_fg_bp);
          console.log("promo_fg.promotion_code == ", promo_fg.promotion_code);
          console.log("this.table.free_goods ", this.table.free_goods);
          console.log(
            "this.table.free_goods.length ",
            this.table.free_goods.length
          );
          // reset dan set ulang FG option
          temp_fg_select = { ...temp_data_fg.fg_component.value };
          temp_fg_select.sku_code = promo_fg.fg_item_code;
          temp_fg_select.item_id = promo_fg.fg_item_id;
          temp_fg_select.item_name = promo_fg.fg_item_name;
          temp_fg_select.item_unit = promo_fg.fg_item_unit;
          temp_fg_select.item_unit_id = promo_fg.fg_item_unit_id;
          temp_fg_select.text =
            "[" + temp_fg_select.sku_code + "] " + temp_fg_select.item_name;
          temp_fg_select.qty = promo_fg.fg_qty;
          temp_fg_select.price = promo_fg.price;
          temp_fg_select.discount = temp_fg_select.qty * temp_fg_select.price;
          temp_fg_select.cap = promo_fg.cap;
          temp_fg_select.capped = promo_fg.capped;
          temp_fg_select.cogs = promo_fg.cogs.toFixed(2);
          temp_fg_select.promotion_id = promo_fg.promotion_id;
          temp_fg_select.promotion_item_req_id = promo_fg.promotion_item_req_id;
          let cloneDtSelect = { ...temp_fg_select };
          tempDataFgOption.push(cloneDtSelect);
          this.table.free_goods[
            this.table.free_goods.length - 1
          ].fg_component.optionValue = tempDataFgOption;
        }
      });

      if (calc.promo_free_good && calc.promo_free_good.length > 0) {
        //&&is_push
        console.log("temp_data_fg", temp_data_fg);
        // temp_data_fg.fg_component.optionValue = tempDataFgOption
        // this.table.free_goods.push(temp_data_fg)
        console.log("tempDataFgOption", tempDataFgOption);
        console.log("tempKeyFgChoices", tempKeyFgChoices);
        calc.promo_free_good.forEach((fgCek) => {
          if (
            parseInt(fgCek.discount_type) != 6 &&
            parseInt(fgCek.discount_type) != 9
          ) {
            // temp_data_fg.fg_component.value = this.temp_free_good.fg_component.value
            temp_data_fg.fg_component.value =
              temp_data_fg.fg_component.optionValue[0];
          }
        });

        tempKeyFgChoices.forEach((el) => {
          temp_data_fg.fg_component.optionValue = tempDataFgOption.filter(
            (fg) => {
              return (
                fg.promotion_id == tempKeyFgChoice.promotion_id &&
                fg.promotion_item_req_id ==
                  tempKeyFgChoice.promotion_item_req_id
              );
            }
          );

          let this_temp = JSON.stringify(temp_data_fg);
          let is_push = false;
          this.table.free_goods.forEach((fg_el) => {
            is_push = JSON.stringify(fg_el) == this_temp;
          });
          console.log("Push FG 4558");
          console.log("is_push", is_push);
          // console.log("JSON.stringify(this.table.free_goods)", JSON.stringify(this.table.free_goods))
          // console.log("JSON.stringify(el)", JSON.stringify(el))
          // console.log("JSON.stringify(temp_data_fg)", JSON.stringify(temp_data_fg))
          if (!is_push) {
            this.table.free_goods.push(temp_data_fg);
          }
        });
      }
      // end setting calc freegood
    },
    setSubtotalDiscWithFreegoods() {
      if (this.table.free_goods.length > 0) {
        this.table.free_goods.forEach((fg) => {
          this.CalcRespData.total_discount += fg.fg_component.value.discount;
          this.CalcRespData.total_order += fg.fg_component.value.discount;
        });
      }
    },
    getWidthByValues(s = [], width = 15, sc = 0) {
      let finalWidth = 0;
      let defaultWidth = 70;

      if (s) {
        s.forEach((el) => {
          let tempWidth = 0;
          let length = 0;
          if (el) {
            length = el.toString().length;
            console.log(el, "get length", length);
            if (length > 2) {
              tempWidth = defaultWidth + width * (length - 2);
              if (finalWidth < tempWidth) {
                finalWidth = tempWidth;
              }
            }
          }
        });
      }
      if (sc > 0) {
        let tempWidth = 0;
        let length = 0;
        length = sc.toString().length;
        console.log(sc, "get length", length);
        if (length > 2) {
          tempWidth = defaultWidth + width * (length - 2);
          if (finalWidth < tempWidth) {
            finalWidth = tempWidth;
          }
        }
      }
      if (finalWidth == 0) {
        console.log("get width failed", s);
        finalWidth = defaultWidth; //default width
      }
      console.log("finalWidth", finalWidth);
      return finalWidth;
    },
  },
  getRddLimit() {
    let rddLimit = new Date();
    rddLimit.setDate(rddLimit.getDate() + 1);
    return rddLimit;
  },
};
</script>
