<template>
  <div>
    <!-- button based on tab active -->
    <!-- <div v-if="progress != 'released' && progress != 'rejected' && progress != 'cancel' && progress != 'planning' && progress != 'allocated' && progress!='waiting_approval'" class="vx-row mb-6"> -->
    <div
      v-if="(progress == 'allocated' && b2bExisting) || progress == 'hold'"
      class="vx-row mb-6"
    >
      <div
        v-if="progress == 'hold'"
        class="vx-col sm:w-1/6 w-full flex items-center"
      >
        <vs-button
          class="float-left"
          color="success"
          style="width: 100%"
          @click="handleProcess()"
        >
          {{ progress == "hold" ? "Submit for Approval" : "Process" }}
        </vs-button>
      </div>
      <div
        v-if="progress == 'allocated' || progress == 'hold'"
        class="vx-col sm:w-1/6 w-full flex items-center"
      >
        <vs-button
          class="float-left"
          color="danger"
          style="width: 100%"
          @click="handleCancel()"
        >
          Cancel
        </vs-button>
      </div>
    </div>
    <!-- main page -->
    <div class="vx-row mb-12">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    this.dataEnd == 0
                      ? 0
                      : this.limitShow * (this.isActive - 1) + 1
                  }}
                  - {{ this.limitShow * (this.isActive - 1) + dataEnd }} of
                  {{ this.recordsTotal }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in limits"
                  v-bind:key="item"
                  @click="changeLimit(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div class="mx-auto"></div>
          <div class="vx-col sm:w-1/3 w-full">
            <div class="flex flex-wrap items-center justify-between">
              <vs-input
                class="mb-4 md:mb-0 mr-0 w-full"
                v-model="search"
                placeholder="Search by : SO Code or Customer Name"
              />
            </div>
          </div>
          <div class="vx-col sm:w-1/12 w-full">
            <div class="flex flex-wrap items-center justify-between">
              <vs-button
                class="mb-4 w-full"
                color="primary"
                type="border"
                icon-pack="feather"
                icon="icon-search"
                v-on:click="handleFilters()"
              ></vs-button>
            </div>
          </div>
        </div>

        <vs-table :data="dataSalesOrder" stripe border>
          <template slot="thead">
            <vs-th>
              Action
              <vs-checkbox
                v-if="
                  (progress == 'allocated' && b2bExisting) || progress == 'hold'
                "
                v-on:click="checkAllProcess()"
                v-model="checkedAll"
              ></vs-checkbox>
            </vs-th>
            <vs-th>Customer Data</vs-th>
            <vs-th>SFA SO Number</vs-th>
            <vs-th>SFA PO Number</vs-th>
            <vs-th>SO Data</vs-th>
            <vs-th>SO Type</vs-th>
            <vs-th>Delivery Data</vs-th>
            <vs-th>Segmentation Data</vs-th>
            <vs-th>Order Summary</vs-th>
            <vs-th>
              {{ dispStatus }}
            </vs-th>
            <!-- <vs-th v-if="progress=='hold'"> -->
            <!-- </vs-th> -->
            <!-- <vs-th v-else>Note</vs-th> -->
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indexSO" v-for="(dt, indexSO) in data">
              <vs-td>
                <div class="vx-row mb-4 pl-3">
                  <feather-icon
                    title="View"
                    icon="EyeIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    class="ml-2"
                    @click="clickView(dt)"
                  /><br />
                  <feather-icon
                    v-if="progress == 'hold'"
                    title="Edit"
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    class="ml-2"
                    @click="clickEdit(dt)"
                  /><br />
                  <feather-icon
                    v-if="progress == 'hold'"
                    title="Set as New SO"
                    icon="CornerUpLeftIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    class="ml-2"
                    @click="clickSetBack(dt)"
                  /><br />
                  <feather-icon
                    v-if="
                      progress == 'all' ||
                      progress == 'allocated' ||
                      progress == 'hold' ||
                      progress == 'waiting_approval'
                    "
                    title="Print"
                    icon="PrinterIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    class="ml-2"
                    @click="handlePrint(indexSO)"
                  /><br />
                  <vs-checkbox
                    v-if="
                      (progress == 'allocated' && b2bExisting) ||
                      progress == 'hold'
                    "
                    :checked="checked_so.includes(dt.DefaultAttribute.ID)"
                    v-on:click="checkProcess(dt.DefaultAttribute.ID)"
                  ></vs-checkbox
                  ><br />
                </div>
              </vs-td>
              <vs-td>
                Customer :
                <b
                  >{{ dt.Customer.Code }} -
                  {{
                    dt.IsOneTime
                      ? dt.Customer.Name + " | " + dt.OcData.name
                      : dt.Customer.Name
                  }}</b
                >
                <br />
                Ship to Code : <b>{{ dt.ShipAddress.Code }}</b
                ><br />
                Address :
                {{
                  dt.IsOneTime
                    ? dt.OcData.shipto_address +
                      ", " +
                      dt.OcData.city +
                      ", " +
                      dt.OcData.country
                    : dt.ShipAddress.Address +
                      ", " +
                      dt.ShipAddress.City +
                      ", " +
                      dt.ShipAddress.Country
                }}<br />
              </vs-td>
              <vs-td> {{ dt.SoReferenceCode }}<br /> </vs-td>
              <vs-td>
                Code : {{ dt.PoReferenceCode }}<br />
                PO Date : {{ dateFormat(dt.PoReferenceDate) }}<br />
              </vs-td>
              <vs-td>
                Code : {{ dt.Code }}<br />
                Date : {{ dateFormat(dt.Date) }}<br />
                Note : {{ dt.Notes }}<br />
                <span v-show="dt.InvoiceCode != ''">
                  Invoice Code : {{ dt.InvoiceCode }}<br />
                  Invoice Value : {{ formatCurrency(dt.InvoiceValue) }}<br />
                </span>
              </vs-td>
              <vs-td> {{ dt.Category }}<br /> </vs-td>
              <vs-td>
                <!--
                                MIX2-1084
                                Deliv Plan Code -> PI Number
                                Deliv Type -> Incoterm
                                Remove Deliv Due
                                Req. Delivery date -> RDD
                                -->
                PI Number : {{ dt.DeliveryOrderCode }}<br />
                RDD : {{ dateFormat(dt.RequestDeliveryDate) }}<br />
                Incoterm :
                {{ dt.DeliveryTypeCode + " - " + dt.DeliveryType.Name }}<br />
                Allow Partial : {{ dt.AllowPartial == 0 ? "No" : "Yes" }}<br />
                Flush Out : {{ dt.IsFlushOut ? "Yes" : "No" }}<br />
                <!-- Delivery Due : {{ getDeliveryDue(dt.RequestDeliveryDate) + " days" }}<br> -->
              </vs-td>
              <vs-td>
                Territory :
                <b>{{ dt.Territory.Code + " " + dt.Territory.Name }}</b
                ><br />
                Dist. Channel : {{ dt.Customer.CustomerCategory.Name }}<br />
                Salesman :
                {{
                  dt.SalesPersonalCodeExternal != "" &&
                  dt.SalesPersonalCodeExternal
                    ? " [" +
                      dt.SalesPersonalCodeExternal +
                      "] " +
                      dt.SalesPersonalName
                    : " [ - ] " + dt.SalesPersonalName
                }}<br />
              </vs-td>
              <vs-td>
                Total Line : {{ dt.CountSoItem }}<br />
                <!-- Total Order : {{ formatCurrency((dt.Subtotal + dt.TaxAmount - dt.Discount)) }}<br> -->
                Total Order : {{ formatCurrency(dt.Total) }}
              </vs-td>
              <vs-td>
                <!-- auto cancel at -->
                <b>Notes : </b>{{ getValidationNote(dt) }}<br />
                <b>Fulfillment Notes : </b>{{ dt.FulfillmentMessage }}<br />
                <span v-if="progress == 'all'">
                  <b>Status : </b>{{ getSoStatus(dt) }}<br />
                </span>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <vs-pagination
          style="padding-top: 5px"
          :total="maxPage"
          v-model="currentPage"
          :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'"
          :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"
        />
      </div>
    </div>
    <vs-popup fullscreen title="Sales Order Lines" :active.sync="detail">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <detailSelectedSo :selectedSO="selectedSO"></detailSelectedSo>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import detailSelectedSo from "../component/detail_selected_so.vue";
import moment from "moment";

export default {
  components: {
    detailSelectedSo,
  },
  props: [
    "progress",
    "progressWarehouse",
    "progressRestriction",
    "isCancel",
    "territory",
    "salesChannel",
    "customerCategory",
    "isClosed",
    "isBilled",
    "salesPersonal",
    "doFilter",
    "sources",
    "dateStart",
    "dateEnd",
    "SelectedOrderTypes",
  ],
  mounted() {
    console.log("progress adalah", this.progress);
    console.log("salesPersonal adalah", this.salesPersonal);
    switch (this.progress) {
      case "hold":
        this.dispStatus = "Validation Notes";
        break;
      case "waiting_approval":
        this.dispStatus = "Note From Approver";
        break;
      case "cbd":
        this.dispStatus = "Deposit Payment Data";
        break;
      case "b2b":
        this.dispStatus = "PO Data";
        break;
      case "released":
        this.dispStatus = "# of DO";
        break;
      default:
        this.dispStatus = "#";
        break;
    }
    this.selectedSO = this.dataSalesOrder;
    console.log("this.selectedSO", this.selectedSO);
    console.log("this.userPermission", this.userPermission);
    console.log(
      "this.userPermission == undefined",
      this.userPermission == undefined
    );
    console.log(
      "this.userPermission === undefined",
      this.userPermission === undefined
    );
    // this.$store.dispatch("user/getPermissions","sales-order");
    if (this.userPermission == undefined) {
      //this.$store.dispatch("user/getPermissions","sales-order");
      console.log("this.$store", this.$store);
      console.log(
        "this.$store.getters['user/hasPermissions']('view')",
        this.$store.getters["user/hasPermissions"]("view")
      );
      this.userPermission = {
        view: true, //this.$store.getters['user/hasPermissions']('view'),
        edit: true, //this.$store.getters['user/hasPermissions']('edit'),
        create: true, //this.$store.getters['user/hasPermissions']('create'),
        delete: true, //this.$store.getters['user/hasPermissions']('delete'),
        cancel: true, //this.$store.getters['user/hasPermissions']('cancel'),
      };
      console.log("this.userPermission", this.userPermission);
    }
  },
  data() {
    return {
      selectedSO: {},
      b2bExisting: false,
      dataSalesOrder: [
        {
          SalesOrderLine: [{}],
          Customer: {
            Code: "",
            Name: "",
            Address: "",
            Phone: "",
            CustomerCategory: {
              Name: "",
            },
          },
          Company: {
            Code: "",
            Name: "",
          },
          DefaultAttribute: {
            ID: "",
          },
          SalesChannel: {
            Code: "",
            Name: "",
          },
          BillAddress: {
            Address: "",
            City: "",
            Country: "",
          },
          ShipAddress: {
            Address: "",
            City: "",
            Country: "",
          },
          Salesman: {
            Name: "",
          },
          PaymentTerm: {
            Name: "",
          },
        },
      ],
      salesOrderCode: "",

      limits: [10, 25, 50, 100],
      limitShow: 10,
      drawData: 0,
      isActive: 1,
      search: "",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      recordsTotal: 0,
      maxPage: 2,
      dataEnd: 0,
      checked_so: [],
      checkedAll: false,
      dispStatus: "",
    };
  },
  watch: {
    doFilter: function () {
      console.log("this.doFilter", this.doFilter);
      this.getData();
    },
    // watchedProperties: function() {
    //     this.getData();
    // }
  },
  computed: {
    watchedProperties() {
      return `${this.territory}||${this.salesChannel}||${this.customerCategory}||${this.search}`;
    },
    currentPage: {
      get() {
        return 1;
      },
      set(page) {
        this.page(page);
      },
    },
  },
  methods: {
    getDeliveryDue(date) {
      if (date) {
        var rdd = moment(String(date));
        var today = moment();
        return today.diff(rdd, "days");
      }
    },
    formatCurrency(nominal) {
      if (nominal && nominal > 0) {
        // nominal > 0 untuk handle return NaN
        nominal = (nominal / 1).toFixed(2).replace(".", ",");
        return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        return 0;
      }
    },
    dateFormat(date) {
      if (date) {
        // return moment(String(date)).utcOffset(0).format('DD/MM/YYYY hh:mm')
        let dt = new Date(date);
        let d = dt.getDate();
        let m = dt.getMonth() + 1;
        let y = dt.getFullYear();
        return d + "/" + m + "/" + y;
      }
    },
    getValidationNote(dt) {
      if (dt.Notes.includes("auto cancel at")) {
        return dt.Notes + "\n" + dt.ValidationNotes;
      } else {
        return dt.ValidationNotes;
      }
    },
    getSoStatus(so) {
      if (so.IsClosed == 1) {
        return "Closed";
      } else if (so.IsCancel == 1) {
        return "Canceled";
      } else if (so.ApprovalManager == 2 || so.ApprovalStatus == 2) {
        return "Rejected";
      } else if (so.ApprovalManager == 3) {
        return "Waiting Approval";
      } else if (so.ValidationStatus == 2) {
        return "Hold";
      } else if (so.ValidationStatus == 1) {
        if (so.InvoiceCode != "") {
          return "Billed";
        } else {
          return "Released PI";
        }
      } else if (so.AllocationStatus == 6) {
        return "Allocating ....";
      } else {
        let stat =
          so.ValidationNotes == null || so.ValidationNotes == ""
            ? ""
            : " | Reprocess";
        if (so.Category == "ZDCBD") {
          return "New CBD SO" + stat;
        }
        return "New SO" + stat;
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    getSOLine() {
      const search = this.salesOrderCode;
      if (search) {
        this.selectedSO = this.dataSalesOrder.filter(
          (so) => so.Code.indexOf(search) > -1
        )[0]; // filter + get 1st record
      }
    },

    clickView(salesOrder) {
      console.log("ini clickView lho!");
      console.log(salesOrder);
      console.log(this.progress);
      this.$emit("clickView", salesOrder, this.progress);
    },
    clickEdit(salesOrder) {
      console.log(salesOrder);
      this.$emit("selectSalesOrder", salesOrder);
      this.$emit("redirectTab", 3, "");
    },
    handleFilters() {
      this.$emit("handleFilters");
    },
    clickDelete(salesOrder) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "You are about to cancel '" + salesOrder.Code + "' ?",
        accept: this.actionDelete,
        parameters: salesOrder,
      });
    },

    actionDelete(salesOrder) {
      this.$vs.loading();
      this.$http
        .post("api/v1/sales-order/cancel", {
          sales_order_code: salesOrder.Code,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Sales Order successfully cancelled",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.getData();
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            // this.getData();
            this.$vs.loading.close();
          }
        });
    },

    clickSOCode(salesOrder) {
      this.closeDetail();
      setTimeout(function () {}, 3000);
      this.salesOrderCode = salesOrder.Code;
      this.getSOLine();
      this.detail = true;
      this.scrollToTop();
    },

    closeDetail() {
      this.detail = false;
      this.$emit("selectSalesOrder", null);
    },

    page(page) {
      this.getData(page);
      return page;
    },

    changeLimit(limit) {
      this.limitShow = limit;
      this.getData(this.isActive);
    },

    getData(page = 1) {
      this.isActive = page;
      this.$vs.loading();
      var sources = [];
      var so_type = [];

      this.sources.forEach((el) => {
        sources.push(el.source);
      });
      this.SelectedOrderTypes.forEach((el) => {
        so_type.push(el.code);
      });
      var parameter = {
        progress_restriction: this.progressRestriction,
        territory_code: this.territory,
        sales_channel_code: this.salesChannel,
        customer_category_id: this.customerCategory ? this.customerCategory : 0,
        progress_status: this.progress,
        start_date: this.dateStart,
        end_date: this.dateEnd,
        sources: sources,
        so_types: so_type,
        search: this.search,
        limit: this.limitShow,
        offset: this.limitShow * (page - 1),
        is_use_approval_matrix: false,
        sales_personal_id: this.salesPersonal ? this.salesPersonal : null,
      };

      if (this.progressRestriction == false) {
        switch (this.progress) {
          case "hold":
            parameter.approval_manager = 0;
            parameter.validation_status = 2;
            break;
          case "waiting_approval":
            parameter.validation_status = 2;
            parameter.approval_manager = 3;
            break;
          case "released":
            parameter.approval_release = 1;
            break;
          case "rejected":
            parameter.approval_status = 2;
            break;
          case "cancel":
            parameter.is_cancel = 1;
            break;
          default:
            parameter.approval_status = 0;
            parameter.approval_manager = 0;
            parameter.approval_release = 0;
            parameter.validation_status = 0;
            parameter.allocation_status = 0;
            break;
        }
      }

      // this.$http.get("/api/v1/sales-order", {
      this.$http
        .get("/api/v1/sales-order-mix2", {
          params: parameter,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.drawData = this.drawData + 1;
            this.dataSalesOrder = resp.data.SalesOrders;
            var countB2b = 0;
            if (
              this.dataSalesOrder != undefined &&
              this.dataSalesOrder.length > 0
            ) {
              this.dataSalesOrder.forEach((el) => {
                if (el.Category == "ZDBB") {
                  countB2b++;
                }
              });
            }
            this.b2bExisting = countB2b > 0;
            this.recordsTotal = resp.data.RecordsTotal;
            this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
            this.dataEnd = resp.data.ResultsTotal;
            this.checked_so = [];
            this.checkedAll = false;
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
    checkProcess(soID) {
      if (this.checked_so.includes(soID)) {
        this.checked_so.splice(this.checked_so.indexOf(soID), 1); // unchecked approve
      } else {
        this.checked_so.push(soID); // checked approve
      }
      this.checkedAll =
        this.checked_so.length == this.dataSalesOrder.length ? true : false;
      console.log("this.checked_so", this.checked_so);
    },
    checkAllProcess() {
      if (this.checkedAll == false) {
        for (var i = 0; i < this.dataSalesOrder.length; i++) {
          if (
            !this.checked_so.includes(
              this.dataSalesOrder[i].DefaultAttribute.ID
            )
          ) {
            this.checked_so.push(this.dataSalesOrder[i].DefaultAttribute.ID);
          }
        }
      } else {
        for (var j = 0; j < this.dataSalesOrder.length; j++) {
          this.checked_so.splice(
            this.checked_so.indexOf(this.dataSalesOrder[j].id),
            1
          );
        }
      }
    },
    handleProcess() {
      let act = this.progress == "hold" ? "approve" : "process";
      if (this.checked_so.length == 0) {
        this.$vs.notify({
          color: "warning",
          title: "Warning",
          text: "Please select at least one data !",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }
      console.log("handleProcess");
      console.log("this.checked_so", this.checked_so);
      this.$vs.dialog({
        type: "confirm",
        color: "success",
        title: `Confirm`,
        text: "Are you sure to " + act + " these selected SO ?",
        accept: this.actionProcess,
        // parameters: salesOrder
      });
    },
    actionProcess() {
      this.$vs.loading();
      let sales_order_ids = this.checked_so;
      console.log("sales_order_ids", sales_order_ids);
      this.$http
        .post("api/v1/sales-order/auto-validation", {
          sales_order_ids: sales_order_ids,
          is_auto_validation: !this.progress == "hold",
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Sales Order successfully processed",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            this.getData();
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.getData();
            this.$vs.loading.close();
          }
        });
    },
    handleCancel() {
      if (this.checked_so.length == 0) {
        this.$vs.notify({
          color: "warning",
          title: "Warning",
          text: "Please select at least one data !",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }
      console.log("handleCancel");
      console.log("this.checked_so", this.checked_so);
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to cancel these selected SO ?",
        accept: this.actionCancels,
        // parameters: salesOrder
      });
    },
    actionCancels() {
      this.$vs.loading();
      let sales_order_ids = this.checked_so;
      console.log("sales_order_ids", sales_order_ids);
      this.$http
        .post("api/v1/sales-order/cancel", {
          sales_order_ids: sales_order_ids,
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.so_failed.length > 0) {
              let temp_so_codes = "";
              let limitCodeSoCount = 2;
              resp.data.so_failed.forEach((code, i) => {
                if (i < limitCodeSoCount) {
                  if (temp_so_codes != "") {
                    if (
                      i == resp.data.so_failed.length - 1 &&
                      resp.data.so_failed.length <= limitCodeSoCount
                    ) {
                      temp_so_codes += " and ";
                    } else {
                      temp_so_codes += ", ";
                    }
                  }
                  temp_so_codes += "'";
                  temp_so_codes += code;
                  temp_so_codes += "'";
                }
              });
              let msgCancelSo = "so code " + temp_so_codes;
              if (resp.data.so_failed.length > limitCodeSoCount) {
                msgCancelSo += " and ";
                msgCancelSo +=
                  resp.data.so_failed.length - limitCodeSoCount + " others";
              }
              msgCancelSo += " failed to cancel";
              this.$vs.notify({
                color: "danger",
                title: "Failed",
                text: msgCancelSo,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
            } else {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: "Sales Order successfully cancelled",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
            }
            this.getData();
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.getData();
            this.$vs.loading.close();
          }
        });
    },
    handlePrint(i = 0) {
      console.log(this.dataSalesOrder);
      console.log(this.dataSalesOrder);
      const routeData = this.$router.resolve({
        name: "SO Print",
        params: { id: this.dataSalesOrder[i].DefaultAttribute.ID },
      });
      console.log("routeData.href=>", routeData.href);
      window.open(routeData.href, "_blank");
    },
    clickSetBack(salesOrder) {
      this.$vs.dialog({
        type: "confirm",
        color: "warning",
        title: `Confirm`,
        text: "You are about to set '" + salesOrder.Code + "' as New SO ?",
        accept: this.doSetBack,
        parameters: salesOrder,
      });
    },
    doSetBack(salesOrder) {
      console.log("salesOrder", salesOrder);
      this.$emit("setAsNewSo", salesOrder);
    },
  },
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
